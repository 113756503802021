import { SelectProps } from "antd";
import styled from "styled-components";

import { SelectStyled } from "./brands";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { SelectedGeneration } from "../filters.types";
import { FiltersActions } from "../redux/filters.slice";

const TagSelectStyled = styled(SelectStyled)<
  Omit<SelectProps, "onChange" | "onSelect" | "onDeselect"> & {
    onSelect: (value: string, option: SelectedGeneration) => void;
    onDeselect: (value: string, option: SelectedGeneration) => void;
  }
>``;

export const Generation = () => {
  const dispatch = useAppDispatch();
  const generations = useAppSelector((state) => state.filters.generations);
  const selectedGenerations = useAppSelector((state) => state.filters.selectedData.generations);

  const onDeselect = (value: string, option: SelectedGeneration) => {
    dispatch(FiltersActions.onDeselectGeneration(option));
  };
  const onSelect = (value: string, option: SelectedGeneration) => {
    dispatch(FiltersActions.onSelectGeneration(option));
  };

  return (
    <TagSelectStyled
      mode="multiple"
      size="large"
      placeholder="Generation"
      onDeselect={onDeselect}
      onSelect={onSelect}
      options={generations}
      value={selectedGenerations}
      maxTagCount={"responsive"}
    />
  );
};
