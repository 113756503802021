import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { LoginComponent } from "../../components/auth/login";
import { RegisterComponent } from "../../components/auth/register/register";
import { AuthRoutesPath } from "../../routes";

export const AuthPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleTabClick = (key: string) => {
    navigate(key);
  };
  return (
    <>
      <Tabs
        onTabClick={handleTabClick}
        defaultActiveKey={pathname}
        type="card"
        size={"large"}
        items={[
          {
            label: "Login",
            key: AuthRoutesPath.auth + AuthRoutesPath.login,
            children: <LoginComponent />,
          },
          {
            label: "Register",
            key: AuthRoutesPath.auth + AuthRoutesPath.register,
            children: <RegisterComponent />,
          },
        ]}
      />
    </>
  );
};
