import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SelectProps, SelectStyled } from "./brands";
import { createYearOptions } from "../../../helpers/createYearOptions";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { AutoCompleteOptions, FromToEnum } from "../filters.types";
import { FiltersActions } from "../redux/filters.slice";

const YearPicker = styled(Select)<SelectProps>`
  width: 100%;
  height: 55px;
  &.ant-select-single {
    height: 100%;
    color: rgb(0, 0, 0);
    font-family: Roboto;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;

    & .ant-select-selection-placeholder {
      height: 100% !important;
      font-family: Roboto !important;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      display: flex;
      align-items: center;
    }
    & .ant-select-selection-item {
      height: 100% !important;
      color: rgb(0, 0, 0);
      font-family: Roboto !important;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      display: flex;
      align-items: center;
    }
    & input {
      height: 100% !important;
      color: rgb(0, 0, 0);
      font-family: Roboto !important;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
    }
  }
`;

const YearPickerWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GetTitle = (year?: { from?: number | undefined; to?: number | undefined }) => {
  let title = "";
  if (year?.from) {
    title += `From: ${year.from}`;
  }
  if (year?.to) {
    if (title !== "") {
      title += " - ";
    }
    title += `To: ${year.to}`;
  }
  if (title === "") {
    return null;
  }
  return title;
};

export const YearSearch = () => {
  const years = useAppSelector((state) => state.filters.selectedData.year);

  return (
    <SelectStyled
      placeholder="Year"
      size="large"
      value={GetTitle(years)}
      onChange={() => console.log("onChange")}
      // onBlur={handleBlur} //TODO close if parent close
      dropdownRender={() => (
        <YearPickerWrapperStyled
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <YearPickerComp type={FromToEnum.FROM} />
          <YearPickerComp type={FromToEnum.TO} />
        </YearPickerWrapperStyled>
      )}
    ></SelectStyled>
  );
};

export const YearPickerComp = React.memo(({ type }: { type: FromToEnum }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [options, setOptions] = useState<{ label: number; value: number }[]>([]);

  const year = useAppSelector((state) => state.filters.selectedData.year);

  const handleSelect = (value: string) => {
    dispatch(FiltersActions.setYear({ type, value: Number(value) }));
  };

  useEffect(() => {
    setOptions(createYearOptions().reverse());
  }, []);

  const handleClear = () => {
    dispatch(FiltersActions.setYear({ type }));
  };

  useEffect(() => {
    if (type === FromToEnum.TO && year?.from) {
      setOptions((prev) =>
        prev.map((option) => {
          if (year.from && Number(option.value) < year.from) {
            return {
              ...option,
              disabled: true,
            };
          }
          return { ...option, disabled: false };
        }),
      );
      if (type === FromToEnum.TO && year.to && year.from > year.to) {
        dispatch(FiltersActions.setYear({ type, value: year.from }));
      }
    }
  }, [year, type, dispatch]);

  return (
    <YearPicker
      options={options}
      size="small"
      value={year?.[type]}
      onSelect={handleSelect}
      onClear={handleClear}
      showSearch
      allowClear
      placeholder={t(type)}
      notFoundContent="No data"
    />
  );
});

// TODO close if parent close
// TODO Bug!!! open nested dropdown options => switch to another tab. Come back and you see some line
