import { FormInstance, Input } from "antd";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import styled from "styled-components";

import { FormItemStyled } from "./form";
import { PHONE } from "../../../../constants/AdditionalInfo";
import axios from "../../../../helpers/interseptor";

const MaskedInputStyled = styled(InputMask)<{ $validateStatus?: string }>`
  border: 1px solid
    ${(props) =>
      props.$validateStatus === "error" ? "rgb(231, 70, 70) !important" : "rgb(219, 219, 219)"};
  border-radius: 8px;
  width: 100%;
  background: rgb(255, 255, 255);
  padding: 16px;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
  outline: none;
`;

const LabelStyled = styled.span`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
`;

export const PhoneAddCar: React.FC<{
  setIsPhoneValid: (value: boolean) => void;

  form: FormInstance;
  phone: string;
  setPhone: (value: string) => void;
}> = ({ setIsPhoneValid, form, phone, setPhone }) => {
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({ phone: phone });
  }, [phone, form]);

  const fetchData = useCallback(
    (value: string) => {
      const phone = value.replace(/\D/g, "").trim();
      // setValidateStatus("validating");
      axios
        .get(`/auth/check?phone=${phone}`)
        .then((res) => res.data)
        .then((data) => {
          if (data.phone) {
            // setValidateStatus("success");
            setIsPhoneValid(true);
            form.setFields([
              {
                name: PHONE,
                errors: [],
              },
            ]);
          } else {
            // setValidateStatus("error");
            setIsPhoneValid(false);
            form.setFields([
              {
                name: PHONE,
                errors: [t("addCar.phone.error-use")],
              },
            ]);
          }
        })
        .catch(() => {
          // setValidateStatus("error");
          setIsPhoneValid(false);
        });
    },
    [setIsPhoneValid, form, t],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 17) {
      form.setFieldsValue({ phone: e.target.value });
      setPhone(e.target.value);
    }
    if (e.target.value.length > 17) {
      form.setFieldsValue({ phone: phone });
    }

    if (e.target.value.length === 17) {
      fetchData(e.target.value);
    }
  };

  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.phone.label")}</LabelStyled>}
      name={PHONE}
      rules={[
        { required: true, message: t("addCar.phone.error") },
        { min: 17, max: 17, message: t("addCar.phone.error-format", { length: 17 }) },
      ]}
    >
      <MaskedInputStyled
        mask="+380 99 999 99 99"
        value={phone}
        alwaysShowMask={false}
        onChange={handleChange}
        placeholder="+380  "
        maskChar=""
      >
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (props) => <Input {...props} />
        }
      </MaskedInputStyled>
    </FormItemStyled>
  );
};
