import { FormInstance } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FormItemStyled, LabelStyled } from "./form";
import { BRAND } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";
import { CarSelectBrandThunk } from "../../redux/car.thunks";
import { SelectStyled } from "../../shared/Input";

export const BrandAddCar: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.car.brands);
  const brand = useAppSelector((state) => state.car.selectedData.brand);
  const handleBrand = (value: string, option: { value: string }) => {
    if (value) dispatch(CarSelectBrandThunk({ value: option.value }));
  };

  useEffect(() => {
    form.setFieldsValue({ brand });
  }, [brand, form]);

  const handleClear = () => {
    dispatch(CarActions.clearBrand());
  };
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.brand.label")}</LabelStyled>}
      name={BRAND}
      rules={[{ required: true, message: t("addCar.brand.error") }]}
    >
      <SelectStyled
        placeholder={t("addCar.brand.placeholder")}
        options={options}
        showSearch
        allowClear
        onSelect={handleBrand}
        onClear={handleClear}
      />
    </FormItemStyled>
  );
};
