import { Button, Input, Pagination } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios, { baseURLApi } from "../../../helpers/interseptor";
import { AdminRoutesPath } from "../../../routes";
import { AdminContext } from "../../../routes/admin/adminRoutes";

export interface User {
  _id: string;
  name: string;
  email: string;

  sellingCars: string[];
  favorite: string[];
  maxCarCount: number;
  createdAt: Date;
  updatedAt: Date;
  __v: 0;
}

export const AdminUserList: React.FC = () => {
  const { adminTocken } = useContext(AdminContext);
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalUsers, setTotalUsers] = useState(1);

  const [searchID, setSearchID] = useState("");

  useEffect(() => {
    axios
      .get<{
        users: User[];
        totalUsers: number;
      }>(`${baseURLApi}/admin/users/all?page=${page}&limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${adminTocken}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setUsers(data.users);
        setTotalUsers(data.totalUsers);
      });
  }, [adminTocken, limit, page]);

  const handleChangeID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchID(e.target.value);
  };

  const handleSearch = () => {
    axios
      .get<User>(`${baseURLApi}/admin/user/${searchID}`, {
        headers: {
          Authorization: `Bearer ${adminTocken}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setUsers([data]);
      });
  };

  const handleMore = (id: string) => () => {
    navigate(`${AdminRoutesPath.admin}${AdminRoutesPath.user}/${id}`);
  };

  const handleNext = (page: number) => {
    setPage(page);
  };

  const handleShowSize = (current: number, size: number) => {
    setLimit(size);
  };

  return (
    <>
      <div>User List</div>
      <div>
        <div>
          <Input value={searchID} onChange={handleChangeID} />
          <Button onClick={handleSearch}>Search</Button>
        </div>
        <div>
          {users.map((user) => (
            <div key={user._id}>
              <div>{user.name}</div>
              <div>{user.email}</div>
              <div>{user.maxCarCount}</div>
              <Button onClick={handleMore(user._id)}>More</Button>
            </div>
          ))}
        </div>
        <Pagination
          onChange={handleNext}
          total={totalUsers}
          current={page}
          defaultPageSize={limit}
          onShowSizeChange={handleShowSize}
        />
      </div>
    </>
  );
};
