import { AIRBAG, MULTIMEDIA, OPTIC, OTHER, SALON, SECURITY } from "../../constants/AdditionalInfo";

export const AdditionalInfoSelectedDataKeys = {
  OPTIC,
  OTHER,
  SALON,
  SECURITY,
  AIRBAG,
  MULTIMEDIA,
};

export enum AdditionalIonfoKeys {
  headlight = "headlight",
  optic = "optic",
  salon = "salon",
  salonMaterial = "salonMaterial",
  security = "security",
  airbags = "airbag",
  multimedia = "multimedia",
  other = "other",
}

export enum MenuID {
  ADDITIONAL = "additional",
  PHOTO = "photo",
  GENERAL = "general",
}
