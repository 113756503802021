import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "../../helpers/store.hook";

export const ProtectedAuthRoutes = () => {
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  if (isLogin) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};
