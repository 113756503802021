import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../../../../constants/AdditionalInfo";
import { useAppSelector } from "../../../../../helpers/store.hook";
import adaptiveLightingSystem from "../../../asset/adaptive-lighting-system.jpg";
import fogLights from "../../../asset/fog-lights.jpg";
import halogen from "../../../asset/halogen-headlights.jpg";
import headlightWasher from "../../../asset/headlight-washer.jpg";
import highBeamControlSystem from "../../../asset/high-beam-control-system.jpg";
import laser from "../../../asset/laser-headlights.jpg";
import led from "../../../asset/led-headlights.jpg";
import opticSencor from "../../../asset/light-sensor.jpg";
import matrix from "../../../asset/matrix-headlight.jpg";
import xenon from "../../../asset/xenon-headlights.jpg";
import { Icons } from "../../../search.types";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickAdditionalInfoCard } from "../../../shared/advancedSearch/SinglePickAdditionalInfo";
import { MenuID } from "../../../shared/constant/menuID";

const SubtitleStyled = styled.div`
  font-family: Roboto;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 18px;
  color: rgb(46, 46, 46);
`;

export const LightsIcons: Icons = {
  laser,
  halogen,
  xenon,
  led,
  matrix,
  "light-sensor": opticSencor,
  "headlight-washer": headlightWasher,
  "fog-lights": fogLights,
  "adaptive-lighting-system": adaptiveLightingSystem,
  "high-beam-control-system": highBeamControlSystem,
};

export const LightsPick = () => {
  const { t } = useTranslation();
  const selectedLights = useAppSelector((state) => state.filters.selectedData.optic);

  return (
    <>
      <SearchComponentWrapper title="lights" count={selectedLights?.length} id={MenuID.LIGHT}>
        <SinglePickAdditionalInfoCard
          type={AdditionalInfoDTOKeys.HEADLIGHTS}
          selectedType={AdditionalInfoSearchSelectedDataKeys.HEADLIGHTS}
          images={LightsIcons}
        />
        <SubtitleStyled>{t("options")}</SubtitleStyled>
        <SinglePickAdditionalInfoCard
          type={AdditionalInfoDTOKeys.OPTICS}
          selectedType={AdditionalInfoSearchSelectedDataKeys.OPTIC}
          images={LightsIcons}
        />
      </SearchComponentWrapper>
    </>
  );
};
