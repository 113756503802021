import { Button, Checkbox, Flex, Form, Input } from "antd";
import { NavLink } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { AuthRoutesPath } from "../../../routes";
import { AuthThunk } from "../redux/auth.thunks";

export const LoginComponent = () => {
  const dispatch = useAppDispatch();
  const isPending = useAppSelector((state) => state.auth.isPending);
  const onFinish = (values: { phone: string; password: string }) => {
    dispatch(
      AuthThunk({
        userData: { password: values.password, phone: `380${values.phone}` },
        type: "login",
      }),
    );
  };

  const onFinishFailed = (errorInfo: {
    values: { phone: string; password: string };
    errorFields: {
      name: (string | number)[];
      errors: string[];
    }[];
    outOfDate: boolean;
  }) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="login"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      disabled={isPending}
    >
      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[
          { required: true, message: "Please input your phone number!" },
          { len: 9, message: "Phone must be 9 characters long!" },
          () => ({
            validator(_, value) {
              if (!value || value.match(/^[0-9]+$/)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Phone is not valid!"));
            },
          }),
        ]}
      >
        <Input addonBefore={380} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <NavLink to={AuthRoutesPath.auth + AuthRoutesPath.resetPassword}>Forgot password</NavLink>
        </Flex>
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          Log in
        </Button>
        or <a href="#a">Register now!</a>
      </Form.Item>
    </Form>
  );
};
