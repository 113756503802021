import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../../../../constants/AdditionalInfo";
import { useAppSelector } from "../../../../../helpers/store.hook";
import AirSuspesion from "../../../asset/air-suspension.jpg";
import GarageStorage from "../../../asset/garage-storage.jpg";
import GasCylinderEquipment from "../../../asset/gas-cylinder-equipment.jpg";
import manualControlPeople from "../../../asset/manual-control-people-with-disabilities.png";
import roofRack from "../../../asset/roof-rack.jpg";
import serviecBook from "../../../asset/service-book.jpg";
import towbar from "../../../asset/towbar.jpg";
import { Icons } from "../../../search.types";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickAdditionalInfoCard } from "../../../shared/advancedSearch/SinglePickAdditionalInfo";
import { MenuID } from "../../../shared/constant/menuID";

export const OtherIcons: Icons = {
  "air-suspension": AirSuspesion,
  "garage-storage": GarageStorage,
  "gas-cylinder-equipment": GasCylinderEquipment,
  "manual-control-people-with-disabilities": manualControlPeople,
  "roof-rack": roofRack,
  "service-book": serviecBook,
  towbar: towbar,
};

export const OtherPick = () => {
  const selectedOther = useAppSelector((state) => state.filters.selectedData.other);

  return (
    <SearchComponentWrapper title="other" count={selectedOther?.length} id={MenuID.OTHER}>
      <SinglePickAdditionalInfoCard
        type={AdditionalInfoDTOKeys.OTHER}
        selectedType={AdditionalInfoSearchSelectedDataKeys.OTHER}
        images={OtherIcons}
      />
    </SearchComponentWrapper>
  );
};
