import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../../../../constants/AdditionalInfo";
import { useAppSelector } from "../../../../../helpers/store.hook";
import Acoustics from "../../../asset/acoustics.webp";
import AndroidAuto from "../../../asset/android-auto.jpg";
import Aux from "../../../asset/aux2.jpg";
import CarPlay from "../../../asset/car-play.jpg";
import GestureControl from "../../../asset/gesture-control.jpg";
import MultimediaRearPassengers from "../../../asset/multimedia-rear-passengers.jpg";
import PremiumAcousticSystem from "../../../asset/premium-speaker-system.jpg";
import USB from "../../../asset/usb.jpg";
import VoiceControl from "../../../asset/voice-control.jpg";
import { Icons } from "../../../search.types";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickAdditionalInfoCard } from "../../../shared/advancedSearch/SinglePickAdditionalInfo";
import { MenuID } from "../../../shared/constant/menuID";

export const MultimediaIcons: Icons = {
  acoustics: Acoustics,
  "android-auto": AndroidAuto,
  aux: Aux,
  carPlay: CarPlay,
  "gesture-control": GestureControl,
  "multimedia-rear-passengers": MultimediaRearPassengers,
  "premium-acoustic-system": PremiumAcousticSystem,
  usb: USB,
  "voice-control": VoiceControl,
};

export const MultimediaPick = () => {
  const selectedMultimedia = useAppSelector((state) => state.filters.selectedData.multimedia);

  return (
    <SearchComponentWrapper
      title="multimedia"
      count={selectedMultimedia?.length}
      id={MenuID.MULTIMEDIA}
    >
      <SinglePickAdditionalInfoCard
        type={AdditionalInfoDTOKeys.MULTIMEDIA}
        selectedType={AdditionalInfoSearchSelectedDataKeys.MULTIMEDIA}
        images={MultimediaIcons}
      />
    </SearchComponentWrapper>
  );
};
