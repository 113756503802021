import { BrandDTO, CarModelDTO, GenerationsDTO } from "./filters.dto";
import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../constants/AdditionalInfo";
import { CarState } from "../car/CarTypes";

export interface Brands extends BrandDTO {
  label: string;
  value: string;
}

export interface CarModel extends Omit<CarModelDTO, "generations" | "series" | "isSeries"> {
  isSeries?: boolean;
  label: string;
  value?: string;
  title?: string;
  isParent?: boolean;
  parentId?: string;
  options?: CarModel[];
}

export interface Generations extends GenerationsDTO {
  label: string;
  value: string;
}

export interface Modification {
  name: string;
  searchName: string;
  _id: string;
  isSelected?: boolean;
}

export interface AutoCompleteOptions {
  label: string;
  value: string | number;
  _id: string;
  title?: string;
  options?: AutoCompleteOptions[];
}

export interface SelectedModifications {
  [key: string]: {
    name: string;
    searchName: string;
    _id: string;
    image: string;
    options: Modification[];
  };
}

export interface FromTo<T> {
  from?: T;
  to?: T;
}

export interface SelectedGeneration extends Generations {
  options: Modification[];
}

export interface SelectedData {
  brand?: Brands;
  model?: CarModel;
  generations?: SelectedGeneration[];
  power?: FromTo<number>;
  year?: FromTo<number>;
  price?: FromTo<number>;
  mileage?: FromTo<number>;
  region?: string[];
  [AdditionalInfoSearchSelectedDataKeys.BODY_TYPE]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.COLOR]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.GEAR]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.LIGHT]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.OPTIC]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.HEADLIGHTS]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.SALON]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.SALON_MATERIAL]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.SECURITY]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.AIRBAG]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.MULTIMEDIA]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.FUEL]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.OTHER]?: string[];
  [AdditionalInfoSearchSelectedDataKeys.DRIVE_TYPE]?: string[];

  useInfo?: string[];
  afterAccident?: boolean;
}
export interface SearchState {
  brands: Brands[];
  models: CarModel[];
  generations: Generations[];
  cardSearchType: CardSearchEnum;
  selectedData: SelectedData;
  sort: string;
  period: string;
}

export enum CardSearchEnum {
  BRAND = "Brand",
  MODEL = "Model",
  GENERATION = "Generation",
  MODIFICATION = "Modification",
}

export enum FromToEnum {
  FROM = "from",
  TO = "to",
}

export type AdditionalInfoOptionsKeys = keyof Pick<
  CarState,
  | AdditionalInfoDTOKeys.DRIVE_TYPES
  | AdditionalInfoDTOKeys.BODY_TYPES
  | AdditionalInfoDTOKeys.OPTICS
  | AdditionalInfoDTOKeys.SALON
  | AdditionalInfoDTOKeys.SALON_MATERIAL
  | AdditionalInfoDTOKeys.HEADLIGHTS
  | AdditionalInfoDTOKeys.SECURITY
  | AdditionalInfoDTOKeys.AIRBAGS
  | AdditionalInfoDTOKeys.MULTIMEDIA
  | AdditionalInfoDTOKeys.OTHER
  | AdditionalInfoDTOKeys.COLORS
>;

export type AdditionalInfoSelectedKeys = keyof Pick<
  SelectedData,
  | AdditionalInfoSearchSelectedDataKeys.BODY_TYPE
  | AdditionalInfoSearchSelectedDataKeys.DRIVE_TYPE
  | AdditionalInfoSearchSelectedDataKeys.OPTIC
  | AdditionalInfoSearchSelectedDataKeys.HEADLIGHTS
  | AdditionalInfoSearchSelectedDataKeys.SALON
  | AdditionalInfoSearchSelectedDataKeys.SALON_MATERIAL
  | AdditionalInfoSearchSelectedDataKeys.SECURITY
  | AdditionalInfoSearchSelectedDataKeys.AIRBAG
  | AdditionalInfoSearchSelectedDataKeys.MULTIMEDIA
  | AdditionalInfoSearchSelectedDataKeys.OTHER
  | AdditionalInfoSearchSelectedDataKeys.COLOR
>;
