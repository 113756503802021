import { useMemo } from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { Brands, CarModel } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { getGenerationsThunk } from "../../../../filters/redux/filters.thunks";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickCard } from "../../../shared/advancedSearch/SinglePickCard";
import { MenuID } from "../../../shared/constant/menuID";

const ModelPickWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const BreadCrumbsWrapperStyled = styled.div`
  display: flex;
  color: rgb(0, 0, 0);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 16px;
`;

const BreadCrumbsItemStyled = styled.div`
  margin-right: 8px;
  &:first-child {
    cursor: pointer;
  }
`;

export const ModelsPick = () => {
  const dispatch = useAppDispatch();
  const models = useAppSelector((state) => state.filters.models);
  const selectedModel = useAppSelector((state) => state.filters.selectedData.model);
  const selectedBrand = useAppSelector((state) => state.filters.selectedData.brand);

  const handleClick = (value: CarModel) => {
    if (value._id === selectedModel?._id) {
      dispatch(FiltersActions.clearModel());
      return;
    }
    if (value?.options?.length) {
      dispatch(FiltersActions.setModel(value));
      return;
    }
    dispatch(getGenerationsThunk(value));
  };

  const options = useMemo(() => {
    if (selectedModel?.isSeries) {
      const model = models.find((model) => model._id === selectedModel?.parentId);
      if (model?.options?.length) {
        return model.options;
      }
      return [];
    }
    const model = models.find((model) => model._id === selectedModel?._id);

    if (model?.options?.length) {
      return model.options;
    }
    return models;
  }, [models, selectedModel]);
  if (!selectedBrand) return null;

  return (
    <SearchComponentWrapper title="model" count={selectedModel ? 1 : 0} id={MenuID.MODEL}>
      <BreadCrumbs brand={selectedBrand} model={selectedModel?.label} />
      <ModelPickWrapperStyled>
        {options.map((model) => (
          <SinglePickCard<CarModel>
            key={model._id}
            onClick={handleClick}
            value={model}
            size="large"
            isSelected={model._id === selectedModel?._id}
          />
        ))}
      </ModelPickWrapperStyled>
    </SearchComponentWrapper>
  );
};

const BreadCrumbs: React.FC<{ brand?: Brands; model?: string }> = ({ brand, model }) => {
  const dispatch = useAppDispatch();

  const handleBrand = () => {
    if (brand) dispatch(FiltersActions.clearModel());
  };
  if (brand && model) {
    return (
      <>
        <BreadCrumbsWrapperStyled>
          <BreadCrumbsItemStyled onClick={handleBrand}>{brand.shortName}</BreadCrumbsItemStyled>
          <BreadCrumbsItemStyled>/</BreadCrumbsItemStyled>
          <BreadCrumbsItemStyled>{model}</BreadCrumbsItemStyled>
        </BreadCrumbsWrapperStyled>
      </>
    );
  }
  if (brand) {
    return (
      <BreadCrumbsWrapperStyled>
        <BreadCrumbsItemStyled>{brand?.shortName}</BreadCrumbsItemStyled>
      </BreadCrumbsWrapperStyled>
    );
  }
  return <></>;
};
