import { Card } from "antd";
import { Link } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import styled from "styled-components";

import { baseURL } from "../../../helpers/interseptor";
import EngineIcon from "../asset/engine.svg";
import FuelIcon from "../asset/fuel.svg";
import GearIcon from "../asset/gear.svg";
import MileageIcon from "../asset/mileage.svg";

const CardStyled = styled(Card)`
  width: 370px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px 0px rgba(37, 37, 37, 0.15);
  background: rgb(255, 255, 255);
  margin: 15px;
`;

const GeneralInfoStyled = styled.div`
  padding: 15px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CarNameStyled = styled.h3`
  color: rgb(51, 51, 51);
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const PriceStyled = styled.h2`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
`;

const YearStyled = styled.div`
  border-radius: 5px;
  background: rgb(255, 192, 0);
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  padding: 0px 10px;
  margin-bottom: 3px;
`;

const CityStyled = styled.div`
  color: rgb(51, 51, 51);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: right;
`;

const Devider = styled.div`
  border: 1px solid rgb(191, 191, 191);
  width: 100%;
`;

const CarInfoStyled = styled.div`
  display: flex;
  padding-top: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InfoItem = styled.div`
  width: 81px;
  height: 35px;
  display: flex;
  width: 40%;
  align-items: center;
  margin-bottom: 15px;
`;

const InfoItemText = styled.div`
  color: rgb(51, 51, 51);
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 15px;
`;

const InfoItemIcon = styled.div`
  color: rgb(51, 51, 51);
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 37px;
  & img {
    width: 22px;
  }
`;

const DetailButton = styled(Link)`
  width: 237px;
  height: 43px;
  display: block;
  margin: 10px auto 0 auto;
  border: 1px solid rgb(255, 192, 0);
  border-radius: 5px;
  color: rgb(61, 61, 61);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: rgb(255, 192, 0) !important;
    color: rgb(255, 192, 0) !important;
  }
`;

const settings: Settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "grey" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "grey" }}
      onClick={onClick}
    />
  );
}

//TODO add power
export const SearchCart = ({
  _id,
  image,
  title,
  brand,
  model,
  photo,
  link,
  footer = <></>,
  ...rest
}: any) => {
  return (
    <>
      <CardStyled>
        <Slider {...settings}>
          {photo.map((photo: string) => (
            <img
              //TODO remove hardcoded url
              key={photo}
              src={photo}
              alt={`${brand} ${model}`}
            />
          ))}
        </Slider>
        <GeneralInfoStyled>
          <div>
            <CarNameStyled>
              {brand} {model}
            </CarNameStyled>
            <PriceStyled>$ {rest.price}</PriceStyled>
          </div>
          <div>
            <YearStyled>{rest.year}</YearStyled>
            <CityStyled>{rest.city}</CityStyled>
          </div>
        </GeneralInfoStyled>
        <Devider />
        <CarInfoStyled>
          <InfoItem>
            <InfoItemIcon>
              <img src={GearIcon} alt="Gear" />
              {rest.gear[0].toUpperCase() + rest.gear.slice(1)}
            </InfoItemIcon>
            <InfoItemText>{rest.gear}</InfoItemText>
          </InfoItem>
          <InfoItem>
            <InfoItemIcon>
              <img src={EngineIcon} alt="Gear" />
              Gearbox
            </InfoItemIcon>
            <InfoItemText>{rest.driveType}</InfoItemText>
          </InfoItem>
          <InfoItem>
            <InfoItemIcon>
              <img src={MileageIcon} alt="Gear" />
              Mileage
            </InfoItemIcon>
            <InfoItemText>{rest.mileage}</InfoItemText>
          </InfoItem>
          <InfoItem>
            <InfoItemIcon>
              <img src={FuelIcon} alt="Gear" />
              Gearbox
            </InfoItemIcon>
            <InfoItemText>{rest.fuel[0].toUpperCase() + rest.fuel.slice(1)}</InfoItemText>
          </InfoItem>
        </CarInfoStyled>
        <DetailButton to={link}>View Details</DetailButton>
        {footer}
      </CardStyled>
    </>
  );
};
