import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

import { baseURL } from "../../../../helpers/interseptor";
import { Brands, CarModel, Generations } from "../../../filters/filters.types";
import notFoundImage from "../../asset/not-found.jpg";

const style = css`
  width: 160px;
  height: 115px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
`;

export const ContainerStyled = styled.div<{ $isSelected?: boolean; $border: boolean }>`
  ${style}

  border: ${(props) =>
    props.$isSelected
      ? "1px solid var(--main-color)"
      : props.$border
        ? "1px solid rgb(219, 219, 219)"
        : "none"};
  border-radius: 8px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const ImageContainerStyled = styled.div<{ $border: boolean }>`
  width: 100%;
  height: 80px;
  border-bottom: ${(props) => (props.$border ? "1px solid rgb(219, 219, 219)" : "none")};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
`;

const ImageStyled = styled.img<{ size?: "small" | "large" }>`
  width: ${(props) => (props.size === "small" ? "38px" : "100%")};
  height: ${(props) => (props.size === "small" ? "38px" : "auto")};
`;

export const TitleContainerStyled = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface SinglePickCardProps<T> {
  value: T;
  onClick?: (value: T) => void;
  isSelected?: boolean;
  size?: "small" | "large";
  border?: boolean;
  img?: string;
}

export const SinglePickCard = <T extends Brands | CarModel | Generations>({
  onClick,
  isSelected,
  value,
  img,
  size = "large",
  border = true,
}: SinglePickCardProps<T>) => {
  const handleClick = () => {
    if (onClick) onClick(value);
  };

  return (
    <ContainerStyled $isSelected={isSelected} $border={border} onClick={handleClick}>
      <ImageContainerStyled $border={border}>
        <ImageStyled
          alt={value.label}
          src={img || baseURL + value?.image}
          size={size}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = notFoundImage;
            currentTarget.style.width = "auto";
            currentTarget.style.height = "100%";
          }}
        />
      </ImageContainerStyled>
      <TitleContainerStyled>
        <TitleStyled>{value.label}</TitleStyled>
      </TitleContainerStyled>
    </ContainerStyled>
  );
};

interface SinglePickMoreProps {
  onClick: () => void;
}

const ContainerPickMoreStyled = styled.div`
  ${style}
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  cursor: pointer;
`;

export const SinglePickMore: React.FC<SinglePickMoreProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return <ContainerPickMoreStyled onClick={onClick}>{t("more")}</ContainerPickMoreStyled>;
};
