import { Route, Routes } from "react-router-dom";

import { UserRoutesPath } from "..";
import { AuthPhoneVerifyPage } from "../../pages/auth/verify";
import { UserCars } from "../../pages/user/cars";
import { ProtectedRoute } from "../protectedRoute";

export const UserRoutes = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<div>user Page</div>} />
        <Route path={UserRoutesPath.cars} element={<UserCars />} />
        <Route path={UserRoutesPath.phoneVerification} element={<AuthPhoneVerifyPage />} />
      </Route>
    </Routes>
  );
};
