import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            dotSize: 8,
            controlSize: 20,
            dotActiveBorderColor: "var(--main-color)",
            handleActiveColor: "var(--main-color)",
            handleColor: "var(--main-color)",
            trackBg: "var(--main-color)",
            trackHoverBg: "var(--main-color)",
            fontSize: 16,
          },
          Tooltip: {
            colorBgSpotlight: "#fff",
            colorTextLightSolid: "rgb(46, 46, 46)",
          },
          Spin: {
            colorPrimary: "#fff",
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
