import { Button, Input, Popconfirm } from "antd";
import { useContext, useState } from "react";

import axios, { baseURLApi } from "../../../helpers/interseptor";
import { AdminContext } from "../../../routes/admin/adminRoutes";

interface Car {
  VIN: string;
  brand: string;
  color: string;
  createdAt: string;
  engin: string;
  // features: any[];
  fuel: string;
  generation: string;
  mileage: number;
  model: string;
  photo: string;
  transmission: string;
  updatedAt: string;
  year: number;
  _id: string;
  isActive: boolean;
}

export const AdminSearchList: React.FC = () => {
  const [car, setCar] = useState<Car | null>(null);
  const { adminTocken } = useContext(AdminContext);
  const [searchID, setSearchID] = useState("");

  const handleChangeID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchID(e.target.value);
  };

  const handleSearch = () => {
    axios
      .get<Car>(`${baseURLApi}/admin/car/${searchID}`, {
        headers: {
          Authorization: `Bearer ${adminTocken}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setCar(data);
      });
  };

  const AdtivateConfirm = () => {
    axios
      .post<Car>(
        `${baseURLApi}/admin/car/active/${searchID}`,
        {},
        { headers: { Authorization: `Bearer ${adminTocken}` } },
      )
      .then((res) => res.data)
      .then((data) => {
        setCar(data);
      });
  };

  const ActivateCancel = () => {
    console.log("cancel");
  };

  const DeleteConfirm = () => {
    axios
      .delete<boolean>(`${baseURLApi}/admin/car/delete/${searchID}`, {
        headers: { Authorization: `Bearer ${adminTocken}` },
      })
      .then((data) => {
        setCar(null);
      });
  };

  const DeleteCancel = () => {
    console.log("cancel");
  };

  return (
    <>
      <div>SearchList</div>
      <div>
        <div>
          <Input value={searchID} onChange={handleChangeID} />
          <Button onClick={handleSearch}>Search</Button>
        </div>
        <div>
          {car && (
            <div>
              <div key={car._id}>
                <div>{car.VIN}</div>
                <div>
                  {car.brand} {car.model}
                </div>
                <div>{car.generation}</div>
                <div>
                  <Popconfirm
                    onConfirm={AdtivateConfirm}
                    onCancel={ActivateCancel}
                    title={`Are you sure you want to ${car.isActive ? "Deactivate" : "Activate"} this car?`}
                  >
                    <Button>{car.isActive ? "Deactivate" : "Activate"}</Button>
                  </Popconfirm>
                  <Popconfirm
                    onConfirm={DeleteConfirm}
                    onCancel={DeleteCancel}
                    title={"Are you sure you want to delete this car?"}
                  >
                    <Button>Delete</Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
