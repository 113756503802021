import { InputNumber, Slider, SliderSingleProps } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromTo, FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";

const MileagePickWrapperStyled = styled.div`
  width: 100%;
  margin: 0 auto;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
`;

const WrapperStyled = styled.div`
  border-radius: 8px;
  padding: 16px;
  background: rgb(255, 255, 255);
`;

const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-size: 18px;
  font-weight: 600;
  line-height: 175%;
`;

const SliderWrapperStyled = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputNumberStyled = styled(InputNumber)<{ onChange: (value: number | null) => void }>`
  flex: 1;
  height: 55px;
  display: flex;
  border: 1px solid rgb(219, 219, 219);

  & .ant-input-number-input-wrap {
    width: 100%;

    & input {
      height: 100%;
      color: rgb(0, 0, 0);
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      text-align: center;
    }
  }
`;

const InputBetweenTextStyled = styled.div`
  color: rgb(0, 0, 0);

  margin: 0 16px;
`;

const marks: SliderSingleProps["marks"] = {
  1000: "1000",

  50000: "50000",
  100000: "100000",
  150000: "150000",
};

export const MileagePick = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [mileage, setMileage] = useState<FromTo<number>>({ from: 0, to: 0 });

  const mileageValue = useAppSelector((state) => state.filters.selectedData.mileage);
  useEffect(() => {
    if (mileageValue) setMileage(mileageValue);
  }, [mileageValue]);

  const handleInputChange = (type: FromToEnum) => (value: number | null) => {
    if (value === null) return;

    dispatch(FiltersActions.setMileage({ type, value }));
  };
  const handleChange = (value: number[]) => {
    setMileage({ from: value[0], to: value[1] });
  };

  const handleChangeComplete = (value: number[]) => {
    dispatch(FiltersActions.setMileageSlider(value));
    setMileage({ from: value[0], to: value[1] });
  };

  const handleBlur = () => {
    if (mileage?.to && mileage?.from && mileage?.from > mileage?.to) {
      dispatch(FiltersActions.setMileage({ type: FromToEnum.TO, value: mileage.from }));
    }
  };
  return (
    <>
      <WrapperStyled id="mileage">
        <TitleStyled>{t("mileage")}</TitleStyled>
        <MileagePickWrapperStyled>
          <SliderWrapperStyled>
            <Slider
              marks={marks}
              range={{ draggableTrack: true }}
              value={[mileage?.from || 0, mileage?.to || 0]}
              min={1}
              max={160000}
              onChange={handleChange}
              onChangeComplete={handleChangeComplete}
            />
          </SliderWrapperStyled>
          <InputWrapper>
            <InputNumberStyled
              min={1}
              max={500000}
              style={{ margin: "0 16px" }}
              value={mileage?.from}
              step={100}
              onChange={handleInputChange(FromToEnum.FROM)}
              placeholder={t("from")}
              onBlur={handleBlur}
            />
            <InputBetweenTextStyled>{t("to")}</InputBetweenTextStyled>
            <InputNumberStyled
              min={1}
              max={500000}
              style={{ margin: "0 16px" }}
              value={mileage?.to}
              step={100}
              onChange={handleInputChange(FromToEnum.TO)}
              onBlur={handleBlur}
            />
          </InputWrapper>
        </MileagePickWrapperStyled>
      </WrapperStyled>
    </>
  );
};
