export enum MenuID {
  USE_INFO = "useInfo",
  BRAND = "brand",
  MODEL = "model",
  MODIFICATION = "modification",
  GENERATION = "generation",
  PRICE = "price",
  YEAR = "year",
  MILEAGE = "mileage",
  DRIVE_TYPE = "driveType",
  FUEL = "fuel",
  REGION = "region",
  POWER = "power",
  BODY_TYPE = "bodyType",
  COLOR = "color",
  GEAR = "gear",
  LIGHT = "light",
  SALON_MATERIAL = "salonMaterial",
  SALON_OPTION = "salonOption",
  SECURITY = "security",
  AIRBAG = "airbag",
  MULTIMEDIA = "multimedia",
  OTHER = "other",
}
