import { GenerationList } from "./cardLists/GenerationList";
import { ModificationsList } from "./cardLists/ModificationsList";
import { SingleSelectionCard } from "./cardLists/SingleSelectionList";
import { useAppSelector } from "../../../helpers/store.hook";
import { CardSearchEnum } from "../filters.types";

export const CardSearch = () => {
  const cardSearchType = useAppSelector((state) => state.filters.cardSearchType);

  return (
    <>
      {cardSearchType === CardSearchEnum.BRAND || cardSearchType === CardSearchEnum.MODEL ? (
        <SingleSelectionCard />
      ) : cardSearchType === CardSearchEnum.GENERATION ? (
        <GenerationList />
      ) : (
        <ModificationsList />
      )}
    </>
  );
};
