import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../../../helpers/interseptor";
import { BrandDTO, CarModelDTO, GenerationsDTO } from "../filters.dto";
import { Brands, CarModel } from "../filters.types";

interface ValidationErrors {
  errorMessage: string;
}

export const getBrandsThunk = createAsyncThunk<
  BrandDTO[],
  undefined,
  {
    rejectValue: ValidationErrors;
  }
>("getBrandsThunk", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.get<BrandDTO[]>("search/brands");

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const getModelsThunk = createAsyncThunk<
  CarModelDTO[],
  Brands,
  {
    rejectValue: ValidationErrors;
  }
>("getModelsThunk", async ({ searchName }, { rejectWithValue }) => {
  try {
    const response = await axios.get<CarModelDTO[]>(`search/models/${searchName}`);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const getGenerationsThunk = createAsyncThunk<
  GenerationsDTO[],
  CarModel,
  {
    rejectValue: ValidationErrors;
  }
>("getGenerationsThunk", async ({ searchName }, { rejectWithValue }) => {
  try {
    const response = await axios.get<GenerationsDTO[]>(`search/generations/${searchName}`);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});
