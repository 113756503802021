import { Navigate, Outlet } from "react-router-dom";

import { AuthRoutesPath } from ".";
import { useAppSelector } from "../helpers/store.hook";

export const ProtectedRoute = () => {
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  if (!isLogin) {
    return <Navigate to={AuthRoutesPath.loginPath} />;
  }

  return <Outlet />;
};
