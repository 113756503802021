import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { AdditionalInfoPyaload } from "../../../../car/CarTypes";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import diesel from "../../../asset/diesel.jpg";
import electro from "../../../asset/electro.jpg";
import gas from "../../../asset/gas.png";
import gasoline from "../../../asset/gasoline.webp";
import { Icons } from "../../../search.types";
import { MultiplePickAdditionalInfoCard } from "../../../shared/advancedSearch/MultiplePickAdditionalInfoCard";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { MenuID } from "../../../shared/constant/menuID";

const FuelPickWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;

export const FuelIcons: Icons = {
  "general-gas": gas,
  "general-electro": electro,
  gasoline: gasoline,
  diesel: diesel,
};

export const FuelPick = () => {
  const dispatch = useAppDispatch();
  const fuels = useAppSelector((state) => state.car.fuel);
  const selectedFuel = useAppSelector((state) => state.filters.selectedData.fuel);
  const handleClick = (value: AdditionalInfoPyaload) => {
    dispatch(FiltersActions.setFuelsBulk(value));
  };

  const handleFuel = ({ value }: AdditionalInfoPyaload) => {
    if (value) {
      dispatch(FiltersActions.setFuel(value));
    }
  };

  return (
    <SearchComponentWrapper title="fuel" count={selectedFuel?.length} id={MenuID.FUEL}>
      <FuelPickWrapperStyled>
        {fuels?.map((fuel) => (
          <MultiplePickAdditionalInfoCard<AdditionalInfoPyaload>
            key={fuel._id}
            value={fuel}
            img={FuelIcons[fuel.value]}
            onClick={handleClick}
            onOption={handleFuel}
            type="fuel"
          />
        ))}
      </FuelPickWrapperStyled>
    </SearchComponentWrapper>
  );
};
