import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../../../../constants/AdditionalInfo";
import { useAppSelector } from "../../../../../helpers/store.hook";
import beige from "../../../asset/beige.svg";
import black from "../../../asset/black.svg";
import blue from "../../../asset/blue.svg";
import brown from "../../../asset/brown.svg";
import green from "../../../asset/green.svg";
import grey from "../../../asset/grey.svg";
import orange from "../../../asset/orange.svg";
import purple from "../../../asset/purple.svg";
import red from "../../../asset/red.svg";
import white from "../../../asset/white.svg";
import yellow from "../../../asset/yellow.svg";
import { Icons } from "../../../search.types";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickAdditionalInfoCard } from "../../../shared/advancedSearch/SinglePickAdditionalInfo";
import { MenuID } from "../../../shared/constant/menuID";

export const ColorIcons: Icons = {
  black: black,
  blue: blue,
  green: green,
  beige: beige,
  red: red,
  purple: purple,
  orange: orange,
  yellow: yellow,
  white: white,
  brown: brown,
  grey: grey,
};

export const ColorsPick = () => {
  const selectedColor = useAppSelector((state) => state.filters.selectedData.color);

  return (
    <>
      <SearchComponentWrapper title="colors" count={selectedColor?.length} id={MenuID.COLOR}>
        <SinglePickAdditionalInfoCard
          type={AdditionalInfoDTOKeys.COLORS}
          selectedType={AdditionalInfoSearchSelectedDataKeys.COLOR}
          images={ColorIcons}
          svg
        />
      </SearchComponentWrapper>
    </>
  );
};
