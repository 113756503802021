import { Row } from "antd";
import qs from "qs";
import { createSearchParams, useNavigate } from "react-router-dom";

import { BreadCrumb } from "../components/filters/components/breadCrumb/breadCrumb";
import { CardSearch } from "../components/filters/components/cardSearch";
import { FiltersActions } from "../components/filters/redux/filters.slice";
import { SearchRow } from "../components/search/searchbar";
import { useAppDispatch, useAppSelector } from "../helpers/store.hook";
import { SearchRoutesPath } from "../routes";

// TODO Change card base on if it model or brand
export const MainPage = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const selectedData = useAppSelector((state) => state.filters.selectedData);
  const price = useAppSelector((state) => state.filters.selectedData.price);
  const year = useAppSelector((state) => state.filters.selectedData.year);

  const handleSearch = () => {
    const searchObject: {
      model?: string;
      brand?: string;
      generation?: string;
      priceFrom?: string;
      priceTo?: string;
      yearFrom?: string;
      yearTo?: string;
      page: string;
      limit: string;
    } = {
      page: "1",
      limit: "20",
    };
    if (selectedData?.model) {
      searchObject.model = selectedData.model.searchName;
    }
    if (selectedData?.brand) {
      searchObject.brand = selectedData.brand.searchName;
    }
    if (selectedData?.generations?.length) {
      const generations = selectedData.generations.reduce((acc: any, item) => {
        const modifications = item.options
          .filter((mod) => mod.isSelected)
          .map((mod) => mod.searchName);
        acc[item.searchName] = modifications;
        return acc;
      }, {});
      const stringified = qs.stringify(generations);
      searchObject.generation = stringified;
    }
    if (price?.from) {
      searchObject.priceFrom = String(price.from);
    }
    if (price?.to) {
      searchObject.priceTo = String(price.to);
    }
    if (year?.from) {
      searchObject.yearFrom = String(year.from);
    }
    if (year?.to) {
      searchObject.yearTo = String(year.to);
    }

    console.log("searchObject", searchObject);

    navigate({
      pathname: SearchRoutesPath.search,
      search: `${createSearchParams(searchObject)}`,
    });
  };

  const handleAdvanceSearch = () => {
    navigate(`${SearchRoutesPath.searchAdvanced}`);
  };
  return (
    <>
      <Row>
        <SearchRow handleSearch={handleSearch} handleAdvanceSearch={handleAdvanceSearch} />
      </Row>
      <Row>
        <BreadCrumb />
        <button onClick={() => dispatch(FiltersActions.clearBrand())}>Clear</button>
      </Row>
      <Row gutter={16}>
        <CardSearch />
      </Row>
    </>
  );
};
//TODO create container
