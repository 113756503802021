import { Col, Row } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

import { manageFavorite } from "../../../components/auth/redux/auth.thunks";
import { CarSlider } from "../../../components/car/components/carSlider";
import { Contacts } from "../../../components/car/components/Contacts";
import { CarDescription } from "../../../components/car/components/Description";
import { CarFeatures } from "../../../components/car/components/Features";
import { CarOptionsList } from "../../../components/car/components/Options";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";

const TitleStyled = styled.h1`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0%;
`;

const CarPageWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export interface CarInfo {
  VIN: string;
  brand: string;
  color: string;
  city: string;
  modification: string;
  bodyType: string;
  createdAt: string;
  engin: string;
  additionalOptions: { [key: string]: any[] };
  fuel: string;
  generation: string;
  mileage: number;
  power: number;
  price: number;
  model: string;
  photo: string[];
  gear: string;
  description: string;
  driveType: string;
  updatedAt: string;
  year: number;
  views: number;
  likes: number;
  _id: string;
  user: {
    telegram: string;
    name: string;
    phone: string;
  };
}

export const CarPage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const favorite = useAppSelector((state) => state.auth.favorite);
  const [car, setCar] = useState<CarInfo | null>(null);
  useEffect(() => {
    if (params.id) {
      axios
        .get(`/car/${params.id}`)
        .then((res) => res.data)
        .then((data) => setCar(data));

      const cookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith(params.id as string));

      if (cookieValue) {
        return;
      } //TODO increase max-age NOW it's 3 minutes
      document.cookie = `${params.id}=1; SameSite=None; Secure; max-age=180`;
      axios.post(`/car/update-view/${params.id}`);
    }
  }, [params.id]);

  const handleFaivorite = () => {
    if (params.id) {
      const isNew = !favorite.some((item) => item === params?.id);

      setCar(
        (prevCar) =>
          ({
            ...prevCar,
            likes: isNew ? (prevCar?.likes ?? 0) + 1 : (prevCar?.likes ?? 0) - 1,
          }) as CarInfo,
      );

      dispatch(manageFavorite({ id: params.id, isNew }));
    }
  };

  return (
    <>
      {car && (
        <CarPageWrapper>
          <TitleStyled>
            {car.brand.toUpperCase()} {car.model.toUpperCase()} {car.modification.toUpperCase()}
          </TitleStyled>
          <Row>
            <Col span={15}>
              <CarSlider photo={car.photo} brand={car.brand} model={car.model} />
              <CarDescription description={car.description} />
              <CarFeatures features={car.additionalOptions} />
              <div>Переглядів {car.views}</div>
              <button onClick={handleFaivorite}>
                {favorite.some((item) => item === params?.id) ? "Dislike" : "Like"}
              </button>

              <div>Liked {car.likes}</div>
            </Col>
            <Col span={9}>
              <CarOptionsList car={car} />
              <Contacts user={car.user} />
            </Col>
          </Row>
        </CarPageWrapper>
      )}
    </>
  );
};
