import React, { useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import styled from "styled-components";

import { Close } from "../../addCar/assets/Close";

const CarSliderWrapperStyled = styled.div`
  margin-bottom: 30px;
`;

const PreviewStyled = styled.div`
  width: 660px;
  height: auto;
`;

const SliderWarpperStyled = styled.div`
  width: 660px;
  height: auto;

  & .slick-slide {
    position: relative;

    &.slick-current {
      &::after {
        opacity: 0;
        transition: opacity 0.4s;
      }
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.4s;
    }
  }
`;

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, transform: "translate(-30px, -10px)" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, zIndex: 2, transform: "translate(30px, -10px)" }}
      onClick={onClick}
    />
  );
}

const settings: Settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  focusOnSelect: true,
  centerMode: true,
  centerPadding: "0px",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

interface CarSliderProps {
  photo: string[];
  brand: string;
  model: string;
}

export const CarSlider: React.FC<CarSliderProps> = ({ photo, brand, model }) => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [nav1, setNav1] = useState<Slider | undefined>();
  const [nav2, setNav2] = useState<Slider | undefined>();
  const sliderRef1 = useRef<Slider>(null);
  const sliderRef2 = useRef<Slider>(null);

  useEffect(() => {
    sliderRef1.current && setNav1(sliderRef1.current);
    sliderRef2.current && setNav2(sliderRef2.current);
  }, []);

  const handleOpenFullScreen = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  return (
    <>
      <CarSliderWrapperStyled>
        <PreviewStyled>
          <Slider asNavFor={nav2} ref={sliderRef1} arrows={false}>
            {photo.map((photo: string) => (
              <img
                key={photo}
                src={photo}
                alt={`${brand} ${model}`}
                onClick={handleOpenFullScreen}
              />
            ))}
          </Slider>
        </PreviewStyled>
        <SliderWarpperStyled>
          <Slider asNavFor={nav1} ref={sliderRef2} {...settings}>
            {photo.map((photo: string) => (
              <img key={photo} src={photo} alt={`${brand} ${model}`} />
            ))}
          </Slider>
        </SliderWarpperStyled>
      </CarSliderWrapperStyled>
      {isFullScreen && (
        <LargePreview
          photo={photo}
          brand={brand}
          model={model}
          onCloseFullScreen={handleCloseFullScreen}
        />
      )}
    </>
  );
};

const LargePreviewStyled = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
`;

const LargePreviewSliderWrapperStyled = styled.div`
  width: 1000px;
  margin: 0 auto;
  max-height: 80%;
  aspect-ratio: 4 / 3;
`;

const SmallPreviewStyled = styled.div`
  width: 90%;
  margin: 0 auto;
  height: 120px;
`;

const CloseWrapperStyled = styled.div`
  position: fixed;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 45px;
  height: 45px;
  & svg {
    width: 100%;
    height: 100%;
  }
`;

const LargePreview: React.FC<CarSliderProps & { onCloseFullScreen: () => void }> = ({
  photo,
  brand,
  model,
  onCloseFullScreen,
}) => {
  const [nav1, setNav1] = useState<Slider | undefined>();
  const [nav2, setNav2] = useState<Slider | undefined>();
  const sliderRef1 = useRef<Slider>(null);
  const sliderRef2 = useRef<Slider>(null);

  useEffect(() => {
    sliderRef1.current && setNav1(sliderRef1.current);
    sliderRef2.current && setNav2(sliderRef2.current);
  }, []);

  const onWheelSlider = (e: any) => {
    if (!sliderRef1.current) return;

    if (e.deltaY > 0) {
      sliderRef1.current.slickNext();
    } else if (e.deltaY < 0) {
      sliderRef1.current.slickPrev();
    }
  };

  const handleClose = (e: KeyboardEvent) => {
    if (e.keyCode === 27) onCloseFullScreen();
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window.addEventListener("keydown", handleClose);
    // eslint-disable-next-line no-undef
    document.body.style.overflow = "hidden";
    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener("keydown", handleClose);
      // eslint-disable-next-line no-undef
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <>
      <LargePreviewStyled onWheel={onWheelSlider}>
        <CloseWrapperStyled onClick={onCloseFullScreen}>
          <Close />
        </CloseWrapperStyled>
        <LargePreviewSliderWrapperStyled>
          <Slider asNavFor={nav2} ref={sliderRef1} arrows={false} speed={300}>
            {photo.map((photo: string) => (
              <img key={photo} src={photo} alt={`${brand} ${model}`} />
            ))}
          </Slider>
        </LargePreviewSliderWrapperStyled>
        <SmallPreviewStyled>
          <Slider asNavFor={nav1} ref={sliderRef2} {...settings} slidesToShow={8} speed={300}>
            {photo.map((photo: string) => (
              <img key={photo} src={photo} alt={`${brand} ${model}`} />
            ))}
          </Slider>
        </SmallPreviewStyled>
      </LargePreviewStyled>
    </>
  );
};
