import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  CarGetAdditionalInfoThunk,
  CarGetBrandsThunk,
  CarSelectBrandThunk,
  CarSelectGenerationsThunk,
  CarSubmitThunk,
  getCarByIdThunk,
} from "./car.thunks";
import { UseInfo } from "../../../constants/AdditionalInfo";
import { createEngineOptions } from "../../../helpers/createEngineOptions";
import { createRegionOptions } from "../../../helpers/createRegionOptions";
import { createYearOptions } from "../../../helpers/createYearOptions";
import { baseURL } from "../../../helpers/interseptor";
import i18next from "../../../i18n/config";
import {
  CarState,
  SelectedDataBooleanKeys,
  SelectedDataNumberKeys,
  SelectedDataStringKeys,
} from "../CarTypes";
import { AdditionalIonfoKeys, MenuID } from "../constant";

const initialState: CarState = {
  inProgress: false,
  currentVisibleMenu: MenuID.GENERAL,
  brands: [],
  models: [],
  generations: [],
  modification: [],
  year: createYearOptions(),
  engine: createEngineOptions(),
  region: createRegionOptions(),
  fuel: [],
  color: [],
  gear: [],
  bodyType: [],
  headlight: [],
  salonMaterial: [],
  salonColor: [],
  driveType: [],
  optic: [],
  salon: [],
  security: [],
  airbag: [],
  multimedia: [],
  other: [],
  selectedData: {
    afterAccident: false,
    additionalOptions: {
      headlight: [],
      optic: [],
      other: [],
      salon: [],
      salonMaterial: [],
      security: [],
      airbag: [],
      multimedia: [],
    },
    photo: [],
  },
};

const carSlice = createSlice({
  name: "Car",
  initialState,
  reducers: {
    clearBrand: (state) => {
      state.selectedData.brand = undefined;
      state.selectedData.model = undefined;
      state.selectedData.parentModel = undefined;
      state.selectedData.generation = undefined;
      state.selectedData.modification = undefined;
      state.models = [];
      state.generations = [];
      state.modification = [];
    },
    clearModel: (state) => {
      state.selectedData.model = undefined;
      state.selectedData.parentModel = undefined;
      state.selectedData.generation = undefined;
      state.selectedData.modification = undefined;
      state.generations = [];
      state.modification = [];
    },
    clearGeneration: (state) => {
      state.selectedData.generation = undefined;
      state.selectedData.modification = undefined;
      state.modification = [];
    },
    clearModification: (state) => {
      state.selectedData.modification = undefined;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.selectedData.description = action.payload;
    },
    setValue: (
      state,
      action: PayloadAction<{
        key: SelectedDataStringKeys;
        value: string;
      }>,
    ) => {
      state.selectedData[action.payload.key] = action.payload.value;
    },
    clearValue: (state, action: PayloadAction<{ key: SelectedDataStringKeys | "year" }>) => {
      state.selectedData[action.payload.key] = undefined;
    },
    setNumberValue: (
      state,
      action: PayloadAction<{
        key: SelectedDataNumberKeys;
        value: number;
      }>,
    ) => {
      state.selectedData[action.payload.key] = action.payload.value;
    },
    setUseInfo: (state, action: PayloadAction<UseInfo>) => {
      state.selectedData.useInfo = action.payload;
    },
    setBoolean: (
      state,
      action: PayloadAction<{ key: SelectedDataBooleanKeys; value: boolean }>,
    ) => {
      state.selectedData[action.payload.key] = action.payload.value;
    },
    setGeneration: (state, action: PayloadAction<{ value: string; searchName: string }>) => {
      const modifications = state.generations.find(
        ({ searchName }) => searchName === action.payload.searchName,
      );
      state.selectedData.generation = action.payload.value;
      state.selectedData.modification = undefined;
      state.modification = [];
      if (modifications) {
        state.modification = modifications.modifications.map((mod) => ({
          ...mod,
          label: mod.name,
          value: mod.searchName,
        }));
      }
    },

    setSelectedAdditionalOptions: (
      state,
      action: PayloadAction<{ name: AdditionalIonfoKeys; option: string }>,
    ) => {
      const isExist = state.selectedData.additionalOptions[action.payload.name]?.includes(
        action.payload.option,
      );
      if (isExist) {
        state.selectedData.additionalOptions[action.payload.name] =
          state.selectedData.additionalOptions[action.payload.name]?.filter(
            (item) => item !== action.payload.option,
          );
      } else {
        state.selectedData.additionalOptions[action.payload.name]?.push(action.payload.option);
      }
    },
    setSelectedAdditionalOptionsSelect: (
      state,
      action: PayloadAction<{ name: AdditionalIonfoKeys; option: string }>,
    ) => {
      state.selectedData.additionalOptions[action.payload.name] = [action.payload.option];
    },
    clearSelectedAdditionalOptionsSelect: (
      state,
      action: PayloadAction<{ name: AdditionalIonfoKeys }>,
    ) => {
      state.selectedData.additionalOptions[action.payload.name] = [];
    },

    setSelectedPhotos: (
      state,
      action: PayloadAction<{ image: string; _id: string; type: string }[]>,
    ) => {
      state.selectedData.photo.push(...action.payload);
    },

    updateSelectedPhoto: (
      state,
      { payload }: PayloadAction<{ image: string; _id: string; type: string }>,
    ) => {
      state.selectedData.photo = state.selectedData.photo.map((img) =>
        img._id === payload._id ? payload : img,
      );
    },
    setCurrentVisibleMenu: (state, action: PayloadAction<MenuID>) => {
      state.currentVisibleMenu = action.payload;
    },

    deleteSelectedPhoto: (state, action: PayloadAction<string>) => {
      state.selectedData.photo = state.selectedData.photo.filter(
        ({ _id }) => _id !== action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CarGetBrandsThunk.fulfilled, (state, action) => {
        state.brands = action.payload.map((brand) => ({
          ...brand,
          label: brand.shortName,
          value: brand.shortName,
        }));
      })
      .addCase(CarSelectBrandThunk.pending, (state, action) => {
        state.selectedData.brand = action.meta.arg.value;
        state.selectedData.model = undefined;
        state.selectedData.parentModel = undefined;
        state.selectedData.generation = undefined;
        state.selectedData.modification = undefined;
        state.models = [];
        state.generations = [];
        state.modification = [];
      })
      .addCase(CarSelectBrandThunk.fulfilled, (state, action) => {
        state.models = action.payload.map(({ name, series, ...rest }) => {
          if (series?.length) {
            return {
              ...rest,
              title: name,
              label: name,
              value: rest.searchName,
              name: name,
              options: [
                ...series.map((model) => ({
                  ...model,
                  label: model.name,
                  value: model.searchName,
                  name: model.name,
                  parentModel: rest.searchName,
                })),
              ],
            };
          }
          return {
            ...rest,
            label: name,
            value: rest.searchName,
            name: name,
          };
        });
      })
      .addCase(CarSelectGenerationsThunk.pending, (state, action) => {
        state.selectedData.model = action.meta.arg.searchName;
        state.selectedData.generation = undefined;
        state.selectedData.modification = undefined;
        state.modification = [];
        state.generations = [];
        if (action.meta.arg?.parentModel) {
          state.selectedData.parentModel = action.meta.arg.parentModel;
        } else {
          state.selectedData.parentModel = undefined;
        }
      })
      .addCase(CarSelectGenerationsThunk.fulfilled, (state, action) => {
        state.generations = action.payload
          .map(({ options, ...gen }) => ({
            ...gen,
            _id: gen._id,
            label: `${gen.name}: ${gen.yearStart}-${gen?.yearEnd || i18next.t("now")}`,
            value: gen.searchName,
            modifications: options,
          }))
          .reverse();
      })
      .addCase(CarGetAdditionalInfoThunk.fulfilled, (state, action) => {
        action.payload.forEach(({ _id, values }) => {
          state[_id] = values.map(({ options, value, ...rest }) => ({
            ...rest,
            label: i18next.t(rest.searchName),
            value: rest.searchName,
            ...(options.length
              ? {
                  options: options?.map(({ options, ...opt }) => ({
                    ...opt,
                    label: opt.value,
                    value: opt.searchName,
                  })),
                }
              : {}),
          }));
        });
      })
      .addCase(getCarByIdThunk.fulfilled, (state, action) => {
        const photo = action.payload.photo.map((img: string, index: number) => ({
          image: baseURL + img,
          _id: index,
        }));
        state.selectedData = { ...action.payload, photo };
      })
      .addCase(CarSubmitThunk.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(CarSubmitThunk.fulfilled, (state) => {
        state.inProgress = false;
      });
  },
});

export const CarActions = carSlice.actions;

export default carSlice.reducer;
