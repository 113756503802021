import qs from "qs";

import { AdditionalInfoSearchSelectedDataKeys } from "../../../constants/AdditionalInfo";
import { FromTo, SelectedData, SelectedGeneration } from "../../filters/filters.types";

const SearchNameItems = ["brand", "model"];

const FromToItems = ["price", "year", "power", "mileage"];

const SimpleSearchItems: (
  | AdditionalInfoSearchSelectedDataKeys
  | "useInfo"
  | "region"
  | "afterAccident"
)[] = [
  AdditionalInfoSearchSelectedDataKeys.OTHER,
  AdditionalInfoSearchSelectedDataKeys.MULTIMEDIA,
  AdditionalInfoSearchSelectedDataKeys.AIRBAG,
  AdditionalInfoSearchSelectedDataKeys.SECURITY,
  AdditionalInfoSearchSelectedDataKeys.SECURITY,
  AdditionalInfoSearchSelectedDataKeys.SALON,
  AdditionalInfoSearchSelectedDataKeys.SALON_MATERIAL,
  AdditionalInfoSearchSelectedDataKeys.OPTIC,
  AdditionalInfoSearchSelectedDataKeys.HEADLIGHTS,
  AdditionalInfoSearchSelectedDataKeys.LIGHT,
  AdditionalInfoSearchSelectedDataKeys.GEAR,
  AdditionalInfoSearchSelectedDataKeys.COLOR,
  AdditionalInfoSearchSelectedDataKeys.BODY_TYPE,
  AdditionalInfoSearchSelectedDataKeys.FUEL,
  AdditionalInfoSearchSelectedDataKeys.DRIVE_TYPE,
  "useInfo",
  "region",
  "afterAccident",
];

export const createGenSearch = (generations: SelectedGeneration[]) => {
  const generationsNext = generations.reduce((acc: { [key: string]: string[] }, item) => {
    const modifications = item.options.filter((mod) => mod.isSelected).map((mod) => mod.searchName);
    acc[item.searchName] = modifications;
    return acc;
  }, {});
  const stringified = qs.stringify(generationsNext, { allowEmptyArrays: true });
  return stringified;
};

export const createSeach = (selectedData: SelectedData) => {
  const searchObject: {
    page: string;
    limit: string;
    [key: string]: string | string[];
  } = {
    page: "1",
    limit: "20",
  };
  if (selectedData) {
    (Object.keys(selectedData ?? {}) as Array<keyof typeof selectedData>).forEach((key) => {
      if (SearchNameItems.includes(key) && selectedData[key]) {
        searchObject[key] = (selectedData[key] as { searchName: string } | undefined)
          ?.searchName as string;
      } else if (FromToItems.includes(key)) {
        if ((selectedData[key] as FromTo<number>)?.from) {
          searchObject[`${key}From`] = String((selectedData[key] as FromTo<number>)?.from);
        }
        if ((selectedData[key] as FromTo<number>)?.to) {
          searchObject[`${key}To`] = String((selectedData[key] as FromTo<number>)?.to);
        }
      } else if (
        SimpleSearchItems.includes(
          key as AdditionalInfoSearchSelectedDataKeys | "useInfo" | "region",
        )
      ) {
        searchObject[key] = selectedData[key] as string[];
      } else if (selectedData?.generations?.length) {
        searchObject.generation = createGenSearch(selectedData.generations);
      }
    });
  }
  return searchObject;
};
