import { useTranslation } from "react-i18next";

import { FormItemStyled, LabelStyled } from "./form";
import { CheckBoxAdditionalInfoAddCar, SimpleSelectAdditionalInfoAddCar } from "./simpleSelect";
import { SALON_MATERIAL } from "../../../../constants/AdditionalInfo";
import { AdditionalIonfoKeys } from "../../constant";

export const SalonAddCar: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <SimpleSelectAdditionalInfoAddCar
        optionsKey={SALON_MATERIAL}
        type={AdditionalIonfoKeys.salonMaterial}
        required={false}
      />

      <FormItemStyled
        label={<LabelStyled>{t("addCar.salonMaterial.label")}</LabelStyled>}
        name={SALON_MATERIAL}
        rules={[{ required: false, message: t("addCar.salonMaterial.error") }]}
      >
        <CheckBoxAdditionalInfoAddCar name={AdditionalIonfoKeys.salon} />
      </FormItemStyled>
    </div>
  );
};
