import { Button, Form, Input } from "antd";
import { useState } from "react";

import { ChangePasswordThunk } from "../../components/auth/redux/auth.thunks";
import axios from "../../helpers/interseptor";
import { useAppDispatch } from "../../helpers/store.hook";

export const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const [number, setNumber] = useState("");
  const [isSentNumberDisabled, setIsSentNumberDisabled] = useState(true);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const handleNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(e.target.value);
    if (e.target.value.length === 9) {
      setIsSentNumberDisabled(false);
    } else {
      setIsSentNumberDisabled(true);
    }
  };

  const sendPhone = async () => {
    try {
      await axios.post("auth/forgot-password-request", { phone: `380${number}` });
      setIsRequestSent(true);
    } catch (e) {
      console.log("eRROR", e);
    }
  };

  const onFinish = (values: { code: string; password: string; password_repeat: string }) => {
    console.log("Success:", values);
    dispatch(ChangePasswordThunk({ ...values, phone: `380${number}` }));
  };

  const onFinishFailed = (errorInfo: {
    values: { code: string; password: string; password_repeat: string };
    errorFields: {
      name: (string | number)[];
      errors: string[];
    }[];
    outOfDate: boolean;
  }) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <div>
        <Input addonBefore={380} value={number} onChange={handleNumber} />
        <Button disabled={isSentNumberDisabled} onClick={sendPhone}>
          Send
        </Button>
      </div>
      {isRequestSent && (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: "Please input your password!" }, { len: 4 }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Password Repeat"
            name="password_repeat"
            rules={[
              { required: true, message: "Please input your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!"),
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
