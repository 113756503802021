import { Button, FormInstance } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FormItemStyled, LabelStyled } from "./form";
import { PRICE } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";
import { InputNumberStyled } from "../../shared/Input";

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ControlWrapperStyled = styled.div`
  display: flex;
  gap: 8px;
`;

const ControlButtonStyled = styled(Button)`
  border-radius: 8px;
  height: 100%;
  width: 56px;
  background: rgb(240, 240, 240);
  font-family: Roboto;
  font-weight: 400;
  font-size: 22px;
  color: rgb(28, 27, 31);
`;

const MIN_PRICE = 0;
const MAX_PRICE = 2000000;

export const PriceAddCar: React.FC<{ form: FormInstance }> = React.memo(({ form }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const value = useAppSelector((state) => state.car.selectedData.price);
  const handleChange = (value: number | null) => {
    if (value) dispatch(CarActions.setNumberValue({ key: "price", value: value }));
  };

  const handleChangeControl = (data: number) => {
    let currentValue = 0;
    if (value) {
      currentValue = value;
    }
    if (currentValue + data >= 0 && currentValue + data <= 2000000) {
      dispatch(CarActions.setNumberValue({ key: "price", value: currentValue + data }));
      form.setFieldsValue({ price: currentValue + data });
    }
  };
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.price.label")}</LabelStyled>}
      name={PRICE}
      rules={[{ required: true, message: t("addCar.price.error") }]}
    >
      <Wrapper>
        <InputNumberStyled
          controls={false}
          value={value}
          onChange={handleChange}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          min={MIN_PRICE}
          max={MAX_PRICE}
        />
        <ControlWrapperStyled>
          <ControlButtonStyled onClick={() => handleChangeControl(100)}>+</ControlButtonStyled>
          <ControlButtonStyled onClick={() => handleChangeControl(-100)}>-</ControlButtonStyled>
        </ControlWrapperStyled>
      </Wrapper>
    </FormItemStyled>
  );
});
