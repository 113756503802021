import { InputNumber, Slider, SliderSingleProps } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FromTo, FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { MenuID } from "../../../shared/constant/menuID";

const WrapperStyled = styled.div`
  border-radius: 8px;
  padding: 16px;
  background: rgb(255, 255, 255);
`;

const PowerPickWrapperStyled = styled.div`
  width: 100%;
  margin: 0 auto;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
`;

const SliderWrapperStyled = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputNumberStyled = styled(InputNumber)<{ onChange: (value: number | null) => void }>`
  flex: 1;
  height: 55px;
  display: flex;
  border: 1px solid rgb(219, 219, 219);

  & .ant-input-number-input-wrap {
    width: 100%;

    & input {
      height: 100%;
      color: rgb(0, 0, 0);
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      text-align: center;
    }
  }
`;

const InputBetweenTextStyled = styled.div`
  color: rgb(0, 0, 0);

  margin: 0 16px;
`;

const marks: SliderSingleProps["marks"] = {
  50: "50",
  100: "100",
  200: "200",
  300: "300",
  400: "400",
  600: "600+",
};

export const PowerPick = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [power, setPower] = useState<FromTo<number>>({ from: 0, to: 0 });

  const powerValue = useAppSelector((state) => state.filters.selectedData.power);
  useEffect(() => {
    if (powerValue) setPower(powerValue);
  }, [powerValue]);

  const handleInputChange = (type: FromToEnum) => (value: number | null) => {
    if (value === null) return;

    dispatch(FiltersActions.setPowers({ type, value }));
  };
  const handleChangeComplete = (value: number[]) => {
    dispatch(FiltersActions.setPower(value));
  };

  const handleChange = (value: number[]) => {
    setPower({ from: value[0], to: value[1] });
  };

  const handleBlur = () => {
    if (power?.to && power?.from && power?.from > power?.to) {
      dispatch(FiltersActions.setPowers({ type: FromToEnum.TO, value: power.from }));
    }
  };

  return (
    <>
      <SearchComponentWrapper
        title="power"
        count={power?.from || power?.to ? 1 : 0}
        id={MenuID.POWER}
      >
        <WrapperStyled>
          <PowerPickWrapperStyled>
            <SliderWrapperStyled>
              <Slider
                marks={marks}
                range={{ draggableTrack: true }}
                value={[power?.from || 0, power?.to || 0]}
                min={20}
                max={600}
                onChange={handleChange}
                onChangeComplete={handleChangeComplete}
              />
            </SliderWrapperStyled>
            <InputWrapper>
              <InputNumberStyled
                min={1}
                max={2000}
                style={{ margin: "0 16px" }}
                value={power?.from}
                onChange={handleInputChange(FromToEnum.FROM)}
                onBlur={handleBlur}
              />
              <InputBetweenTextStyled>{t("to")}</InputBetweenTextStyled>
              <InputNumberStyled
                min={1}
                max={2000}
                style={{ margin: "0 16px" }}
                value={power?.to}
                onChange={handleInputChange(FromToEnum.TO)}
                onBlur={handleBlur}
              />
            </InputWrapper>
          </PowerPickWrapperStyled>
        </WrapperStyled>
      </SearchComponentWrapper>
    </>
  );
};
