import { useEffect } from "react";

import { useAppDispatch } from "../../../helpers/store.hook";
import useOnScreen from "../../search/utils/useScroll";
import { MenuID } from "../constant";
import { CarActions } from "../redux/car.slice";

export const LinkWrapper: React.FC<{ children: React.ReactNode; id: MenuID }> = ({
  children,
  id,
}) => {
  const dispatch = useAppDispatch();
  const [ref, isVisible] = useOnScreen({
    threshold: 0, // Trigger as soon as the top of the element hits the top of the screen
    rootMargin: "0px 0px -80% 0px",
  });

  useEffect(() => {
    if (isVisible) {
      dispatch(CarActions.setCurrentVisibleMenu(id));
    }
  }, [isVisible, dispatch, id]);
  return (
    <div ref={ref} id={id}>
      {children}
    </div>
  );
};
