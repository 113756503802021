import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import axios from "../../../../helpers/interseptor";
import { CarRoutesPath } from "../../../../routes";
import { SearchCart } from "../../../search/components/searchCart";

const ListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 25px auto;
  max-width: 1600px;
`;

const FooterWrapperStyled = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px;
`;

const EditButton = styled(Link)`
  box-sizing: border-box;
  border: 1px solid rgb(255, 192, 0);
  border-radius: 5px;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0%;
  padding: 0 15px;

  &:hover {
    color: rgb(255, 192, 0);
  }
`;
const DeleteButton = styled.div`
  box-sizing: border-box;
  border: 1px solid rgb(255, 192, 0);
  border-radius: 5px;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0%;
  padding: 0 15px;
  cursor: pointer;
  &:hover {
    color: rgb(255, 192, 0);
  }
`;

const Footer = ({
  _id,
  isActive,
  onDelete,
  upToTop,
  onActive,
}: {
  _id: string;
  isActive: boolean;
  onDelete: (_id: string) => void;
  upToTop: (_id: string) => void;
  onActive: (_id: string, active: boolean) => void;
}) => {
  const handleDelete = () => {
    onDelete(_id);
  };

  const handleUpToTop = () => {
    upToTop(_id);
  };
  const handleActive = () => {
    onActive(_id, !isActive);
  };

  return (
    <FooterWrapperStyled>
      <EditButton to={`${CarRoutesPath.car}${CarRoutesPath.edit}/${_id}`}>Редагувати</EditButton>
      <DeleteButton onClick={handleActive}>{isActive ? "Deactivate" : "Activate "} </DeleteButton>
      <DeleteButton onClick={handleUpToTop}>up To Top</DeleteButton>
      <DeleteButton onClick={handleDelete}>Видалити</DeleteButton>
    </FooterWrapperStyled>
  );
};

//TODO add good catch check
export const UserCarsList = () => {
  const [cars, setCars] = useState<
    {
      VIN: string;
      brand: string;
      color: string;
      createdAt: string;
      engin: string;
      features: any[];
      fuel: string;
      generation: string;
      mileage: number;
      model: string;
      photo: string;
      transmission: string;
      updatedAt: string;
      year: number;
      _id: string;
      isActive: boolean;
    }[]
  >([]);
  useEffect(() => {
    const controller = new AbortController();
    axios
      .get<any>("/car/user-cars", {
        signal: controller.signal,
      })
      .then((res) => res.data)
      .then((data) => setCars(data))
      .catch((e) => console.log(e));
    return () => controller.abort();
  }, []);

  const handleDelete = (id: string) => {
    axios
      .delete(`car/delete/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setCars(cars.filter((car) => car._id !== id));
        }
      })
      .catch((e) => console.log(e));
  };

  const handleUpToTop = (id: string) => {
    axios
      .post(`car/up-to-top/${id}`)
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  const handleActive = (id: string, isActive: boolean) => {
    axios
      .post(`car/active/${id}`, { isActive: true })
      .then((res) => {
        setCars(
          cars.map((car) => {
            if (car._id === id) {
              return res.data;
            }
            return car;
          }),
        );
      })
      .catch((e) => console.log(e));
  };

  return (
    <ListStyled>
      {cars.map((car) => (
        <SearchCart
          {...car}
          key={car._id}
          _id={car._id}
          link={`${CarRoutesPath.car}/${car._id}`}
          footer={
            <Footer
              _id={car._id}
              onDelete={handleDelete}
              upToTop={handleUpToTop}
              isActive={car.isActive}
              onActive={handleActive}
            />
          }
        />
      ))}
    </ListStyled>
  );
};
//TODO Implement user car page
