import { Button } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppDispatch } from "../../../../helpers/store.hook";
import { SearchActions } from "../../redux/search.slice";
import useOnScreen from "../../utils/useScroll";
import { MenuID } from "../constant/menuID";

const TitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(219, 219, 219);
`;

const TitleTextWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

const TitleTextStyled = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: rgb(46, 46, 46);
  font-weight: 500;
  line-height: 175%;
  letter-spacing: 0;
`;

const SelectedCountStyled = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border-radius: 100px;
  background: rgb(116, 99, 232);
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  color: rgb(250, 250, 250);
  margin-left: 8px;
`;

const CollapseButtonStyled = styled(Button)``;

const CollapseSvgStyled = styled.svg<{ $isActive?: boolean }>`
  transform: ${(props) => (props.$isActive ? "rotate(0deg)" : "rotate(180deg)")};
`;

const WrapperStyled = styled.div`
  background-color: #e8e8e8;
  border-radius: 8px;
  padding: 16px 16px 0px 16px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 16px;
`;

const ChildWrapperStyled = styled.div`
  margin-top: 16px;
  padding-bottom: 16px;
`;

interface SearchComponentWrapperProps {
  title: string;
  children: React.ReactNode;
  count?: number;
  titleTranslated?: string;
  id: MenuID;
}
export const SearchComponentWrapper: React.FC<SearchComponentWrapperProps> = ({
  title,
  children,
  count,
  titleTranslated,
  id,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [ref, isVisible] = useOnScreen({
    threshold: 0, // Trigger as soon as the top of the element hits the top of the screen
    rootMargin: "0px 0px -80% 0px",
  });
  const [isActive, setIsActive] = useState(true);
  const handleActive = () => setIsActive((prev) => !prev);

  useEffect(() => {
    if (isVisible && title) {
      dispatch(SearchActions.setCurrentPosition(titleTranslated || title));
    }
  }, [isVisible, title, dispatch, titleTranslated]);

  return (
    <WrapperStyled ref={ref} id={id}>
      <TitleStyled>
        <TitleTextWrapperStyled>
          <TitleTextStyled>{titleTranslated || t(title)}</TitleTextStyled>
          {!!count && <SelectedCountStyled>{count}</SelectedCountStyled>}
        </TitleTextWrapperStyled>
        <CollapseButtonStyled type="text" onClick={handleActive}>
          <CollapseSvgStyled
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            $isActive={isActive}
          >
            <mask id="mask0_338_4684" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_338_4684)">
              <path
                d="M12.0004 15.0537L6.34668 9.39994L7.40043 8.34619L12.0004 12.9462L16.6004 8.34619L17.6542 9.39994L12.0004 15.0537Z"
                fill="#1C1B1F"
              />
            </g>
          </CollapseSvgStyled>
        </CollapseButtonStyled>
      </TitleStyled>
      {isActive && <ChildWrapperStyled>{children}</ChildWrapperStyled>}
    </WrapperStyled>
  );
};
