import { useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CardItemSingle } from "../../../common/Card";
import { Brands, CarModel, CardSearchEnum } from "../../filters.types";
import { FiltersActions } from "../../redux/filters.slice";
import { getGenerationsThunk, getModelsThunk } from "../../redux/filters.thunks";

export const SingleSelectionCard = () => {
  const dispatch = useAppDispatch();

  const cardSearchType = useAppSelector((state) => state.filters.cardSearchType);
  const brands = useAppSelector((state) => state.filters.brands);
  const models = useAppSelector((state) => state.filters.models);
  const selectedModel = useAppSelector((state) => state.filters.selectedData.model);

  const options = useMemo(() => {
    switch (cardSearchType) {
      case CardSearchEnum.BRAND: {
        return brands;
      }
      case CardSearchEnum.MODEL: {
        const model = models.find((model) => model._id === selectedModel?._id);

        if (model?.options?.length) {
          return model.options;
        }
        return models;
      }
    }
    return [];
  }, [brands, cardSearchType, models, selectedModel]);

  const handleClick = (id: string, options: Brands | CarModel) => {
    if (cardSearchType === CardSearchEnum.BRAND) {
      dispatch(getModelsThunk(options as Brands));
      return;
    }
    if ((options as CarModel)?.options?.length) {
      dispatch(FiltersActions.setModel(options as CarModel));
      return;
    }

    dispatch(getGenerationsThunk(options as CarModel));
  };

  return (
    <>
      {options?.map((option) => (
        <CardItemSingle
          key={option._id}
          _id={option._id}
          image={option.image}
          name={option.name}
          searchName={option.searchName}
          options={(option as CarModel)?.options}
          onClick={handleClick}
        />
      ))}
    </>
  );
};
