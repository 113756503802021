import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../../../../constants/AdditionalInfo";
import { useAppSelector } from "../../../../../helpers/store.hook";
import cabriolet from "../../../asset/cabriolet.svg";
import coupe from "../../../asset/coupe.svg";
import hatchback from "../../../asset/hatchback.svg";
import pickup from "../../../asset/pickup.svg";
import roadster from "../../../asset/roadster.svg";
import sedan from "../../../asset/sedan.svg";
import supercar from "../../../asset/supercar.svg";
import crossover from "../../../asset/suv.svg";
import universal from "../../../asset/universal.svg";
import { Icons } from "../../../search.types";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickAdditionalInfoCard } from "../../../shared/advancedSearch/SinglePickAdditionalInfo";
import { MenuID } from "../../../shared/constant/menuID";

export const BodyTypeIcons: Icons = {
  cabriolet,
  coupe,
  hatchback,
  pickup,
  roadster,
  sedan,
  crossover,
  universal,
  supercar,
};

export const BodyTypesPick = () => {
  const selectedBodyType = useAppSelector((state) => state.filters.selectedData.bodyType);

  return (
    <>
      <SearchComponentWrapper
        title="bodyTypes"
        count={selectedBodyType?.length}
        id={MenuID.BODY_TYPE}
      >
        <SinglePickAdditionalInfoCard
          type={AdditionalInfoDTOKeys.BODY_TYPES}
          selectedType={AdditionalInfoSearchSelectedDataKeys.BODY_TYPE}
          images={BodyTypeIcons}
          svg
        />
      </SearchComponentWrapper>
    </>
  );
};
