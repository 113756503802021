import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../../../../constants/AdditionalInfo";
import { useAppSelector } from "../../../../../helpers/store.hook";
import centralBetweenDriverPassenger from "../../../asset/airbag-centered.jpg";
import driverer from "../../../asset/airbag-driver.jpg";
import driverKnee from "../../../asset/airbag-driverer-knee.jpg";
import SideFront from "../../../asset/airbag-front-side.jpg";
import Passengerer from "../../../asset/airbag-passenger.jpg";
import LateralRear from "../../../asset/airbag-rear-side.jpg";
import WindowCurtains from "../../../asset/airbag-window-curtains.jpg";
import { Icons } from "../../../search.types";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickAdditionalInfoCard } from "../../../shared/advancedSearch/SinglePickAdditionalInfo";
import { MenuID } from "../../../shared/constant/menuID";

export const AirBagIcons: Icons = {
  "central-between-driver-passenger": centralBetweenDriverPassenger,
  "driverer-knee": driverKnee,
  driverer: driverer,
  "lateral-rear": LateralRear,
  passengerer: Passengerer,
  "side-front": SideFront,
  "window-curtains": WindowCurtains,
};

export const AirBagPick = () => {
  const selectedAirBag = useAppSelector((state) => state.filters.selectedData.airbag);

  return (
    <SearchComponentWrapper title="airbags" count={selectedAirBag?.length} id={MenuID.AIRBAG}>
      <SinglePickAdditionalInfoCard
        type={AdditionalInfoDTOKeys.AIRBAGS}
        selectedType={AdditionalInfoSearchSelectedDataKeys.AIRBAG}
        images={AirBagIcons}
      />
    </SearchComponentWrapper>
  );
};
