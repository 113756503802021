import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../../../helpers/interseptor";
import { LocalStorageKeys, UserDTO, loginDTO } from "../types";
import { getToken, setTokenLocal, deleteTokenLocal } from "../utils/tokenLocal";

interface ValidationErrors {
  errorMessage: string;
}

export const ChangePasswordThunk = createAsyncThunk<
  loginDTO,
  {
    code: string;
    password: string;
    phone: string;
  },
  {
    rejectValue: ValidationErrors;
  }
>("changePasswordThunk", async ({ code, password, phone }, { rejectWithValue }) => {
  try {
    const response = await axios.post<loginDTO>("auth/change-forgot-password", {
      code,
      password,
      phone,
    });
    return response.data;
  } catch (e) {
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const VerifyPhoneThunk = createAsyncThunk<
  loginDTO,
  {
    code: string;
  },
  {
    rejectValue: ValidationErrors;
  }
>("verifyPhoneThunk", async ({ code }, { rejectWithValue }) => {
  try {
    const response = await axios.post<loginDTO>("auth/verify-phone", { code });

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const AuthThunk = createAsyncThunk<
  loginDTO,
  {
    userData: {
      name?: string;
      password: string;
      phone: string;
    };
    type: "register" | "login";
  },
  {
    rejectValue: ValidationErrors;
  }
>("authThunk", async ({ userData, type }, { rejectWithValue }) => {
  try {
    let response;
    if (type === "register") {
      response = await axios.post<loginDTO>("auth/sign-in", userData);
    } else {
      response = await axios.post<loginDTO>("auth/login", userData);
    }

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }
    setTokenLocal(response.data.tokens);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const getCurrentUser = createAsyncThunk<
  UserDTO,
  undefined,
  {
    rejectValue: ValidationErrors;
  }
>("getCurrentUser", async (args, { rejectWithValue }) => {
  if (getToken(LocalStorageKeys.JWT_ACCESS) === null) return rejectWithValue({ errorMessage: "" });
  try {
    const response = await axios.get<UserDTO>("auth/getUser");

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }

    return response.data;
  } catch (e) {
    deleteTokenLocal();
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);

    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const manageFavorite = createAsyncThunk<
  UserDTO,
  { id: string; isNew: boolean },
  { rejectValue: ValidationErrors }
>("addToFavorite", async ({ id, isNew }, { rejectWithValue }) => {
  try {
    let response;
    if (isNew) {
      response = await axios.post<UserDTO>(`user/favorite/${id}`);
    } else {
      response = await axios.delete<UserDTO>(`user/favorite/${id}`);
    }
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});
