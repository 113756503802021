import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { AdditionalInfoPyaload } from "../../../../car/CarTypes";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import auto from "../../../asset/automatic.jpg";
import manual from "../../../asset/mechanic.jpg";
import { Icons } from "../../../search.types";
import { MultiplePickAdditionalInfoCard } from "../../../shared/advancedSearch/MultiplePickAdditionalInfoCard";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { MenuID } from "../../../shared/constant/menuID";

const GearPickWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;

export const GearIcons: Icons = {
  "automatic-general": auto,
  mechanic: manual,
};

export const GearsPick = () => {
  const dispatch = useAppDispatch();
  const gears = useAppSelector((state) => state.car.gear);
  const selectedGear = useAppSelector((state) => state.filters.selectedData.gear);
  const handleClick = (value: AdditionalInfoPyaload) => {
    dispatch(FiltersActions.setGearBulk(value));
  };

  const handleGear = (value?: AdditionalInfoPyaload) => {
    if (value) {
      dispatch(FiltersActions.setGear(value.value));
    }
  };

  return (
    <SearchComponentWrapper title="gear" count={selectedGear?.length} id={MenuID.GEAR}>
      <GearPickWrapperStyled>
        {gears?.map((gear) => (
          <MultiplePickAdditionalInfoCard<AdditionalInfoPyaload>
            key={gear._id}
            value={gear}
            img={GearIcons[gear.value]}
            onClick={handleClick}
            onOption={handleGear}
            type="gear"
          />
        ))}
      </GearPickWrapperStyled>
    </SearchComponentWrapper>
  );
};

// return (
//   <WrapperStyled>
//     gear
//     <GearPickWrapperStyled>
//       {gears?.map((gear) => (
//         <div key={gear._id}>
//           <TitleStyled>{gear.label}</TitleStyled>
//           <div>
//             <ImageWrapperStyled>
//               <ImageStyled
//                 onClick={handleBulkSelect(gear.options)}
//                 src={gearImages[gear.value]}
//                 $isSelected={gear.options?.every((option) =>
//                   selectedGear?.includes(option.value),
//                 )}
//               />
//             </ImageWrapperStyled>
//             <OptionsWrapperStyled>
//               {gear.options?.map((option) => (
//                 <OptionStyled
//                   key={option._id}
//                   $isSelected={selectedGear?.includes(option.value)}
//                   onClick={handleClick(option.value)}
//                 >
//                   {option.label}
//                 </OptionStyled>
//               ))}
//             </OptionsWrapperStyled>
//           </div>
//         </div>
//       ))}
//     </GearPickWrapperStyled>
//     <Button onClick={handleNext}>Next</Button>
//   </WrapperStyled>
// );
