import styled from "styled-components";

import { CarInfo } from "../../../pages/car/preview/PreviewCar";

const TitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const PriceStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0%;
`;

const StyledCity = styled.div`
  color: rgb(61, 61, 61);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0%;
`;

const ListNameStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0%;
  margin-bottom: 15px;
`;

const ListStyled = styled.div`
  margin-bottom: 30px;
`;

const ListRowStyled = styled.div`
  display: flex;
`;
const ListKeyStyled = styled.div`
  background: rgb(217, 217, 217);
  color: rgb(51, 51, 51);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0%;
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 15px;
`;
const ListValueStyled = styled.div`
  background: rgb(240, 240, 240);
  color: rgb(51, 51, 51);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0%;
  height: 40px;
  width: 290px;
  display: flex;
  align-items: center;
  padding-left: 15px;
`;

interface CarOptionsListProps {
  car: CarInfo;
}

export const CarOptionsList: React.FC<CarOptionsListProps> = ({ car }) => {
  return (
    <div>
      <TitleStyled>
        <PriceStyled>$ {car.price}</PriceStyled>
        <StyledCity>{car.city}</StyledCity>
      </TitleStyled>
      <ListNameStyled>Характеристики</ListNameStyled>
      <ListStyled>
        <ListRowStyled>
          <ListKeyStyled>Рік виробництва</ListKeyStyled>
          <ListValueStyled>{car.year}</ListValueStyled>
        </ListRowStyled>
        <ListRowStyled>
          <ListKeyStyled>Пробіг, тис. км</ListKeyStyled>
          <ListValueStyled>{car.mileage}</ListValueStyled>
        </ListRowStyled>
        <ListRowStyled>
          <ListKeyStyled>Марка</ListKeyStyled>
          <ListValueStyled>{car.brand}</ListValueStyled>
        </ListRowStyled>
        <ListRowStyled>
          <ListKeyStyled>Модель</ListKeyStyled>
          <ListValueStyled>
            {car.model} {car.generation} {car.modification}
          </ListValueStyled>
        </ListRowStyled>
        <ListRowStyled>
          <ListKeyStyled>Потужність</ListKeyStyled>
          <ListValueStyled>{car.power}</ListValueStyled>
        </ListRowStyled>
        <ListRowStyled>
          <ListKeyStyled>Тип кузова</ListKeyStyled>
          <ListValueStyled>{car.bodyType}</ListValueStyled>
        </ListRowStyled>
        <ListRowStyled>
          <ListKeyStyled>Паливо</ListKeyStyled>
          <ListValueStyled>{car.fuel}</ListValueStyled>
        </ListRowStyled>
        <ListRowStyled>
          <ListKeyStyled>Тип приводу</ListKeyStyled>
          <ListValueStyled>{car.driveType}</ListValueStyled>
        </ListRowStyled>
        <ListRowStyled>
          <ListKeyStyled>Колір</ListKeyStyled>
          <ListValueStyled>{car.color}</ListValueStyled>
        </ListRowStyled>
        <ListRowStyled>
          <ListKeyStyled>VIN</ListKeyStyled>
          <ListValueStyled>{car.VIN}</ListValueStyled>
        </ListRowStyled>
      </ListStyled>
    </div>
  );
};
