import { useTranslation } from "react-i18next";

import { FormItemStyled, LabelStyled } from "./form";
import { CheckBoxAdditionalInfoAddCar } from "./simpleSelect";
import { MULTIMEDIA } from "../../../../constants/AdditionalInfo";
import { AdditionalIonfoKeys } from "../../constant";

export const MultimediaAddCar: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <FormItemStyled
        label={<LabelStyled>{t("addCar.multimedia.label")}</LabelStyled>}
        name={MULTIMEDIA}
        rules={[{ required: false, message: t("addCar.multimedia.error") }]}
      >
        <CheckBoxAdditionalInfoAddCar name={AdditionalIonfoKeys.multimedia} />
      </FormItemStyled>
    </div>
  );
};
