import { useTranslation } from "react-i18next";

import { FormItemStyled, LabelStyled } from "./form";
import { CheckBoxAdditionalInfoAddCar, SimpleSelectAdditionalInfoAddCar } from "./simpleSelect";
import { HEADLIGHTS, OPTIC } from "../../../../constants/AdditionalInfo";
import { AdditionalIonfoKeys } from "../../constant";

export const OpticsAddCar: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <SimpleSelectAdditionalInfoAddCar
        optionsKey={HEADLIGHTS}
        type={AdditionalIonfoKeys.headlight}
        required={false}
      />

      <FormItemStyled
        label={<LabelStyled>{t("addCar.optic.label")}</LabelStyled>}
        name={OPTIC}
        rules={[{ required: false, message: t("addCar.optic.error") }]}
      >
        <CheckBoxAdditionalInfoAddCar name={AdditionalIonfoKeys.optic} />
      </FormItemStyled>
    </div>
  );
};
