import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { MileagePick } from "./mileage";
import { PricePick } from "./price";
import { YearPick } from "./year";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { MenuID } from "../../../shared/constant/menuID";

const RowStyled = styled.div`
  margin-bottom: 16px;
  display: flex;
  gap: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const RowItemStyled = styled.div`
  width: 50%;
`;

export const PriceYearMielage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SearchComponentWrapper
      title=""
      titleTranslated={`${t("price")}, ${t("year")}, ${t("mileage")}`}
      id={MenuID.PRICE}
    >
      <div>
        <RowStyled>
          <PricePick />
        </RowStyled>

        <RowStyled>
          <RowItemStyled>
            <YearPick />
          </RowItemStyled>
          <RowItemStyled>
            <MileagePick />
          </RowItemStyled>
        </RowStyled>
      </div>
    </SearchComponentWrapper>
  );
};
