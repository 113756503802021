import { useMemo } from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { SelectedGeneration } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { MultiplePickCard } from "../../../shared/advancedSearch/MultiplePickCard";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { MenuID } from "../../../shared/constant/menuID";

const ModificationPickWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;

export const ModificationPick = () => {
  const dispatch = useAppDispatch();
  const modificationsList = useAppSelector((state) => state.filters.selectedData.generations);

  const handleModification = (parentId: string, modification: { _id: string }) => {
    dispatch(FiltersActions.setModification({ modification, parentId }));
  };

  const selectedModifications = useMemo(() => {
    let count = 0;
    modificationsList?.forEach((generation) => {
      generation.options.forEach((mod) => {
        if (mod.isSelected) {
          count++;
        }
      });
    });
    return count;
  }, [modificationsList]);

  if (!modificationsList?.length) return null;

  const handleClick = (value: SelectedGeneration) => {
    dispatch(FiltersActions.setMultyModification(value));
  };

  return (
    <SearchComponentWrapper
      title="modification"
      count={selectedModifications}
      id={MenuID.MODIFICATION}
    >
      <ModificationPickWrapperStyled>
        {modificationsList?.map((generation) => (
          <MultiplePickCard<SelectedGeneration>
            key={generation._id}
            value={generation}
            onClick={handleClick}
            onOption={handleModification}
          />
        ))}
      </ModificationPickWrapperStyled>
    </SearchComponentWrapper>
  );
};
