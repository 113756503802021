import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { Generations, SelectedGeneration } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickCard } from "../../../shared/advancedSearch/SinglePickCard";
import { MenuID } from "../../../shared/constant/menuID";

const GenerationPickWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const GenerationsPick = () => {
  const dispatch = useAppDispatch();
  const generations = useAppSelector((state) => state.filters.generations);
  const selectedGenerations = useAppSelector((state) => state.filters.selectedData.generations);
  const selectedModel = useAppSelector((state) => state.filters.selectedData.model);

  const handleClick = (generation: SelectedGeneration) => {
    dispatch(FiltersActions.setGenerationsMultyCard(generation));
  };

  if (!selectedModel || !generations.length) return null;
  return (
    <SearchComponentWrapper
      title="generation"
      count={selectedGenerations?.length}
      id={MenuID.GENERATION}
    >
      <GenerationPickWrapperStyled>
        {generations.map((generation) => (
          <SinglePickCard<Generations>
            key={generation._id}
            onClick={handleClick}
            value={generation}
            isSelected={selectedGenerations?.some((gen) => gen._id === generation._id)}
          />
        ))}
      </GenerationPickWrapperStyled>
    </SearchComponentWrapper>
  );
};
