import { Select } from "antd";
import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { useAppSelector } from "../../../helpers/store.hook";
import { AppDispatch } from "../../../store";
import { Brands } from "../filters.types";
import { FiltersActions } from "../redux/filters.slice";
import { getModelsThunk } from "../redux/filters.thunks";

export interface SelectProps {
  onSelect?: (value: string, options: Brands) => void;
  onChange?: (value: string) => void;
  dropdownRender?: (originNode: ReactNode) => ReactNode;
}

export const SelectStyled = styled(Select)<SelectProps>`
  border-radius: 5px;
  width: 215px !important;
`;

export const BrandSearch = () => {
  const dispatch = useDispatch<AppDispatch>();
  const brands = useAppSelector((state) => state.filters.brands);
  const selectedBrand = useAppSelector((state) => state.filters.selectedData?.brand);

  const handleBrand = (value: string, options: Brands) => {
    if (selectedBrand?.name !== value) {
      dispatch(
        getModelsThunk({
          ...options,
          name: value,
        }),
      );
    }
  };

  const handleClear = () => {
    dispatch(FiltersActions.clearBrand());
  };

  return (
    <SelectStyled
      options={brands}
      size="large"
      value={selectedBrand?.name}
      onSelect={handleBrand}
      onClear={handleClear}
      showSearch
      allowClear
      placeholder="Brand"
    />
  );
};
//TODO create some general solution
