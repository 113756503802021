import { LoadingOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useSearchCount } from "./useSearchCount";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { SearchRoutesPath } from "../../../../routes";
import { FiltersActions } from "../../../filters/redux/filters.slice";
import { MenuID } from "../../shared/constant/menuID";
import { createSeach } from "../../utils/createSearch";

const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 175%;
  letter-spacing: 0%;
  text-align: left;
  margin: 16px;
`;

const WrapperStyled = styled.div`
  position: fixed;
  width: 265px;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
`;

const TitleWrapperStyled = styled.div`
  border-bottom: 1px solid rgb(219, 219, 219);
`;

const ContainerStyled = styled.div`
  margin-right: 32px;
  width: 265px;
`;

const ItemsWrapperStyled = styled.div`
  padding: 16px;
`;

const ItemStyled = styled.a<{ $isActive: boolean }>`
  display: flex;
  text-decoration: none;
  margin: 8px;
  cursor: pointer;
  color: ${(props) => (props.$isActive ? "var(--main-color)" : "rgb(34, 34, 34)")};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: capitalize;

  &:hover {
    color: var(--main-color);
  }
`;

const ActionWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const ActionButtonStyled = styled(Button)<{ $type?: "search" | "clear-all" }>`
  margin: 8px;
  border-radius: 8px;

  background: ${(props) => (props.$type === "search" ? "var(--main-color)" : "rgb(255, 255, 255)")};
  color: ${(props) => (props.$type === "search" ? "rgb(255, 255, 255)" : "rgb(34, 34, 34)")};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 1px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  &:hover {
    border-color: var(--main-color) !important;
    & span {
      color: var(--main-color);
    }
  }
`;

const CountWrapperStyled = styled.div`
  margin-left: 5px;
`;

const item: MenuID[] = [
  MenuID.USE_INFO,
  MenuID.BRAND,
  // "model",
  MenuID.PRICE,
  MenuID.YEAR,
  MenuID.MILEAGE,
  MenuID.REGION,
  MenuID.DRIVE_TYPE,
  MenuID.FUEL,
  MenuID.POWER,
  MenuID.BODY_TYPE,
  MenuID.COLOR,
  MenuID.GEAR,
  MenuID.LIGHT,
  MenuID.SALON_MATERIAL,
  MenuID.SALON_OPTION,
  MenuID.SECURITY,
  MenuID.AIRBAG,
  MenuID.MULTIMEDIA,
  MenuID.OTHER,
];

export const AdvancedSearchMenu = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useSearchCount();
  const currentActive = useAppSelector((state) => state.search.currentPosition);
  const carCount = useAppSelector((state) => state.search.carCount);
  const carCountLoading = useAppSelector((state) => state.search.carCountLoading);

  const navigate = useNavigate();
  const selectedData = useAppSelector((state) => state.filters.selectedData);

  const handleSearch = () => {
    const searchObject = createSeach(selectedData);
    navigate({
      pathname: SearchRoutesPath.search,
      search: `${createSearchParams(searchObject)}`,
    });
  };

  const handleClearAll = () => {
    dispatch(FiltersActions.clearSelectedData());
  };

  return (
    <ContainerStyled>
      <WrapperStyled>
        <TitleWrapperStyled>
          <TitleStyled>{t("menu")}</TitleStyled>
        </TitleWrapperStyled>

        <ItemsWrapperStyled>
          {item.map((item) => (
            <ItemStyled key={item} $isActive={currentActive === item} href={`#${item}`}>
              {t(item)}
            </ItemStyled>
          ))}
        </ItemsWrapperStyled>
        <ActionWrapperStyled>
          <ActionButtonStyled $type="search" onClick={handleSearch}>
            {t("search")}
            <CountWrapperStyled>
              {carCountLoading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : carCount ? (
                `(${carCount})`
              ) : (
                ""
              )}
            </CountWrapperStyled>
          </ActionButtonStyled>
          <ActionButtonStyled onClick={handleClearAll}>{t("clear-all")}</ActionButtonStyled>
        </ActionWrapperStyled>
      </WrapperStyled>
    </ContainerStyled>
  );
};
