export interface JWTTokens {
  access_token: string;
  refresh_token: string;
}

export enum LocalStorageKeys {
  JWT_ACCESS = "JWT_ACCESS",
  JWT_REFRESH = "JWT_REFRESH",
}

export interface loginDTO extends UserDTO {
  tokens: JWTTokens;
}

export interface UserDTO {
  phone: string;
  name: string;
  _id: string;
  favorite: string[];
  isPhoneVerified: boolean;
}
