import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../../../helpers/interseptor";
import { BrandDTO } from "../search.dto";

interface ValidationErrors {
  errorMessage: string;
}

export const getBrandsThunk = createAsyncThunk<
  BrandDTO[],
  undefined,
  {
    rejectValue: ValidationErrors;
  }
>("getBrandsThunk", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.get<BrandDTO[]>("search/brands");

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const getCarsCountThunk = createAsyncThunk<
  { count: number },
  string,
  { rejectValue: ValidationErrors }
>("getCarsCoyuntThunk", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.get<{ count: number }>(`search/cars-count?${args}`);

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});
