import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./components/auth/redux/auth.slice";
import CarReducer from "./components/car/redux/car.slice";
import filtersReducer from "./components/filters/redux/filters.slice";
import searchSlice from "./components/search/redux/search.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    filters: filtersReducer,
    car: CarReducer,
    search: searchSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
