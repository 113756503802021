import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppSelector } from "../../../../helpers/store.hook";
import { MenuID } from "../../constant";

const WrapperStyled = styled.div`
  position: fixed;
  border-radius: 8px;

  background: rgb(240, 240, 240);
  padding: 16px;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const ContainerStyled = styled.div`
  height: 100%;
  width: 250px;
  min-width: 250px;
`;

const MenuItem = styled.a<{ $active?: boolean }>`
  padding: 16px;
  cursor: pointer;
  border-radius: 8px;
  text-decoration: none;
  color: rgb(46, 46, 46);

  ${(props) =>
    props.$active &&
    `background: rgb(116, 99, 232);
    color: rgb(255, 255, 255);`}

  &:hover {
    background: rgb(116, 99, 232);
    color: rgb(255, 255, 255);
  }
`;

export const MenuAddCar: React.FC = () => {
  const { t } = useTranslation();
  const currentVisibleMenu = useAppSelector((state) => state.car.currentVisibleMenu);
  return (
    <ContainerStyled>
      <WrapperStyled>
        <MenuItem $active={currentVisibleMenu === MenuID.GENERAL} href={`#${MenuID.GENERAL}`}>
          {t("addCar.menu.generalInfo")}
        </MenuItem>
        <MenuItem $active={currentVisibleMenu === MenuID.PHOTO} href={`#${MenuID.PHOTO}`}>
          {t("addCar.menu.carPhoto")}
        </MenuItem>
        <MenuItem $active={currentVisibleMenu === MenuID.ADDITIONAL} href={`#${MenuID.ADDITIONAL}`}>
          {t("addCar.menu.additionalInfo")}
        </MenuItem>
      </WrapperStyled>
    </ContainerStyled>
  );
};
