import { Area } from "react-easy-crop";

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    // image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  type: string,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
): Promise<string | null> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(0);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      if (file) {
        resolve(URL.createObjectURL(file));
      }
    }, type);
  });
};

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export function cropListOfimg(url: string, aspectRatio: number) {
  // we return a Promise that gets resolved with our canvas element

  // this image will hold our source image data
  const inputImage = new Image();

  // we want to wait for our image to load
  inputImage.onload = () => {
    // let's store the width and height of our image
    const inputWidth = inputImage.naturalWidth;
    const inputHeight = inputImage.naturalHeight;

    // get the aspect ratio of the input image
    const inputImageAspectRatio = inputWidth / inputHeight;

    // if it's bigger than our target aspect ratio
    let outputWidth = inputWidth;
    let outputHeight = inputHeight;
    if (inputImageAspectRatio > aspectRatio) {
      outputWidth = inputHeight * aspectRatio;
    } else if (inputImageAspectRatio < aspectRatio) {
      outputHeight = inputWidth / aspectRatio;
    }

    // calculate the position to draw the image at
    const outputX = (outputWidth - inputWidth) * 0.5;
    const outputY = (outputHeight - inputHeight) * 0.5;

    // create a canvas that will present the output image
    const outputImage = document.createElement("canvas");

    // set it to the same size as the image
    outputImage.width = outputWidth;
    outputImage.height = outputHeight;

    // draw our image at position 0, 0 on the canvas
    const ctx = outputImage.getContext("2d");
    ctx?.drawImage(inputImage, outputX, outputY);

    const croppedCanvas = document.createElement("canvas");

    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return null;
    }

    croppedCtx.drawImage(
      outputImage,
      outputX,
      outputY,
      outputWidth,
      outputHeight,
      0,
      0,
      outputWidth,
      outputHeight,
    );
    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob((file) => {
        if (file) {
          resolve(URL.createObjectURL(file));
        }
      }, "image/jpeg");
    });
  };

  // start loading our image
  inputImage.src = url;
}

export async function createCroppedImage(url: string): Promise<string | null> {
  // const aspectRatio = 4 / 3;
  const croppedCanvas = document.createElement("canvas");
  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  const inputImage = new Image();
  inputImage.src = url;

  await new Promise<void>((resolve, reject) => {
    inputImage.onload = () => {
      const inputWidth = inputImage.naturalWidth;
      const inputHeight = inputImage.naturalHeight;
      console.log("inputWidth", inputWidth);
      console.log("inputHeight", inputHeight);
      // const inputImageAspectRatio = inputWidth / inputHeight;

      // const outputWidth = inputWidth;
      // const outputHeight = inputHeight;
      // let MoveX = inputWidth;
      // let MoveY = inputHeight;
      // let stepsX = 0;
      // let stepsY = 0;
      // let isBreak = false;

      // if (inputImageAspectRatio > aspectRatio) {
      //   do {
      //     MoveY += 0.001;
      //     stepsY += 0.001;

      //     if (MoveY - inputHeight > 5000) {
      //       isBreak = true;
      //       break;
      //     }
      //   } while (Math.floor((inputWidth / MoveY) * 100000) !== Math.floor(aspectRatio * 100000));
      // } else if (inputImageAspectRatio < aspectRatio) {
      //   do {
      //     MoveX += 0.001;
      //     stepsX += 0.001;
      //     if (MoveX - inputWidth > 5000) {
      //       isBreak = true;
      //       break;
      //     }
      //   } while (Math.floor((MoveX / inputHeight) * 100000) !== Math.floor(aspectRatio * 100000));
      //   inputImage.width = MoveX;
      // }
      // if (isBreak) {
      //   reject(new Error("Failed to create cropped image blob."));
      // }
      // croppedCanvas.width = MoveX;
      // croppedCanvas.height = MoveY;
      // croppedCtx.fillStyle = "#2E2E2E";
      // croppedCtx.fillRect(0, 0, MoveX, MoveY);

      croppedCtx.drawImage(
        inputImage,
        0,
        0,
        inputWidth,
        inputHeight,
        // 0, // stepsX / 2,
        // 0, // stepsY / 2,
        // inputWidth,
        // inputHeight,
      );

      resolve();
    };
  });

  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      // alert(file);
      if (file) {
        resolve(URL.createObjectURL(file)); //TODO unlink files by url revorkUrls smth like that
      } else {
        alert("Failed to create cropped image blob. 123");
        reject(new Error("Failed to create cropped image blob."));
      }
    }, "image/jpeg");
  });
}

export function base64ToBlob(base64String: string, contentType = "application/octet-stream") {
  // Decode Base64 string to binary data
  const base64Data = base64String.replace(/^data:(.*?);base64,/, "");
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the binary data
  return new Blob([byteArray], { type: contentType });
}
