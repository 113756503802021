import { Checkbox } from "antd";
import { useMemo } from "react";
import styled from "styled-components";

import { SinglePickCard } from "./SinglePickCard";
import { SelectedGeneration } from "../../../filters/filters.types";

const ContainerStyled = styled.div<{ $isSelected?: boolean }>`
  border: ${(props) =>
    props.$isSelected ? "1px solid var(--main-color)" : "1px solid rgb(219, 219, 219)"};
  border-radius: 8px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  //   width: 100%;
  max-height: 150px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 16px;
  flex: 1 0 auto;
`;

const OptionsWparperStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 30px;
  height: 120px;
`;

const OptionsItemStyled = styled.div`
  min-width: 100px;
`;

const CheckboxTextStyled = styled.div`
  font-family: "Roboto", sans-serif;
`;

interface MultiplePickCardProps<T> {
  value: T;
  onClick: (value: T) => void;
  onOption: (parent: string, value: { _id: string }) => void;
  isSelected?: boolean;
  size?: "small" | "large";
}

export const MultiplePickCard = <T extends SelectedGeneration>({
  onClick,
  isSelected,
  value,
  size = "large",
  onOption,
}: MultiplePickCardProps<T>) => {
  const handleClick = () => {
    onClick(value);
  };

  const handlechange = (parentId: string, option: { _id: string }) => () => {
    onOption(parentId, option);
  };

  const isAllSelected = useMemo(
    () => value?.options?.every((option) => option.isSelected),
    [value],
  );

  return (
    <ContainerStyled $isSelected={isSelected}>
      <SinglePickCard<T>
        value={value}
        size={size}
        border={false}
        onClick={handleClick}
        isSelected={isAllSelected}
      />
      <OptionsWparperStyled>
        {value?.options?.map((option) => (
          <OptionsItemStyled key={option.name}>
            <Checkbox onChange={handlechange(value._id, option)} checked={option?.isSelected}>
              <CheckboxTextStyled>{option.name}</CheckboxTextStyled>
            </Checkbox>
          </OptionsItemStyled>
        ))}
      </OptionsWparperStyled>
    </ContainerStyled>
  );
};
