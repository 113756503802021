import React from "react";
import { useTranslation } from "react-i18next";

import { FormItemStyled, LabelStyled } from "./form";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { SelectedDataNumberKeys } from "../../CarTypes";
import { CarActions } from "../../redux/car.slice";
import { InputNumberStyled } from "../../shared/Input";

export const SimpleInputNumberAddCar: React.FC<{
  type: SelectedDataNumberKeys;
  min: number;
  max: number;
  required?: boolean;
}> = React.memo(({ type, min, max, required = true }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const value = useAppSelector((state) => state.car.selectedData[type]);
  const handleChange = (value: number | null) => {
    if (value) dispatch(CarActions.setNumberValue({ key: type, value: value }));
  };

  return (
    <FormItemStyled
      label={<LabelStyled>{t(`addCar.${type}.label`)}</LabelStyled>}
      name={type}
      rules={[{ required, message: t(`addCar.${type}.error`) }]}
    >
      <InputNumberStyled
        controls={false}
        value={value}
        onChange={handleChange}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
        min={min}
        max={max}
      />
    </FormItemStyled>
  );
});
