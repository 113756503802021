import styled from "styled-components";

import { SelectProps, SelectStyled } from "./brands";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { CarModel } from "../filters.types";
import { FiltersActions } from "../redux/filters.slice";
import { getGenerationsThunk } from "../redux/filters.thunks";

const SelectModelStyled = styled(SelectStyled)<
  Omit<SelectProps, "onSelect"> & {
    onSelect?: (value: string, options: CarModel & { isParent: boolean }) => void;
  }
>``;

export const ModelSearch = () => {
  const dispatch = useAppDispatch();
  const models = useAppSelector((state) => state.filters.models);
  const selectedModel = useAppSelector((state) => state.filters.selectedData?.model);

  const handleModel = (value: string, options: CarModel & { isParent: boolean }) => {
    if (options.isParent) {
      dispatch(FiltersActions.setModel(options));
      return;
    }
    dispatch(getGenerationsThunk(options));
  };

  const handleClear = () => {
    dispatch(FiltersActions.clearModel());
  };

  return (
    <SelectModelStyled
      options={models}
      value={selectedModel?.searchName}
      onSelect={handleModel}
      onClear={handleClear}
      placeholder="Model"
      showSearch
      allowClear
      size="large"
    />
  );
};
