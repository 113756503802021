export enum AdditionalInfoDTOKeys {
  SALON_MATERIAL = "salonMaterial",
  GEARS = "gear",
  SALON = "salon",
  OPTICS = "optic",
  AIRBAGS = "airbag",
  DRIVE_TYPES = "driveType",
  COLORS = "color",
  HEADLIGHTS = "headlight",
  SECURITY = "security",
  MULTIMEDIA = "multimedia",
  OTHER = "other",
  BODY_TYPES = "bodyType",
  FUEL = "fuel",
  SALON_COLOR = "salonColor",
}

export enum AdditionalInfoSearchSelectedDataKeys {
  SALON = "salon",
  SALON_MATERIAL = "salonMaterial",
  BODY_TYPE = "bodyType",
  COLOR = "color",
  GEAR = "gear",
  LIGHT = "light",
  OPTIC = "optic",
  HEADLIGHTS = "headlight",
  SECURITY = "security",
  AIRBAG = "airbag",
  MULTIMEDIA = "multimedia",
  FUEL = "fuel",
  OTHER = "other",
  DRIVE_TYPE = "driveType",
}

export enum UseInfo {
  NEW = "new",
  USED = "used",
}

export const USE_INFO = "useInfo";
export const USE_INFO_NEW = "new";
export const USE_INFO_USED = "used";
export const USE_INFO_ACCIDENT = "afterAccident";
export const VIN_HIDE = "vinHide";
export const VIN = "vin";
export const CITY = "city";
export const REGION = "region";
export const NUMBER = "number";
export const PHONE = "phone";
export const BRAND = "brand";
export const MODEL = "model";
export const GENERATION = "generation";
export const MODIFICATION = "modification";
export const GEAR = "gear";
export const FUEL = "fuel";
export const BODY_TYPE = "bodyType";
export const DRIVE_TYPE = "driveType";
export const COLOR = "color";
export const MILEAGE = "mileage";
export const YEAR = "year";
export const POWER = "power";
export const PRICE = "price";
export const PHOTO = "photo";
export const DESCRIPTION = "description";
export const OPTIC = "optic";
export const OTHER = "other";
export const SALON = "salon";
export const SALON_MATERIAL = "salonMaterial";
export const SECURITY = "security";
export const AIRBAG = "airbag";
export const MULTIMEDIA = "multimedia";
export const HEADLIGHTS = "headlight";
export const ENGINE = "engine";
