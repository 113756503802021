import { useEffect } from "react";
import styled from "styled-components";

import { CarGetAdditionalInfoThunk } from "../../components/car/redux/car.thunks";
import { AirBagPick } from "../../components/search/components/filters/pick/airbags";
import { BodyTypesPick } from "../../components/search/components/filters/pick/bodyTypes";
import { BrandsPick } from "../../components/search/components/filters/pick/brands";
import { ColorsPick } from "../../components/search/components/filters/pick/colors";
import { DriveTypesPick } from "../../components/search/components/filters/pick/driveTypes";
import { FuelPick } from "../../components/search/components/filters/pick/fuels";
import { GearsPick } from "../../components/search/components/filters/pick/gears";
import { GenerationsPick } from "../../components/search/components/filters/pick/generations";
import { LightsPick } from "../../components/search/components/filters/pick/lights";
import { ModelsPick } from "../../components/search/components/filters/pick/models";
import { ModificationPick } from "../../components/search/components/filters/pick/modifications";
import { MultimediaPick } from "../../components/search/components/filters/pick/multimedia";
import { OtherPick } from "../../components/search/components/filters/pick/other";
import { PowerPick } from "../../components/search/components/filters/pick/power";
import { PriceYearMielage } from "../../components/search/components/filters/pick/priceYearMileage";
import { RegionPick } from "../../components/search/components/filters/pick/region";
import { SalonOptionsPick } from "../../components/search/components/filters/pick/salonOptions";
import { SalonsPick } from "../../components/search/components/filters/pick/salons";
import { SecurityPick } from "../../components/search/components/filters/pick/security";
import { UseInfoPick } from "../../components/search/components/filters/pick/useInfo";
import { AdvancedSearchMenu } from "../../components/search/components/menu/menu";
import { useAppDispatch } from "../../helpers/store.hook";

const SearchPickWrapperStyled = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const ContainerStyled = styled.div`
  display: flex;
  justify-content: center;
`;

export const AdvancetSearch: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(CarGetAdditionalInfoThunk());
  }, [dispatch]);
  return (
    <ContainerStyled>
      <AdvancedSearchMenu />
      <SearchPickWrapperStyled>
        <UseInfoPick />
        <BrandsPick />
        <ModelsPick />
        <GenerationsPick />
        <ModificationPick />
        <PriceYearMielage />
        <RegionPick />
        <DriveTypesPick />
        <FuelPick />
        <PowerPick />
        <BodyTypesPick />
        <ColorsPick />
        <GearsPick />

        <LightsPick />
        <SalonsPick />
        <SalonOptionsPick />
        <SecurityPick />
        <AirBagPick />
        <MultimediaPick />
        <OtherPick />
      </SearchPickWrapperStyled>
    </ContainerStyled>
  );
};
