import { useTranslation } from "react-i18next";

import { FormItemStyled, LabelStyled } from "./form";
import { CheckBoxAdditionalInfoAddCar } from "./simpleSelect";
import { OTHER } from "../../../../constants/AdditionalInfo";
import { AdditionalIonfoKeys } from "../../constant";

export const OtherAddCar: React.FC = () => {
  const { t } = useTranslation();
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.other.label")}</LabelStyled>}
      name={OTHER}
      rules={[{ required: false, message: t("addCar.other.error") }]}
    >
      <CheckBoxAdditionalInfoAddCar name={AdditionalIonfoKeys.other} />
    </FormItemStyled>
  );
};
