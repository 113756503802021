import { useTranslation } from "react-i18next";

import { FormItemStyled, LabelStyled } from "./form";
import { CheckBoxAdditionalInfoAddCar } from "./simpleSelect";
import { SECURITY } from "../../../../constants/AdditionalInfo";
import { AdditionalIonfoKeys } from "../../constant";

export const SecurityAddCar: React.FC = () => {
  const { t } = useTranslation();
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.security.label")}</LabelStyled>}
      name={SECURITY}
      rules={[{ required: false, message: t("addCar.security.error") }]}
    >
      <CheckBoxAdditionalInfoAddCar name={AdditionalIonfoKeys.security} />
    </FormItemStyled>
  );
};
