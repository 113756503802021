import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../../../../constants/AdditionalInfo";
import { useAppSelector } from "../../../../../helpers/store.hook";
import alcantara from "../../../asset/interior-alcantara.jpg";
import artificialLeather from "../../../asset/interior-artificial-leather.jpg";
import combined from "../../../asset/interior-combined.jpg";
import fabric from "../../../asset/interior-fabric.jpg";
import leather from "../../../asset/interior-leather.jpg";
import velor from "../../../asset/interior-velor.jpg";
import { Icons } from "../../../search.types";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickAdditionalInfoCard } from "../../../shared/advancedSearch/SinglePickAdditionalInfo";
import { MenuID } from "../../../shared/constant/menuID";

export const SalonMaterialsIcons: Icons = {
  leather,
  fabric,
  "artificial-leather": artificialLeather,
  velor,
  combined,
  alcantara,
};

export const SalonsPick = () => {
  const selectedSalonMaterials = useAppSelector(
    (state) => state.filters.selectedData.salonMaterial,
  );

  return (
    <SearchComponentWrapper
      title="salon-material"
      count={selectedSalonMaterials?.length}
      id={MenuID.SALON_MATERIAL}
    >
      <SinglePickAdditionalInfoCard
        type={AdditionalInfoDTOKeys.SALON_MATERIAL}
        selectedType={AdditionalInfoSearchSelectedDataKeys.SALON_MATERIAL}
        images={SalonMaterialsIcons}
      />
    </SearchComponentWrapper>
  );
};
