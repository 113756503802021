import { Button, Input, Popconfirm } from "antd";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { User } from "./adminUserList";
import axios, { baseURLApi } from "../../../helpers/interseptor";
import { AdminRoutesPath, CarRoutesPath } from "../../../routes";
import { AdminContext } from "../../../routes/admin/adminRoutes";

export const AdminUser: React.FC = () => {
  const { adminTocken } = useContext(AdminContext);
  const navigate = useNavigate();
  const params = useParams();
  const [user, setUser] = useState<User | null>(null);
  const [maxCarCount, setMaxCarCount] = useState(0);
  useEffect(() => {
    axios
      .get<User>(`${baseURLApi}/admin/user/${params.id}`, {
        headers: {
          Authorization: `Bearer ${adminTocken}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        setUser(data);
        setMaxCarCount(data.maxCarCount);
      });
  }, [adminTocken, params.id]);

  const handleChangeID = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(e.target.value as string)) {
      setMaxCarCount(+e.target.value);
    }
  };

  const handleMaxCount = () => {
    axios
      .post<User>(
        `${baseURLApi}/admin/user/maxCarCount/${params.id}`,
        { maxCarCount },
        { headers: { Authorization: `Bearer ${adminTocken}` } },
      )
      .then((res) => res.data)
      .then((data) => {
        setUser(data);
        setMaxCarCount(data.maxCarCount);
      });
  };

  const DeleteConfirm = () => {
    axios
      .delete<User>(`${baseURLApi}/admin/user/${params.id}`, {
        headers: { Authorization: `Bearer ${adminTocken}` },
      })
      .then(() => {
        navigate(`${AdminRoutesPath.admin}${AdminRoutesPath.users}`);
      });
  };

  return (
    <>
      <div>
        <div>{user?.name}</div>
        <div>{user?.email}</div>
        <div>
          Favorite
          {user?.favorite.map((car) => (
            <div key={car}>
              <Link to={`${CarRoutesPath.car}/${car}`} target="blank">
                {car}
              </Link>
            </div>
          ))}
        </div>
        <div>
          User Cars:
          {user?.sellingCars.map((car) => (
            <div key={car}>
              <Link to={`${CarRoutesPath.car}/${car}`} target="blank">
                {car}
              </Link>
            </div>
          ))}
        </div>
        <div>{user?.maxCarCount}</div>
        <div>{new Date(user?.createdAt || "")?.toDateString()}</div>
        <div>
          Actions
          <div>
            Max Count:
            <Input value={maxCarCount} onChange={handleChangeID} />
            <Button onClick={handleMaxCount}>Set Max Count</Button>
          </div>
        </div>
        <div>
          Delete:
          <Popconfirm
            onConfirm={DeleteConfirm}
            title={"Are you sure you want to delete this user?"}
          >
            <Button>DeleteUser</Button>
          </Popconfirm>
        </div>
      </div>
    </>
  );
};
