import { Route, Routes } from "react-router-dom";

import { CarRoutesPath } from "..";
import { CarAdd } from "../../pages/car/add/CarAdd";
import { CarFavorite } from "../../pages/car/favorite/CarFavorite";
import { CarPage } from "../../pages/car/preview/PreviewCar";

export const CarRoutes = () => {
  return (
    <Routes>
      <Route path="/:id" element={<CarPage />} />
      <Route path={CarRoutesPath.add} element={<CarAdd />} />
      <Route path={CarRoutesPath.editID} element={<CarAdd edit />} />
      <Route path={CarRoutesPath.favourite} element={<CarFavorite />} />
    </Routes>
  );
};
