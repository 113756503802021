/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Carousel,
  ConfigProvider,
  Modal,
  Popconfirm,
  Spin,
  Slider,
  FormInstance,
} from "antd";
import { CarouselRef } from "antd/es/carousel";
import { useEffect, useRef, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { v4 } from "uuid";

import { FormItemStyled } from "./form";
import { PHOTO } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import greySvg from "../../../search/asset/grey.svg";
import { CarActions } from "../../redux/car.slice";
import { base64ToBlob } from "../../utils/cropeImage";

const HEIGHT = 160;
const WIDTH = 213;

const WrapperStyled = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const PhotoPlaceholderStyled = styled.div`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  border: 1px dashed rgb(219, 219, 219);
  border-radius: 8px;
  background: rgb(240, 240, 240);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;

const PlaceholderActionWrapperStyled = styled.div`
  border-radius: 100px;
  width: 44px;
  height: 44px;
  background: rgb(191, 191, 191);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

const InputFileStyled = styled.input`
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const UploadedImageItemStyled = styled.div`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  border-radius: 8px;
  border: 1px solid var(--main-color);
  overflow: hidden;
  display: flex;
  position: relative;
`;

const UploadedImageStyled = styled.img`
  width: 100%;
  height: auto;
  // border-radius: 8px;
  object-fit: cover;
  object-position: center;
`;

const UploadedImageActionButtonStyled = styled(Button)<{ $hbg?: string }>`
  border-radius: 4px;

  backdrop-filter: blur(100px);
  background: rgb(255, 255, 255);
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  border: none !important;

  &:hover {
    background: ${(prop) => prop.$hbg || "rgb(231, 70, 70)"} !important;
    color: rgb(255, 255, 255) !important;
  }
`;

const UploadedImageActionWrapperStyled = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 2;
  align-items: center;
  justify-content: center;
  gap: 4px;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
`;

const SpinStyled = styled(Spin)`
  position: absolute;
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const maxFiles = 20;

const acceptedImageTypes = [
  "image/jpeg",
  "image/jpg",
  "image/jpe",
  "image/jif",
  "image/png",
  "image/webp",
];

interface EditImageProps {
  image: string;
  _id: string;
  type: string;
}

export const PhotoAddCar: React.FC<{ form: FormInstance }> = ({ form }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isPhotoAdded = useRef<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCropOpen, setIsCropOpen] = useState(false);
  const [cropeImage, setCropeImage] = useState({ _id: "", image: "", type: "" });
  const startedIndex = useRef(0);

  const photos = useAppSelector((state) => state.car.selectedData.photo);

  const handleImage = async (file: File, _id: string) => {
    const imageDataUrl: string = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result as string), false);
      reader.readAsDataURL(file);
    });

    const cropedImage = await base64ToBlob(imageDataUrl, file.type);
    const blobUrl = URL.createObjectURL(cropedImage);
    if (cropedImage)
      dispatch(CarActions.updateSelectedPhoto({ _id, image: blobUrl, type: file.type }));
  };
  const handleAddImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = e;
    if (!files) return;

    const FilterdImages = Array.from(files)
      .filter((file) => acceptedImageTypes.includes(file.type))
      .map((file) => ({ file, _id: v4() }));
    const res22 = FilterdImages.map(({ file, _id }) => ({
      _id,
      image: greySvg,
      type: "svg",
      status: "loading",
    }));

    dispatch(CarActions.setSelectedPhotos(res22));
    FilterdImages.forEach(async ({ file, _id }) => {
      handleImage(file, _id);
    });

    e.target.value = "";
  };

  const handleDeleteImage = (id: string) => () => {
    dispatch(CarActions.deleteSelectedPhoto(id));
  };

  const handlePreviewImage = (id: string, index: number) => () => {
    setIsOpen(true);
    startedIndex.current = index;
  };

  const handleEdit = (image: string, _id: string, type: string) => () => {
    setIsCropOpen(true);
    setCropeImage({ _id, image, type });
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleCloseCrop = () => {
    setIsCropOpen(false);
  };

  const handleSubmitImage = ({ image, _id, type }: EditImageProps) => {
    dispatch(CarActions.updateSelectedPhoto({ image, _id, type }));
    handleCloseCrop();
  };

  useEffect(() => {
    if (photos.length) {
      form.setFieldsValue({ photo: photos.length });
      isPhotoAdded.current = true;
    } else if (isPhotoAdded.current && photos.length === 0) {
      form.setFields([
        {
          name: PHOTO,
          errors: [t("addCar.photo.error")],
        },
      ]);
    }
  }, [photos, form, t]);

  return (
    <FormItemStyled name={PHOTO} rules={[{ required: true, message: t("addCar.photo.error") }]}>
      <WrapperStyled>
        {photos.map((photo, index) => (
          <UploadedImageItemStyled key={photo._id}>
            <UploadedImageStyled src={photo.image} alt="car" />
            {photo.status !== "loading" ? (
              <UploadedImageActionWrapperStyled>
                <UploadedImageActionButtonStyled
                  onClick={handleEdit(photo.image, photo._id, photo.type)}
                  $hbg="var(--main-color)"
                >
                  {t("addCar.photo.edit")}
                </UploadedImageActionButtonStyled>
                <UploadedImageActionButtonStyled
                  onClick={handlePreviewImage(photo._id, index)}
                  $hbg="var(--main-color)"
                >
                  {t("addCar.photo.check")}
                </UploadedImageActionButtonStyled>
                <Popconfirm
                  title={t("addCar.photo.removeConfirm")}
                  description={t("addCar.photo.removeDescription")}
                  okText={t("addCar.photo.removeConfirmYes")}
                  cancelText={t("addCar.photo.removeConfirmNo")}
                  onConfirm={handleDeleteImage(photo._id)}
                >
                  <UploadedImageActionButtonStyled>
                    {t("addCar.photo.remove")}
                  </UploadedImageActionButtonStyled>
                </Popconfirm>
              </UploadedImageActionWrapperStyled>
            ) : (
              <SpinStyled indicator={<LoadingOutlined spin />} />
            )}
          </UploadedImageItemStyled>
        ))}
        <PhotoPlaceholderStyled>
          <PlaceholderActionWrapperStyled>
            <PlusOutlined />
          </PlaceholderActionWrapperStyled>
          <InputFileStyled
            type="file"
            id="avatarInput"
            accept="image/jpeg,
            image/jpg,
            image/jpe,
            image/jif,
            image/png,
            image/webp"
            disabled={photos.length >= maxFiles}
            onChange={handleAddImage}
            multiple
          />
        </PhotoPlaceholderStyled>
        {isCropOpen && (
          <CropAddCar
            isCropOpen={isCropOpen}
            cropeImage={cropeImage}
            handleClose={handleCloseCrop}
            onSubmit={handleSubmitImage}
          />
        )}
        {isOpen && (
          <CarouselAddCar
            isOpen={isOpen}
            handleClose={handleClose}
            startedIndex={startedIndex.current}
            handleEdit={handleEdit}
          />
        )}
      </WrapperStyled>
    </FormItemStyled>
  );
};

const modalStyles = {
  mask: {
    backdropFilter: "blur(10px)",
  },
  body: {
    height: "80vh",
    background: "#2E2E2E",
  },
};

const MainCarouselWrapper = styled.div`
  height: 70%;
  & > div {
    height: 100%;
  }
  & .slick-slide {
    & > div {
      height: 100%;
    }
  }

  & .slick-list {
    height: 100%;
  }
  & .slick-track {
    height: 100%;
  }
  & .carousel-container {
    height: 100%;
  }
`;

const ModalWrapperStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CauruselImageWrapperStyled = styled.div`
  position: relative;
  height: 100%;
  &:hover .actions {
    opacity: 1;
  }
  & img {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
`;

const ActionWrapperStyled = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 8px;
  opacity: 0;
`;

const ThumbnailCarouselStyled = styled.div`
  height: 20%;
  max-height: ${HEIGHT}px;
  & > div {
    height: 100%;
  }
  & .slick-slide {
    height: 100%;
    & > div {
      height: 100%;
    }
  }
  & .slick-slider {
    height: 100%;
  }

  & .slick-list {
    height: 100%;
  }
  & .slick-track {
    height: 100%;
  }
  & .carousel-container {
    height: 100%%;
  }
`;

const ThumbnailCarouselImageStyled = styled.div`
  width: ${WIDTH}px;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }
`;

const ArrorWrapperStyled = styled.div<{ $position: "left" | "right" }>`
  position: absolute;
  top: 50%;
  ${(prop) => (prop.$position === "left" ? "left: 0" : "right: 0")};
  cursor: pointer;
  transition: transform 0.3s;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  &:hover {
    transform: scale(1.2);
  }
`;

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <ArrorWrapperStyled $position="right" onClick={onClick}>
      <svg
        width="9.863342"
        height="17.726654"
        viewBox="0 0 9.86334 17.7267"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc>Created with Pixso.</desc>
        <defs />
        <path
          id="chevron_forward"
          d="M7.84 8.87L0 1.01L1.01 0L9.86 8.87L1.01 17.72L0 16.71L7.84 8.87Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </ArrorWrapperStyled>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <ArrorWrapperStyled $position="left" onClick={onClick}>
      <svg
        width="9.863342"
        height="17.726654"
        viewBox="0 0 9.86334 17.7267"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc>Created with Pixso.</desc>
        <defs />
        <path
          id="chevron_backward"
          d="M8.85 17.72L0 8.87L8.85 0L9.86 1.01L2.02 8.87L9.86 16.71L8.85 17.72Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </svg>
    </ArrorWrapperStyled>
  );
}

const CarouselAddCar: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
  startedIndex: number;
  handleEdit: (image: string, id: string, type: string) => () => void;
}> = ({ isOpen, handleClose, startedIndex, handleEdit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement>(null);
  const photos = useAppSelector((state) => state.car.selectedData.photo);
  const [slideToShow, setSlideToShow] = useState<number | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [nav1, setNav1] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [nav2, setNav2] = useState<any>(null);
  const sliderRef1 = useRef<CarouselRef>(null);
  const sliderRef2 = useRef<CarouselRef>(null);

  useEffect(() => {
    if (slideToShow) {
      setNav1(sliderRef1.current);
      setNav2(sliderRef2.current);
    }
  }, [slideToShow]);

  useEffect(() => {
    const width = modalRef.current?.clientWidth;
    if (width) {
      const slides = Math.floor(width / WIDTH) > 6 ? 6 : Math.floor(width / WIDTH);
      setSlideToShow(slides);
    }
  }, []);

  useEffect(() => {
    if (photos.length === 0) {
      handleClose();
    }
  }, [photos, handleClose]);

  const handleDeleteImage = (id: string) => () => {
    dispatch(CarActions.deleteSelectedPhoto(id));
    if (photos.length === 1) {
      handleClose();
    }
  };

  const handleEditImage = (image: string, id: string, type: string) => () => {
    handleEdit(image, id, type)();
  };

  return (
    <ConfigProvider
      modal={{
        styles: modalStyles,
      }}
    >
      <Modal
        open={isOpen}
        onCancel={handleClose}
        footer={null}
        width={"90%"}
        style={{ maxWidth: "1700px" }}
        centered
        className="AddCarModal"
      >
        <ModalWrapperStyled ref={modalRef}>
          {slideToShow === null ? (
            <Spin />
          ) : (
            <>
              <MainCarouselWrapper>
                <Carousel
                  autoplay={false}
                  draggable
                  arrows
                  dots={false}
                  style={{ height: "100%" }}
                  initialSlide={startedIndex}
                  asNavFor={nav2}
                  ref={sliderRef1}
                  nextArrow={<SampleNextArrow />}
                  prevArrow={<SamplePrevArrow />}
                >
                  {photos.map(({ image, _id, type }, index) => (
                    <CauruselImageWrapperStyled key={index}>
                      <ActionWrapperStyled className="actions">
                        <UploadedImageActionButtonStyled
                          onClick={handleEditImage(image, _id, type)}
                          $hbg="var(--main-color)"
                        >
                          {t("addCar.photo.edit")}
                        </UploadedImageActionButtonStyled>

                        <Popconfirm
                          title={t("addCar.photo.removeConfirm")}
                          description={t("addCar.photo.removeDescription")}
                          okText={t("addCar.photo.removeConfirmYes")}
                          cancelText={t("addCar.photo.removeConfirmNo")}
                          onConfirm={handleDeleteImage(_id)}
                        >
                          <UploadedImageActionButtonStyled>
                            {t("addCar.photo.remove")}
                          </UploadedImageActionButtonStyled>
                        </Popconfirm>
                      </ActionWrapperStyled>
                      <img src={image} alt={`Slide ${index}`} />
                    </CauruselImageWrapperStyled>
                  ))}
                </Carousel>
              </MainCarouselWrapper>

              <ThumbnailCarouselStyled>
                <Carousel
                  dots={false}
                  arrows
                  draggable
                  infinite
                  slidesToShow={slideToShow}
                  initialSlide={startedIndex}
                  swipeToSlide
                  centerMode
                  asNavFor={nav1}
                  ref={sliderRef2}
                  nextArrow={<SampleNextArrow />}
                  prevArrow={<SamplePrevArrow />}
                >
                  {photos.map(({ image }, index) => (
                    <ThumbnailCarouselImageStyled
                      key={index}
                      // className={`thumbnail ${currentSlide === index ? 'active' : ''}`}
                      onClick={() => sliderRef2.current?.goTo(index)}
                    >
                      <img src={image} alt={`Thumbnail ${index}`} />
                    </ThumbnailCarouselImageStyled>
                  ))}
                </Carousel>
              </ThumbnailCarouselStyled>
            </>
          )}
        </ModalWrapperStyled>
      </Modal>
    </ConfigProvider>
  );
};

const CropWrapperStyled = styled.div`
  width: 100%;
  height: 100%;
`;

const CropAreaStyled = styled.div`
  position: relative;
  height: 80%;
  width: 80%;
  background: rgb(255, 255, 255);
  margin: 0 auto;
`;

const CropActionsWrapperStyled = styled.div`
  width: 80%;
  margin: 50px auto;

  & .ant-slider .ant-slider-track {
    background-color: transparent;
  }

  & .ant-slider .ant-slider-rail {
    border-radius: 8px;

    background: rgb(109 102 102);
  }

  & .ant-slider-handle {
    &:after {
      border-radius: 8px;

      background: rgb(250, 250, 250) !important;
      width: 6px !important;
      height: 32px !important;
      box-shadow: none !important;
      transform: translate(0, -11px);
    }
  }
`;

const RotateActionWrapperStyled = styled.div`
  margin-top: 16px;
`;

const RotateButtonStyled = styled(Button)`
  background: transparent;
  border: none;
  &:hover {
    background: transparent !important;
    transform: scale(1.2);
  }
`;

const SaveActionsWrapperStyled = styled.div`
  margin-bottom: 5px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const CROP_AREA_ASPECT = 4 / 3;
const CropAddCar: React.FC<{
  isCropOpen: boolean;
  cropeImage: { image: string; _id: string; type: string };
  handleClose: () => void;
  onSubmit: (data: EditImageProps) => void;
}> = ({ isCropOpen, cropeImage, handleClose, onSubmit }) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSliderChange = (value: number) => {
    setZoom(value);
  };

  const handleOk = async () => {
    const croppedImage = await getCroppedImg(
      cropeImage?.image || "",
      croppedAreaPixels,
      cropeImage.type,
      rotate,
    );

    if (croppedImage) onSubmit({ image: croppedImage, _id: cropeImage._id, type: cropeImage.type });
  };

  const handleRotate = (angle: number) => () => {
    setRotate((prev) => prev + angle);
  };

  return (
    <ConfigProvider
      modal={{
        styles: modalStyles,
      }}
    >
      <Modal
        open={isCropOpen}
        width={"90%"}
        style={{ maxWidth: "1700px", zIndex: 10000 }}
        centered
        onCancel={handleClose}
        className="AddCarModal"
        footer={null}
        closeIcon={null}
      >
        <CropWrapperStyled>
          <SaveActionsWrapperStyled>
            <UploadedImageActionButtonStyled onClick={handleClose}>
              {t("addCar.photo.cancel")}
            </UploadedImageActionButtonStyled>
            <UploadedImageActionButtonStyled $hbg="var(--main-color)" onClick={handleOk}>
              {t("addCar.photo.save")}
            </UploadedImageActionButtonStyled>
          </SaveActionsWrapperStyled>
          <CropAreaStyled>
            <Cropper
              image={cropeImage.image}
              crop={crop}
              zoom={zoom}
              aspect={CROP_AREA_ASPECT}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={false}
              rotation={rotate}
              style={{
                containerStyle: {
                  background: "rgb(46, 46, 46)",
                },
                cropAreaStyle: {
                  // background: "rgb(255, 255, 255)",
                },
                mediaStyle: {
                  background: "rgb(255, 255, 255)",
                },
              }}
            />
          </CropAreaStyled>
          <CropActionsWrapperStyled>
            <Slider
              // defaultValue={1.5}
              tooltip={{ formatter: null }}
              max={3}
              min={1}
              step={0.01}
              value={zoom}
              onChange={handleSliderChange}
            />
            <RotateActionWrapperStyled>
              <RotateButtonStyled onClick={handleRotate(90)}>
                <svg
                  width="19.692139"
                  height="17.000000"
                  viewBox="0 0 19.6921 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs />
                  <path
                    id="replay"
                    d="M19.69 8.5C19.69 9.67 19.46 10.78 19.02 11.81C18.57 12.84 17.97 13.74 17.2 14.51C16.43 15.27 15.53 15.88 14.5 16.33C13.47 16.77 12.37 17 11.19 17L11.19 15.5C13.14 15.5 14.79 14.82 16.15 13.46C17.51 12.1 18.19 10.45 18.19 8.5C18.19 6.55 17.51 4.89 16.15 3.53C14.79 2.17 13.14 1.5 11.19 1.5C9.24 1.5 7.58 2.17 6.22 3.53C4.87 4.89 4.19 6.55 4.19 8.5L4.19 8.76L5.78 7.17L6.86 8.23L3.43 11.65L0 8.21L1.08 7.15L2.69 8.76L2.69 8.5C2.69 7.32 2.91 6.21 3.36 5.18C3.8 4.15 4.41 3.25 5.18 2.48C5.94 1.72 6.84 1.11 7.87 0.66C8.9 0.22 10.01 0 11.19 0C12.37 0 13.47 0.22 14.5 0.66C15.53 1.11 16.43 1.72 17.2 2.48C17.97 3.25 18.57 4.15 19.02 5.18C19.46 6.21 19.69 7.32 19.69 8.5Z"
                    fill="#FFFFFF"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                  />
                </svg>
              </RotateButtonStyled>
              <RotateButtonStyled onClick={handleRotate(-90)}>
                <svg
                  width="19.692139"
                  height="17.000000"
                  viewBox="0 0 19.6921 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs />
                  <path
                    id="forward_media"
                    d="M0 8.5C0 7.32 0.22 6.21 0.66 5.18C1.11 4.15 1.72 3.25 2.48 2.48C3.25 1.72 4.15 1.11 5.18 0.66C6.21 0.22 7.32 0 8.5 0C9.67 0 10.78 0.22 11.81 0.66C12.84 1.11 13.74 1.72 14.51 2.48C15.27 3.25 15.88 4.15 16.33 5.18C16.77 6.21 17 7.32 17 8.5L17 8.76L18.6 7.15L19.69 8.21L16.25 11.65L12.82 8.23L13.91 7.17L15.5 8.76L15.5 8.5C15.5 6.55 14.82 4.89 13.46 3.53C12.1 2.17 10.45 1.5 8.5 1.5C6.54 1.5 4.89 2.17 3.53 3.53C2.17 4.89 1.5 6.55 1.5 8.5C1.5 10.45 2.17 12.1 3.53 13.46C4.89 14.82 6.54 15.5 8.5 15.5L8.5 17C7.32 17 6.21 16.77 5.18 16.33C4.15 15.88 3.25 15.27 2.48 14.51C1.72 13.74 1.11 12.84 0.66 11.81C0.22 10.78 0 9.67 0 8.5Z"
                    fill="#FFFFFF"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                  />
                </svg>
              </RotateButtonStyled>
            </RotateActionWrapperStyled>
          </CropActionsWrapperStyled>
        </CropWrapperStyled>
      </Modal>
    </ConfigProvider>
  );
};

export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  type: string,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
): Promise<string | null> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      if (file) {
        resolve(URL.createObjectURL(file));
      }
    }, type);
  });
};

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    // image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}
