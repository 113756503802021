import { AirBagIcons } from "../search/components/filters/pick/airbags";
import { BodyTypeIcons } from "../search/components/filters/pick/bodyTypes";
import { ColorIcons } from "../search/components/filters/pick/colors";
import { DriveTypesIcons } from "../search/components/filters/pick/driveTypes";
import { FuelIcons } from "../search/components/filters/pick/fuels";
import { GearIcons } from "../search/components/filters/pick/gears";
import { LightsIcons } from "../search/components/filters/pick/lights";
import { MultimediaIcons } from "../search/components/filters/pick/multimedia";
import { OtherIcons } from "../search/components/filters/pick/other";
import { SalonOptionsIcons } from "../search/components/filters/pick/salonOptions";
import { SalonMaterialsIcons } from "../search/components/filters/pick/salons";
import { SecurityIcons } from "../search/components/filters/pick/security";

export const icons = {
  ...AirBagIcons,
  ...BodyTypeIcons,
  ...ColorIcons,
  ...DriveTypesIcons,
  ...FuelIcons,
  ...GearIcons,
  ...LightsIcons,
  ...MultimediaIcons,
  ...OtherIcons,
  ...SalonOptionsIcons,
  ...SalonMaterialsIcons,
  ...SecurityIcons,
};
