import { Button, Checkbox, Form, Input } from "antd";

import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { AuthThunk } from "../redux/auth.thunks";

export const RegisterComponent = () => {
  const dispatch = useAppDispatch();
  const isPending = useAppSelector((state) => state.auth.isPending);
  const onFinish = (values: {
    agreement: boolean;
    name: string;
    password: string;
    password_repeat: string;
    phone: string;
  }) => {
    console.log("Success:", values);
    dispatch(AuthThunk({ userData: { ...values, phone: `380${values.phone}` }, type: "register" }));
  };

  const onFinishFailed = (errorInfo: {
    values: { phone: string; password: string };
    errorFields: {
      name: (string | number)[];
      errors: string[];
    }[];
    outOfDate: boolean;
  }) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Form
      name="register"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      disabled={isPending}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: "Please input your name!" },
          () => ({
            validator(_, value) {
              if (value.length > 2 && value.length < 20) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Name is not valid!"));
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone Number"
        rules={[
          { required: true, message: "Please input your phone number!" },
          { len: 9, message: "Phone must be 9 characters long!" },
          () => ({
            validator(_, value) {
              if (!value || value.match(/^[0-9]+$/)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Phone is not valid!"));
            },
          }),
        ]}
      >
        <Input addonBefore={380} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Password Repeat"
        name="password_repeat"
        rules={[
          { required: true, message: "Please input your password!" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("The new password that you entered do not match!"));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error("Should accept agreement")),
          },
        ]}
      >
        <Checkbox>
          I have read the <a href="#a">agreement</a>
        </Checkbox>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
