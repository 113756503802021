import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getCarsCountThunk } from "./search.thunks";

interface SearchState {
  currentPosition: string;
  carCount: number | null;
  carCountLoading: boolean;
}

// Define the initial state
const initialState: SearchState = {
  currentPosition: "useInfo",
  carCount: null,
  carCountLoading: true,
};

// Create the search slice
const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setCurrentPosition: (state, action: PayloadAction<string>) => {
      state.currentPosition = action.payload;
    },
    setCarCountLoading: (state, action: PayloadAction<boolean>) => {
      state.carCountLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCarsCountThunk.fulfilled, (state, action) => {
      state.carCount = action.payload.count;
      state.carCountLoading = false;
    });
  },
});

// Export the actions and reducer
export const SearchActions = searchSlice.actions;

export default searchSlice.reducer;
