import { Card } from "antd";
import React from "react";
import styled from "styled-components";

import { baseURL } from "../../helpers/interseptor";
import { Brands, CarModel } from "../filters/filters.types";

const { Meta } = Card;

export interface CardItemProps {
  _id: string;
  image: string;
  name: string;
  searchName: string;
  options?: CarModel["options"];
  onClick: (_id: string, options: Brands | CarModel) => void;
}

export const CardItemSingle: React.FC<CardItemProps> = ({ _id, image, onClick, ...restProps }) => {
  const handleClick = () => {
    onClick(_id, { _id, image, ...restProps } as Brands | CarModel);
  };
  return (
    <Card
      style={{ width: 240 }}
      cover={<img alt="example" src={baseURL + image} />}
      onClick={handleClick}
    >
      <Meta title={restProps.name} />
    </Card>
  );
};

const CardStyled = styled(Card)<{ $isSelected: boolean }>`
  border: 1px solid ${(props) => (props.$isSelected ? "green" : "#f0f0f0")};
  cursor: pointer;
`;

export const CardItemMulti: React.FC<any> = ({
  title,
  _id,
  image,
  onClick,
  isSelected,
  ...restProps
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClick({ title, _id, image, ...restProps });
  };
  return (
    <CardStyled
      style={{ width: 240 }}
      cover={<img alt="example" src={baseURL + image} />}
      onClick={handleClick}
      $isSelected={isSelected}
    >
      <Meta title={title} />
    </CardStyled>
  );
};
