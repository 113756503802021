import { createContext, useContext, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { AdminRoutesPath } from "..";
import { LoginAdmin } from "../../components/admin/login/login";
import { AdminSearch } from "../../pages/admin/search";
import { AdminUserPage } from "../../pages/admin/user";
import { AdminUsersPage } from "../../pages/admin/users";
import { CarAdd } from "../../pages/car/add/CarAdd";

export const AdminRoutes = () => {
  const [adminTocken, setAdminTocken] = useState("");
  const handleTocken = (value: string) => {
    setAdminTocken(value);
  };
  return (
    <AdminContext.Provider value={{ adminTocken, handleTocken }}>
      <Routes>
        <Route element={<ProtectedAdmin />}>
          <Route path={AdminRoutesPath.editID} element={<CarAdd edit isAdmin />} />
          <Route path={AdminRoutesPath.search} element={<AdminSearch />} />
          <Route path={AdminRoutesPath.users} element={<AdminUsersPage />} />
          <Route path={AdminRoutesPath.userID} element={<AdminUserPage />} />
        </Route>
      </Routes>
    </AdminContext.Provider>
  );
};

export const ProtectedAdmin = () => {
  const { adminTocken, handleTocken } = useContext(AdminContext);

  if (!adminTocken) {
    return <LoginAdmin setAdminTocken={handleTocken} />;
  }

  return <Outlet />;
};

export const AdminContext = createContext({
  adminTocken: "",
  handleTocken: (value: string) => {
    console.log("init", value);
  },
});
