import styled from "styled-components";

const Description = styled.div`
  /* basic text */
  color: rgb(51, 51, 51);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0%;
`;

export const CarDescription: React.FC<{ description: string }> = ({
  description,
}) => {
  return <Description>{description}</Description>;
};
