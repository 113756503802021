import { Button } from "antd";
import styled from "styled-components";

import { baseURL } from "../../../../helpers/interseptor";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { Modification } from "../../filters.types";
import { FiltersActions } from "../../redux/filters.slice";

const WrapperStyled = styled.div`
  width: 250px;
  margin: 15px;
`;

const ImageWrapperStyled = styled.div`
  width: 100%;
  height: 100px;
  overflow: hidden;
`;

const ImageStyled = styled.img`
  width: 100%;
  height: auto;
  position: relative;
  left: 0;
  top: 0;
  transform: translateY(-25%);
`;

const WrapperListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ModificationStyled = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px;
  border: 1px solid ${(props) => (props?.$isSelected ? "green" : "#f0f0f0")} 
}`;

const ModificationItemStyled = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  text-align: center;
`;

export const ModificationsList = () => {
  const dispatch = useAppDispatch();

  const modificationsList = useAppSelector((state) => state.filters.selectedData.generations);
  // const selectedModifications = useAppSelector((state) => state.filters.selectedModifications);

  const handleModification = (parentId: string, modification: Modification) => () => {
    dispatch(FiltersActions.setModification({ modification, parentId }));
  };
  return (
    <>
      {modificationsList?.map((generation) => (
        <WrapperStyled key={generation._id}>
          <ImageWrapperStyled>
            <ImageStyled src={baseURL + generation.image} alt={generation.label} />
          </ImageWrapperStyled>
          <h3>{generation.label}</h3>
          <WrapperListStyled>
            {generation.options.map((modification) => (
              <ModificationStyled
                key={modification._id}
                $isSelected={modification.isSelected}
                onClick={handleModification(generation._id, modification)}
              >
                <ModificationItemStyled> {modification.name}</ModificationItemStyled>
              </ModificationStyled>
            ))}
          </WrapperListStyled>
        </WrapperStyled>
      ))}
      <Button
        onClick={() => {
          console.log("onCLick");
        }}
      >
        Search //TODO check if modificationSelected or not. filter by selected modifications
      </Button>
    </>
  );
};
