import { Radio } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FormItemStyled, LabelStyled } from "./form";
import { USE_INFO, UseInfo } from "../../../../constants/AdditionalInfo";
import { useAppDispatch } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";

const ItemStyled = styled(Radio.Button)`
  background: rgb(240, 240, 240);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  height: 47px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: var(--main-color);
    border-color: var(--main-color);
  }
  &:hover {
    color: #fff;
    background: var(--main-color) !important;
    border-color: var(--main-color) !important;
  }
`;

export const UseInfoAddCar: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleUseInfo = useCallback(
    (key: UseInfo) => () => {
      dispatch(CarActions.setUseInfo(key));
    },
    [dispatch],
  );
  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.useInfo.label")}</LabelStyled>}
      name={USE_INFO}
      rules={[{ required: true, message: t("addCar.useInfo.error") }]}
    >
      <Radio.Group buttonStyle="solid" id={USE_INFO}>
        <ItemStyled value={UseInfo.NEW} onClick={handleUseInfo(UseInfo.NEW)}>
          {t("addCar.useInfo.new")}
        </ItemStyled>
        <ItemStyled value={UseInfo.USED} onClick={handleUseInfo(UseInfo.USED)}>
          {t("addCar.useInfo.used")}
        </ItemStyled>
      </Radio.Group>
    </FormItemStyled>
  );
};
