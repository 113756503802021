import { Slider, SliderSingleProps } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { YearPickerComp } from "../../../../filters/components/years";
import { FromToEnum } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";

const YearPickWrapperStyled = styled.div`
  width: 100%;
  margin: 0 auto;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
`;

const WrapperStyled = styled.div`
  border-radius: 8px;
  padding: 16px;
  background: rgb(255, 255, 255);
`;

const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-size: 18px;
  font-weight: 600;
  line-height: 175%;
`;

const SliderWrapperStyled = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputBetweenTextStyled = styled.div`
  color: rgb(0, 0, 0);
  margin: 0 15px;
`;

const YearItemSelectWrapper = styled.div`
  flex: 1;
  height: 55px;
`;

const marks: SliderSingleProps["marks"] = {
  2000: "2000",

  2010: "2010",

  2015: "2015",
  2020: "2020",
  2024: "2024",
};

export const maxYear = 2025;

export const YearPick = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const year = useAppSelector((state) => state.filters.selectedData.year);

  const handleChange = (value: number[]) => {
    dispatch(FiltersActions.setYearSlider(value));
  };
  return (
    <>
      <WrapperStyled id="year">
        <TitleStyled>{t("year")}</TitleStyled>
        <YearPickWrapperStyled>
          <SliderWrapperStyled>
            <Slider
              marks={marks}
              range={{ draggableTrack: true }}
              value={[Number(year?.from) || 0, Number(year?.to) || 0]}
              min={2000}
              max={Number(process.env.REACT_APP_MAX_YEAR)}
              onChange={handleChange}
            />
          </SliderWrapperStyled>
          <InputWrapper>
            <YearItemSelectWrapper>
              <YearPickerComp type={FromToEnum.FROM} />
            </YearItemSelectWrapper>

            <InputBetweenTextStyled>{t("to")}</InputBetweenTextStyled>
            <YearItemSelectWrapper>
              <YearPickerComp type={FromToEnum.TO} />
            </YearItemSelectWrapper>
          </InputWrapper>
        </YearPickWrapperStyled>
      </WrapperStyled>
    </>
  );
};
