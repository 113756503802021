import { Route, Routes } from "react-router-dom";

import { SearchRoutesPath } from "..";
import { AdvancetSearch } from "../../pages/search/advancedSearch";
import { SearchPage } from "../../pages/search/search";

export const SearchRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SearchPage />} />
      <Route path={SearchRoutesPath.advanced} element={<AdvancetSearch />} />
    </Routes>
  );
};
