import { Button } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CardItemMulti } from "../../../common/Card";
import { CardSearchEnum, SelectedGeneration } from "../../filters.types";
import { FiltersActions } from "../../redux/filters.slice";

export const GenerationList = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedGenerations = useAppSelector((state) => state.filters.selectedData.generations);
  const generations = useAppSelector((state) => state.filters.generations);
  const options = useMemo(() => {
    return generations
      .map((gen) => ({
        ...gen,

        title: `${gen.name}: ${gen.yearStart}-${gen?.yearEnd || t("now")}`,
      }))
      .reverse();
  }, [generations, t]);

  const handleClick = (generation: SelectedGeneration) => {
    dispatch(FiltersActions.setGenerationsMultyCard(generation));
  };

  const handleNext = () => {
    dispatch(FiltersActions.setSearchCartType(CardSearchEnum.MODIFICATION));
  };
  return (
    <>
      {options?.map((option) => (
        <div key={option._id}>
          <CardItemMulti
            {...option}
            isSelected={selectedGenerations?.some((gen) => gen._id === option._id)}
            onClick={handleClick}
          />
        </div>
      ))}
      <Button onClick={handleNext}>Next</Button>
    </>
  );
};
