import React from "react";
import styled from "styled-components";

import type { BaseSelectRef } from "rc-select";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { Brands } from "../../../../filters/filters.types";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { getModelsThunk } from "../../../../filters/redux/filters.thunks";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickCard, SinglePickMore } from "../../../shared/advancedSearch/SinglePickCard";
import { MenuID } from "../../../shared/constant/menuID";
import { BrandsSelect } from "../inline/brands";

const BrandPickWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const BrandsPick = () => {
  const dispatch = useAppDispatch();
  const selectRef = React.useRef<BaseSelectRef>(null);
  const brands = useAppSelector((state) => state.filters.brands);
  const selectedBrands = useAppSelector((state) => state.filters.selectedData.brand);

  const handleClick = (value: Brands) => {
    if (value._id === selectedBrands?._id) {
      dispatch(FiltersActions.clearBrand());
      return;
    }
    dispatch(getModelsThunk(value));
  };

  const handleFocus = () => {
    selectRef.current?.focus();
  };
  return (
    <SearchComponentWrapper title="brand" count={selectedBrands ? 1 : 0} id={MenuID.BRAND}>
      <BrandsSelect ref={selectRef} />
      <BrandPickWrapperStyled>
        {brands.slice(0, 9).map((brand) => (
          <SinglePickCard<Brands>
            size="small"
            key={brand._id}
            onClick={handleClick}
            value={brand}
            isSelected={brand._id === selectedBrands?._id}
          />
        ))}
        <SinglePickMore onClick={handleFocus} />
      </BrandPickWrapperStyled>
    </SearchComponentWrapper>
  );
};
