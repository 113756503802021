export const CarFeatures: React.FC<{
  features: { [key: string]: any[] };
}> = ({ features }) => {
  return (
    <div>
      {Object.entries(features).map(([key, value]) => (
        <div key={key}>
          <h4>category:{key}</h4>
          {value.map((feature) => (
            <div key={feature.key}>{feature.value}</div>
          ))}
        </div>
      ))}
    </div>
  );
};
