import { createSlice } from "@reduxjs/toolkit";

import {
  AuthThunk,
  ChangePasswordThunk,
  getCurrentUser,
  manageFavorite,
  VerifyPhoneThunk,
} from "./auth.thunks";
import { CarSubmitThunk } from "../../car/redux/car.thunks";

export interface AuthState {
  name: string;
  phone: string;
  isLogin: boolean;
  loading: boolean;
  isPending: boolean;
  _id?: string;
  favorite: string[];
  isPhoneVerified: boolean;
}

const initialState: AuthState = {
  name: "",
  phone: "",
  isLogin: false,
  loading: true,
  isPending: false,
  favorite: [],
  isPhoneVerified: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isLogin = false;
      state._id = undefined;
      state.loading = false;
      state.phone = "";
      state.name = "";
      state.isPhoneVerified = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AuthThunk.pending, (state, action) => {
        console.log("action Pending", action);
        state.isPending = true;
        state.isLogin = false;
      })
      .addCase(AuthThunk.fulfilled, (state, action) => {
        state.isPending = false;
        state.isLogin = true;
        state.phone = action.payload.phone;
        state.name = action.payload.name;
        state._id = action.payload._id;
        state.favorite = action.payload.favorite;
        state.isPhoneVerified = action.payload.isPhoneVerified;
      })
      .addCase(AuthThunk.rejected, (state, action) => {
        state.isLogin = false;
        state.isPending = false;
        state._id = undefined;
        state.phone = "";
        state.name = "";
      })

      .addCase(getCurrentUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isLogin = true;
        state.phone = action.payload.phone;
        state.name = action.payload.name;
        state._id = action.payload._id;
        state.favorite = action.payload.favorite;
        state.isPhoneVerified = action.payload.isPhoneVerified;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.isLogin = false;
        state.loading = false;
        state._id = undefined;
        state.phone = "";
        state.name = "";
      })
      .addCase(VerifyPhoneThunk.pending, (state, action) => {
        state.isPending = true;
      })
      .addCase(VerifyPhoneThunk.fulfilled, (state, action) => {
        state.isPhoneVerified = action.payload.isPhoneVerified;
        state.isPending = false;
      })
      .addCase(VerifyPhoneThunk.rejected, (state, action) => {
        state.isPending = false;
      })
      .addCase(manageFavorite.fulfilled, (state, action) => {
        state.favorite = action.payload.favorite;
      })
      .addCase(CarSubmitThunk.fulfilled, (state, action) => {
        if (action.payload?.user) {
          state.isLogin = true;
          state.phone = action.payload.user.phone;
          state.name = action.payload.user.name;
          state._id = action.payload.user._id;
          state.favorite = action.payload.user.favorite;
        }
      })
      .addCase(ChangePasswordThunk.fulfilled, (state, action) => {
        state.isLogin = true;
        state.phone = action.payload.phone;
        state.name = action.payload.name;
        state._id = action.payload._id;
        state.favorite = action.payload.favorite;
        state.isPhoneVerified = action.payload.isPhoneVerified;
      });
  },
});

export const AuthActions = authSlice.actions;

export default authSlice.reducer;
