import { InputNumber } from "antd";
import React from "react";
import styled from "styled-components";

import { SelectStyled } from "./brands";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { FromToEnum } from "../filters.types";
import { FiltersActions } from "../redux/filters.slice";

const GetTitle = (value?: { from?: number | undefined; to?: number | undefined }) => {
  let title = "";
  if (value?.from) {
    title += `$${value.from}`;
  }
  if (value?.to) {
    if (title !== "") {
      title += " - ";
    }
    title += `$${value.to}`;
  }
  if (title === "") {
    return null;
  }
  return title.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const PricePickerWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputNumberStyled = styled(InputNumber)<{
  onChange: (value: string | null) => void;
}>`
  width: 45%;
`;

export const PriceSearch = () => {
  const price = useAppSelector((state) => state.filters.selectedData.price);

  return (
    <>
      <SelectStyled
        placeholder="Price"
        size="large"
        onSelect={() => {
          console.log("onSelect");
        }}
        onChange={() => {
          console.log("onChange");
        }}
        value={GetTitle(price)}
        dropdownRender={() => (
          <PricePickerWrapperStyled>
            <PriceInput type={FromToEnum.FROM} />
            <PriceInput type={FromToEnum.TO} />
          </PricePickerWrapperStyled>
        )}
      />
    </>
  );
};

const PriceInput = React.memo(({ type }: { type: FromToEnum }) => {
  const dispatch = useAppDispatch();
  const price = useAppSelector((state) => state.filters.selectedData.price);
  const handleChange = (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      dispatch(FiltersActions.setPrice({ type, value: (value as unknown as number) || undefined }));
    }
  };
  return (
    <InputNumberStyled
      onChange={handleChange}
      placeholder={type}
      maxLength={16}
      value={price?.[type]}
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      prefix="$"
      controls={false}
    />
  );
});
