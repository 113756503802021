import styled from "styled-components";

import { PhoneSvg } from "../../addCar/assets/Phone";
import { TelegramSvg } from "../../addCar/assets/Telegram";

const ContactsTitle = styled.h2`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0%;
`;

const IconWrapper = styled.div<{ $borColor: string; $ml?: number }>`
  width: 31px;
  height: 31px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${(props) => props.$borColor || "rgb(0, 0, 0)"};

  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 17px;
    margin-left: ${(props) => props.$ml || 0}px;
  }
`;

const UserContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const UserContactItem = styled.div`
  display: flex;
  align-items: center;
`;

const UserContactItemText = styled.div`
  margin: 0 10px;
  color: rgb(51, 51, 51);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: 0%;
`;

interface ContactsProps {
  user: { name: string; phone: string; telegram: string };
}

export const Contacts: React.FC<ContactsProps> = ({ user }) => {
  return (
    <div>
      <ContactsTitle>Контакти</ContactsTitle>
      <ContactsTitle>{user.name}</ContactsTitle>
      <UserContactWrapper>
        <UserContactItem>
          <IconWrapper $borColor="#39b525">
            <PhoneSvg />
          </IconWrapper>
          <UserContactItemText>{user.phone || "Не вказано"}</UserContactItemText>
        </UserContactItem>
        <UserContactItem>
          <IconWrapper $borColor="#009eeb" $ml={-2}>
            <TelegramSvg />
          </IconWrapper>
          <UserContactItemText>{user.telegram || "Не вказано"}</UserContactItemText>
        </UserContactItem>
      </UserContactWrapper>
    </div>
  );
};
