import { Select } from "antd";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { MenuID } from "../../../shared/constant/menuID";

const SelectStyled = styled(Select)<{ onChange: (value: string[]) => void }>`
  width: 100%;
  height: 55px;
`;

const RegionPickWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;

export const RegionPick = () => {
  const dispatch = useAppDispatch();
  const selectedRegion = useAppSelector((state) => state.filters.selectedData.region);
  const options = useAppSelector((state) => state.car.region);

  const handleChange = (value: string[]) => {
    dispatch(FiltersActions.setRegions(value));
  };

  const handleClear = () => {
    dispatch(FiltersActions.setRegions([]));
  };
  return (
    <SearchComponentWrapper title="region" count={selectedRegion?.length} id={MenuID.REGION}>
      <RegionPickWrapperStyled>
        <SelectStyled
          options={options}
          mode="multiple"
          allowClear
          onChange={handleChange}
          onClear={handleClear}
          value={selectedRegion}
        />
      </RegionPickWrapperStyled>
    </SearchComponentWrapper>
  );
};
