import { Select } from "antd";
import { forwardRef } from "react";
import styled from "styled-components";

import type { BaseSelectRef } from "rc-select";

import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { getModelsThunk } from "../../../../filters/redux/filters.thunks";

const BrandSelectWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export interface SelectProps {
  onSelect?: (value: any, options: any) => void;
}

export const SelectStyled = styled(Select)<SelectProps>`
  border-radius: 5px;
  width: 100%;
`;

export type Ref = HTMLButtonElement;

export const BrandsSelect = forwardRef<BaseSelectRef, unknown>((_, ref) => {
  const dispatch = useAppDispatch();
  const brands = useAppSelector((state) => state.filters.brands);
  const value = useAppSelector((state) => state.filters.selectedData.brand);
  const handleBrand = (value: string, options: any) => {
    console.log("options", options);
    dispatch(getModelsThunk(options));
  };

  const handleClear = () => {
    dispatch(FiltersActions.clearBrand());
  };

  return (
    <>
      <BrandSelectWrapperStyled>
        <SelectStyled
          options={brands}
          showSearch
          allowClear
          size="large"
          value={value}
          onSelect={handleBrand}
          onClear={handleClear}
          ref={ref}
          showAction={["focus"]}
        />
      </BrandSelectWrapperStyled>
    </>
  );
});
