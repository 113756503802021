import { Button, Col, Dropdown, Flex, MenuProps, Row } from "antd";
import React, { useCallback, useMemo } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { usePageTracking } from "../../helpers/pageTracking";
import { useAppDispatch, useAppSelector } from "../../helpers/store.hook";
import { CarRoutesPath } from "../../routes";
import { AuthActions } from "../auth/redux/auth.slice";
import { deleteTokenLocal } from "../auth/utils/tokenLocal";

const LogoStyled = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 40px;
  border-radius: 5px;
  background-color: blue;
  color: white;

  &:hover {
    background-color: darkblue;
    color: white;
  }
`;

export const Header = () => {
  usePageTracking();
  return (
    <Row>
      <Col span={24}>
        <Flex
          justify={"space-between"}
          style={{
            border: "1px solid darkgrey",
            padding: "5px",
            borderRadius: "5px 5px 0 0 ",
          }}
        >
          <div>
            <LogoStyled to="/">Intro</LogoStyled>
          </div>
          <div>
            <AddCarButton />
            <LoginButton />
          </div>
        </Flex>
      </Col>
    </Row>
  );
};

const LoginButton = React.memo(() => {
  const dispatch = useAppDispatch();
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const user = useAppSelector((state) => state.auth);

  const handleLogOut = useCallback(() => {
    deleteTokenLocal();
    dispatch(AuthActions.logOut());
  }, [dispatch]);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <NavLink to="/user/cars">My Cars</NavLink>,
      },
      {
        key: "2",
        label: <NavLink to={CarRoutesPath.car + CarRoutesPath.add}>Add Car</NavLink>,
      },
      {
        key: "3",
        label: <NavLink to={CarRoutesPath.car + CarRoutesPath.favourite}>Favorite</NavLink>,
      },
      {
        key: "4",
        label: <Button onClick={handleLogOut}>LogOut</Button>,
      },
    ],
    [handleLogOut],
  );

  return (
    <>
      {isLogin ? (
        <Dropdown menu={{ items }} placement="bottomRight" trigger={["click"]}>
          <Button>{user?.name || user?.phone}</Button>
        </Dropdown>
      ) : (
        <NavLink to="/auth/login">Login</NavLink>
      )}
    </>
  );
});

//TODO add navbar to go throu user page
// TODO ADD user page with list of user cars and adverts

const AddCarButton = React.memo(() => {
  return (
    <NavLink to={CarRoutesPath.car + CarRoutesPath.add}>
      <Button>Add Car</Button>
    </NavLink>
  );
});
