import { Button, InputNumber, Select } from "antd";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { CarGetAdditionalInfoThunk } from "../../car/redux/car.thunks";
import { YearPickerComp } from "../../filters/components/years";
import {
  Brands,
  CarModel,
  FromToEnum,
  Modification,
  SelectedGeneration,
} from "../../filters/filters.types";
import { FiltersActions } from "../../filters/redux/filters.slice";
import { getGenerationsThunk, getModelsThunk } from "../../filters/redux/filters.thunks";
import { createGenSearch } from "../utils/createSearch";

export interface SelectProps {
  onSelect?: (value: string, options: any) => void;
  onChange?: (value: string | string[]) => void;
  dropdownRender?: (originNode: ReactNode) => ReactNode;
}

interface SelectMultyProps extends Omit<SelectProps, "onChange"> {
  onChange?: (value: string[]) => void;
}
const SelectStyled = styled(Select)<SelectProps>`
  width: 215px;
`;
const SelectStyledMulty = styled(SelectStyled)<SelectMultyProps>``;

const FromToWrapperStyled = styled.div`
  display: flex;
`;

export function debounce(func: any, delay: number) {
  // eslint-disable-next-line no-undef
  let timeoutId: NodeJS.Timeout;
  return function (this: any, ...args: any) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export const SearchFilter = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(CarGetAdditionalInfoThunk());
  }, [dispatch]);
  const brands = useAppSelector((state) => state.filters.brands);
  const models = useAppSelector((state) => state.filters.models);
  const selectedBrand = useAppSelector((state) => state.filters.selectedData?.brand);
  const selectedModel = useAppSelector((state) => state.filters.selectedData?.model);

  const bodyTypes = useAppSelector((state) => state.car.bodyType);
  const fuels = useAppSelector((state) => state.car.fuel);
  const driveTypes = useAppSelector((state) => state.car.driveType);
  const colors = useAppSelector((state) => state.car.color);
  const gears = useAppSelector((state) => state.car.gear);

  const selectedBodyType = useAppSelector((state) => state.filters.selectedData?.bodyType);
  const selectedFuel = useAppSelector((state) => state.filters.selectedData?.fuel);
  const selectedDriveType = useAppSelector((state) => state.filters.selectedData?.driveType);
  const selectedColor = useAppSelector((state) => state.filters.selectedData?.color);
  const selectedGear = useAppSelector((state) => state.filters.selectedData?.gear);
  const selectedPrice = useAppSelector((state) => state.filters.selectedData.price);
  const selectedPower = useAppSelector((state) => state.filters.selectedData.power);
  const selectedMileage = useAppSelector((state) => state.filters.selectedData.mileage);
  const selectedUseInfo = useAppSelector((state) => state.filters.selectedData.useInfo);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const manageSearch = useCallback(
    debounce((key: string, value: string) => {
      setSearchParams((prev) => {
        prev.set(key, value);
        return prev;
      });
    }, 800),
    [],
  );

  const manageAdvanceSearch = useCallback(
    (key: string, value: string[]) => {
      const nextSearch: { [key: string]: string[] } = {};

      searchParams.forEach((value, key) => {
        if (nextSearch[key]) {
          nextSearch[key] = [...nextSearch[key], value];
        } else {
          nextSearch[key] = [value];
        }
      });

      nextSearch[key] = value;

      setSearchParams(createSearchParams(nextSearch).toString());
    },
    [searchParams, setSearchParams],
  );

  const handleBrand = (value: string, options: Brands) => {
    if (selectedBrand?.name !== value) {
      dispatch(
        getModelsThunk({
          ...options,
          name: value,
        }),
      );
      manageSearch("brand", value);
    }
  };

  const handleModel = (value: string, options: CarModel & { isParent: boolean }) => {
    console.log("options", options);
    if (options.isParent) {
      dispatch(FiltersActions.setModel(options));
    } else {
      dispatch(getGenerationsThunk(options));
    }
    manageSearch("model", options.value || "");
  };

  const handleChangePrice = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      dispatch(FiltersActions.setPrice({ type, value: (value as unknown as number) || undefined }));

      manageSearch(`price${type.slice(0, 1).toUpperCase()}${type.slice(1)}`, value || "");
    }
  };

  const handleChangePower = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      dispatch(
        FiltersActions.setPowers({ type, value: (value as unknown as number) || undefined }),
      );

      manageSearch(`power${type.slice(0, 1).toUpperCase()}${type.slice(1)}`, value || "");
    }
  };

  const handleChangeMilage = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      dispatch(
        FiltersActions.setMileage({ type, value: (value as unknown as number) || undefined }),
      );

      manageSearch(`mileage${type.slice(0, 1).toUpperCase()}${type.slice(1)}`, value || "");
    }
  };

  const handleBodyType = (value: string[]) => {
    dispatch(FiltersActions.setBodyTypes(value));
    manageAdvanceSearch("bodyType", value);
  };

  const hangleFuel = (value: string[]) => {
    dispatch(FiltersActions.setFuels(value));
    manageAdvanceSearch("fuel", value);
  };
  const hangleDriveType = (value: string[]) => {
    dispatch(FiltersActions.setDriveTypes(value));
    manageAdvanceSearch("driveType", value);
  };
  const hangleColor = (value: string[]) => {
    dispatch(FiltersActions.setColors(value));
    manageAdvanceSearch("color", value);
  };
  const hangleGear = (value: string[]) => {
    dispatch(FiltersActions.setGears(value));
    manageAdvanceSearch("gear", value);
  };

  const handleUseInfo = (value: string[]) => {
    dispatch(FiltersActions.setUseInfos(value));
    manageAdvanceSearch("useInfo", value);
  };

  return (
    <div>
      <SelectStyledMulty
        options={[
          {
            _id: "used",
            value: "used",
            label: "used",
          },
          {
            _id: "afterAccident",
            value: "afterAccident",
            label: "afterAccident",
          },
          {
            _id: "isNewCar",
            value: "isNewCar",
            label: "isNewCar",
          },
        ]}
        value={selectedUseInfo}
        // onSelect={handleModel}
        onChange={handleUseInfo}
        // onClear={handleClear}
        placeholder="Use Info"
        mode="multiple"
        showSearch
        allowClear
        size="large"
      />
      <SelectStyled
        options={brands}
        value={selectedBrand?.searchName}
        onSelect={handleBrand}
        // onChange={handleChange}
        // onClear={handleClear}
        placeholder="Brand"
        showSearch
        allowClear
        size="large"
      />
      <SelectStyled
        options={models}
        value={selectedModel?.searchName}
        onSelect={handleModel}
        // onChange={handleChange}
        // onClear={handleClear}
        placeholder="Model"
        showSearch
        allowClear
        size="large"
      />

      <div>
        <Generations />
      </div>
      <FromToWrapperStyled>
        Price:
        <InputNumber
          value={String(selectedPrice?.from || "0")}
          onChange={handleChangePrice(FromToEnum.FROM)}
        />
        <InputNumber
          value={selectedPrice?.to as unknown as string}
          onChange={handleChangePrice(FromToEnum.TO)}
        />
      </FromToWrapperStyled>

      <FromToWrapperStyled>
        Power:
        <InputNumber
          value={String(selectedPower?.from || "0")}
          onChange={handleChangePower(FromToEnum.FROM)}
        />
        <InputNumber
          value={selectedPower?.to as unknown as string}
          onChange={handleChangePower(FromToEnum.TO)}
        />
      </FromToWrapperStyled>
      <SelectStyledMulty
        options={bodyTypes}
        value={selectedBodyType}
        onChange={handleBodyType}
        mode="multiple"
        size="large"
        placeholder="Body type"
        showSearch
        allowClear
      />
      <FromToWrapperStyled>
        Year: <YearPickerComp type={FromToEnum.FROM} /> <YearPickerComp type={FromToEnum.TO} />
      </FromToWrapperStyled>
      <FromToWrapperStyled>
        Mileages:
        <InputNumber
          value={String(selectedMileage?.from || "0")}
          onChange={handleChangeMilage(FromToEnum.FROM)}
        />
        <InputNumber
          value={selectedMileage?.to as unknown as string}
          onChange={handleChangeMilage(FromToEnum.TO)}
        />
      </FromToWrapperStyled>

      <SelectStyledMulty
        options={fuels}
        value={selectedFuel}
        // onSelect={handleModel}
        onChange={hangleFuel}
        // onClear={handleClear}
        placeholder="Fuel"
        mode="multiple"
        showSearch
        allowClear
        size="large"
      />
      <SelectStyledMulty
        options={driveTypes}
        value={selectedDriveType}
        // onSelect={handleModel}
        onChange={hangleDriveType}
        // onClear={handleClear}
        placeholder="Drive type"
        mode="multiple"
        showSearch
        allowClear
        size="large"
      />
      <SelectStyledMulty
        options={colors}
        value={selectedColor}
        // onSelect={handleModel}
        onChange={hangleColor}
        // onClear={handleClear}
        placeholder="Color"
        mode="multiple"
        showSearch
        allowClear
        size="large"
      />
      <SelectStyledMulty
        options={gears}
        value={selectedGear}
        // onSelect={handleModel}
        onChange={hangleGear}
        // onClear={handleClear}
        placeholder="Gear"
        mode="multiple"
        showSearch
        allowClear
        size="large"
      />
    </div>
  );
};

const GenItemWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const GenItemStyled = styled.div<{ $isSelected: boolean }>`
  border: 1px solid ${(props) => (props.$isSelected ? "green" : "#f0f0f0")};
  cursor: pointer;
  padding: 10px;
  margin: 2px;
`;

export const Generations = React.memo(() => {
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const generations = useAppSelector((state) => state.filters.generations);
  const selectedGenerations = useAppSelector((state) => state.filters.selectedData?.generations);
  const genList = useMemo(() => {
    return [...(selectedGenerations || []), ...generations].reduce(
      (acc: SelectedGeneration[], item: SelectedGeneration) => {
        if (acc.find((accItem) => accItem._id === item._id)) {
          return acc;
        } else {
          return [...acc, item];
        }
      },
      [],
    );
  }, [selectedGenerations, generations]);

  const handleModification = (
    gen: SelectedGeneration,
    mod: Modification & {
      isSelected?: boolean | undefined;
    },
  ) => {
    console.log("gen", gen);
    console.log("selectedGenerations", selectedGenerations);
    console.log("generations", generations);
    const isSelectedGen = selectedGenerations?.find((item) => item._id === gen._id);
    if (isSelectedGen) {
      const nextGen = selectedGenerations?.map((item) => {
        if (item._id === gen._id) {
          return {
            ...gen,
            modifications: gen.options.map((modItem) => {
              if (modItem._id === mod._id) {
                return {
                  ...modItem,
                  isSelected: !modItem.isSelected,
                };
              }
              return modItem;
            }),
          };
        }
        return item;
      });
      const res = createGenSearch(nextGen || []);
      dispatch(FiltersActions.setGenerationSearch({ nextGen: nextGen || [] }));

      setSearchParams((prev) => {
        prev.set("generation", res);
        return prev;
      });
    } else {
      const nextMod = gen.options.map((item) => {
        if (item._id === mod._id) {
          return {
            ...mod,
            isSelected: !mod.isSelected,
          };
        }
        return item;
      });
      const res = createGenSearch([...(selectedGenerations || []), { ...gen, options: nextMod }]);
      dispatch(
        FiltersActions.setGenerationSearch({
          nextGen: [...(selectedGenerations || []), { ...gen, options: nextMod }],
        }),
      );
      setSearchParams((prev) => {
        prev.set("generation", res);
        return prev;
      });
    }
    // let isGenSelected = false;
    // const nextMod = gen.modifications.map((item) => {
    //   if (item._id === mod._id) {
    //     return {
    //       ...mod,
    //       isSelected: !mod.isSelected,
    //     };
    //   }

    //   return item;
    // });
    // dispatch(
    //   FiltersActions.setModificationSearch({
    //     gen: {  },
    //     isGenSelected,
    //   }),
    // );
    // const res = createGenSearch([{ ...gen, modifications: nextMod }]);
    // console.log("res", res);
  };

  return (
    <>
      <div>Gen</div>
      <div>
        {genList.map((gen) => {
          return (
            <div key={gen._id}>
              <div>{gen.label}</div>
              <GenItemWrapperStyled>
                <GenItem gen={gen} key={gen._id} onModification={handleModification} />
              </GenItemWrapperStyled>
            </div>
          );
        })}
      </div>
    </>
  );
});

export const GenItem: React.FC<{
  gen: SelectedGeneration;
  onModification: (
    gen: SelectedGeneration,
    mod: Modification & {
      isSelected?: boolean | undefined;
    },
  ) => void;
}> = React.memo(({ gen, onModification }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedMods = useMemo(() => {
    return gen.options.filter((mod) => mod.isSelected);
  }, [gen]);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleGeneration =
    (
      gen: SelectedGeneration,
      mod: Modification & {
        isSelected?: boolean | undefined;
      },
    ) =>
    () => {
      onModification(gen, mod);
    };
  return (
    <>
      {isOpen ? (
        <>
          {gen.options.map((mod) => (
            <GenItemStyled
              key={mod._id}
              $isSelected={!!mod.isSelected}
              onClick={handleGeneration(gen, mod)}
            >
              {mod.name}
            </GenItemStyled>
          ))}
        </>
      ) : (
        selectedMods.map((mod) => (
          <GenItemStyled
            key={mod._id}
            $isSelected={!!mod.isSelected}
            onClick={handleGeneration(gen, mod)}
          >
            {mod.name}
          </GenItemStyled>
        ))
      )}

      <Button onClick={handleOpen}>{isOpen ? "close" : "open"}</Button>
    </>
  );
});
