import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { FromToEnum } from "../../components/filters/filters.types";
import { FiltersActions } from "../../components/filters/redux/filters.slice";
import { getGenerationsThunk, getModelsThunk } from "../../components/filters/redux/filters.thunks";
import { SearchFilter } from "../../components/search/components/searchFilter";
import { SearchList } from "../../components/search/components/searchList";
import { AdditionalInfoSearchSelectedDataKeys } from "../../constants/AdditionalInfo";
import { useAppDispatch } from "../../helpers/store.hook";

const SearchPageStyled = styled.div`
  display: flex;
  max-width: 1600px;
  justify-content: center;
  margin-top: 25px;
`;

const SearchListWrapperStyled = styled.div`
  max-width: 80%;
  width: 100%;
`;

const SearcFilterWrapperStyled = styled.div`
  max-width: 20%;
  width: 100%;
  margin-top: 15px;
`;

export const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const [, setState] = useState({});
  const dispatch = useAppDispatch();

  const parseSearchParams = useCallback(async () => {
    const temp: any = {};
    for (const value of searchParams.keys()) {
      if (value === "page" || value === "limit") {
        // console.log("page", searchParams.get(value));
      }
      if (value === "brand") {
        const brand = searchParams.get("brand");
        // console.log("brand", brand);
        temp.brand = brand;
      }
      if (value === "model") {
        const model = searchParams.get("model");
        temp.model = model;
      }

      if (value === "generations") {
        const generation = searchParams.get("generations");

        temp.generation = qs.parse(generation as string);
      }

      if (value === "powerFrom") {
        const powerFrom = searchParams.get("powerFrom");
        temp.powerFrom = powerFrom;

        dispatch(FiltersActions.setPowers({ type: FromToEnum.FROM, value: Number(powerFrom) }));
      }
      if (value === "powerTo") {
        const powerTo = searchParams.get("powerTo");
        temp.yearTo = powerTo;

        dispatch(FiltersActions.setPowers({ type: FromToEnum.TO, value: Number(powerTo) }));
      }

      if (value === "yearFrom") {
        const yearFrom = searchParams.get("yearFrom");
        temp.yearFrom = yearFrom;

        dispatch(FiltersActions.setYear({ type: FromToEnum.FROM, value: Number(yearFrom) }));
      }
      if (value === "yearTo") {
        const yearTo = searchParams.get("yearTo");
        temp.yearTo = yearTo;

        dispatch(FiltersActions.setYear({ type: FromToEnum.TO, value: Number(yearTo) }));
      }

      if (value === "priceFrom") {
        const priceFrom = searchParams.get("priceFrom");
        temp.priceFrom = priceFrom;

        dispatch(
          FiltersActions.setPrice({ type: FromToEnum.FROM, value: priceFrom as unknown as number }),
        );
      }
      if (value === "priceTo") {
        const priceTo = searchParams.get("priceTo");
        temp.priceTo = priceTo;

        dispatch(
          FiltersActions.setPrice({ type: FromToEnum.TO, value: priceTo as unknown as number }),
        );
      }
      if (value === "sort") {
        dispatch(FiltersActions.setSort(searchParams.get("sort") || "d"));
      }
      if (value === "period") {
        dispatch(FiltersActions.setPeriod(searchParams.get("period") || "all"));
      }

      if (value === AdditionalInfoSearchSelectedDataKeys.FUEL) {
        const fuel: string[] = searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.FUEL) || [];

        dispatch(FiltersActions.setFuels(fuel));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.BODY_TYPE) {
        const bodyType: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.BODY_TYPE) || [];
        dispatch(FiltersActions.setBodyTypes(bodyType));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.DRIVE_TYPE) {
        const bodyType: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.DRIVE_TYPE) || [];
        dispatch(FiltersActions.setDriveTypes(bodyType));
      }

      if (value === AdditionalInfoSearchSelectedDataKeys.OTHER) {
        const other: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.OTHER) || [];

        dispatch(FiltersActions.setOthers(other));
      }

      if (value === AdditionalInfoSearchSelectedDataKeys.COLOR) {
        const colors: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.COLOR) || [];

        dispatch(FiltersActions.setColors(colors));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.GEAR) {
        const gear: string[] = searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.GEAR) || [];

        dispatch(FiltersActions.setGears(gear));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.MULTIMEDIA) {
        const multimedia: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.MULTIMEDIA) || [];
        dispatch(FiltersActions.setMultimedias(multimedia));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.AIRBAG) {
        const airbag: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.AIRBAG) || [];
        dispatch(FiltersActions.setAirbags(airbag));
      }

      if (value === AdditionalInfoSearchSelectedDataKeys.SECURITY) {
        const security: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.SECURITY) || [];
        dispatch(FiltersActions.setSecurities(security));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.SALON) {
        const salon: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.SALON) || [];
        dispatch(FiltersActions.setSalons(salon));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.OPTIC) {
        const optics: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.OPTIC) || [];
        dispatch(FiltersActions.setOptics(optics));
      }
      if (value === AdditionalInfoSearchSelectedDataKeys.LIGHT) {
        const lights: string[] =
          searchParams.getAll(AdditionalInfoSearchSelectedDataKeys.LIGHT) || [];
        dispatch(FiltersActions.setLights(lights));
      }
      if (value === "useInfo") {
        const useInfo: string[] = searchParams.getAll("useInfo") || [];
        dispatch(FiltersActions.setUseInfos(useInfo));
      }
      // console.log("value", value, "temp", temp);
    }

    if (temp.brand) {
      await dispatch(getModelsThunk({ searchName: temp.brand } as any));
    }
    if (temp.model) {
      await dispatch(getGenerationsThunk({ searchName: temp.model } as any));
    }

    if (temp.generation) {
      dispatch(FiltersActions.setGenerations(temp.generation as any));
    }

    setState(temp);
  }, [dispatch, searchParams]);

  useEffect(() => {
    parseSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchPageStyled>
      <SearchListWrapperStyled>
        <SearchList />
      </SearchListWrapperStyled>
      <SearcFilterWrapperStyled>
        <SearchFilter />
      </SearcFilterWrapperStyled>
    </SearchPageStyled>
  );
};
