import { Button } from "antd";
import styled from "styled-components";

import { BrandSearch } from "../filters/components/brands";
import { Generation } from "../filters/components/generation";
import { ModelSearch } from "../filters/components/models";
import { PriceSearch } from "../filters/components/price";
import { YearSearch } from "../filters/components/years";

const SearchWrapper = styled.div`
  border-radius: 10px;
  background: rgb(240, 240, 240);
  padding: 40px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  max-width: 1600px;
  width: 90%;
`;

export const SearchRow: React.FC<{ handleSearch: () => void; handleAdvanceSearch: () => void }> = ({
  handleSearch,
  handleAdvanceSearch,
}) => {
  return (
    <SearchWrapper>
      <BrandSearch />
      <ModelSearch />
      <YearSearch />
      <PriceSearch />
      <Generation />
      <Button onClick={handleSearch}>Search</Button>
      <Button onClick={handleAdvanceSearch}>Advanced Search</Button>
    </SearchWrapper>
  );
};
//TODO add year selection
//TODO add engin selection
//TODO add price selection
// TODO add car mileage
//TODO add changes to search and apply them after refresh
