import { JWTTokens, LocalStorageKeys } from "../types";

export const setTokenLocal = (token: JWTTokens) => {
  try {
    localStorage.setItem(LocalStorageKeys.JWT_ACCESS, token.access_token);
    localStorage.setItem(LocalStorageKeys.JWT_REFRESH, token.refresh_token);
  } catch (e) {
    console.log("Unable to set up tokens", e);
  }
};

export const deleteTokenLocal = () => {
  try {
    localStorage.removeItem(LocalStorageKeys.JWT_ACCESS);
    localStorage.removeItem(LocalStorageKeys.JWT_REFRESH);
  } catch (e) {
    console.log("Unable to set up tokens", e);
  }
};

export const getToken = (type: LocalStorageKeys) => {
  return localStorage.getItem(type);
};
