import { Pagination, Select } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { SearchCart } from "./searchCart";
import axios from "../../../helpers/interseptor";
import { useAppSelector } from "../../../helpers/store.hook";
import { CarRoutesPath } from "../../../routes";

const ListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  max-width: 1600px;
`;

const OrderWrapperStyled = styled.div`
  padding: 10px;
  display: flex;
`;
const OrderFilterItemStyled = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const OrderSelectStyled = styled(Select)<{ onChange: (value: string) => void }>`
  width: 100%;
  margin-left: 10px;
`;

export const SearchList = () => {
  const sort = useAppSelector((state) => state.filters.sort);
  const period = useAppSelector((state) => state.filters.period);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalCars, setTotalCars] = useState(1);
  const [cars, setCars] = useState<
    {
      VIN: string;
      brand: string;
      color: string;
      createdAt: string;
      engin: string;
      features: any[];
      fuel: string;
      generation: string;
      mileage: number;
      model: string;
      photo: string;
      transmission: string;
      updatedAt: string;
      year: number;
      _id: string;
    }[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get<any>(`search/cars?${searchParams}`)
      .then((res) => res.data)
      .then((data) => {
        setCars(data.cars);
        setTotalCars(data.totalCars);
        setPage(data.page);
        setLimit(data.limit);
      });
  }, [searchParams]);

  const handleNext = (page: number) => {
    setSearchParams((prev) => {
      prev.set("page", String(page));
      return prev;
    });
  };

  const handleShowSize = (current: number, size: number) => {
    setSearchParams((prev) => {
      prev.set("limit", String(size));
      return prev;
    });
  };

  const handleSort = (value: string) => {
    setSearchParams((prev) => {
      prev.set("sort", value);
      return prev;
    });
  };
  const handlePeriod = (value: string) => {
    setSearchParams((prev) => {
      prev.set("period", value);
      return prev;
    });
  };

  return (
    <div>
      <OrderWrapperStyled>
        <OrderFilterItemStyled>
          Sort:
          <OrderSelectStyled
            onChange={handleSort}
            value={sort}
            options={[
              { value: "d", label: "Default" },
              { value: "p-l-t", label: "Price: low to top" },
              { value: "p-t-l", label: "Price: top to low" },
              { value: "y-l-t", label: "Year: low to top" },
              { value: "y-t-l", label: "Year: top to low" },
              { value: "m-l-t", label: "Mileage: low to top" },
              { value: "m-t-l", label: "Mileage: top to low" },
              { value: "publication", label: "Publication date" },
            ]}
          />
        </OrderFilterItemStyled>
        <OrderFilterItemStyled>
          Period:
          <OrderSelectStyled
            onChange={handlePeriod}
            value={period}
            options={[
              { value: "all", label: "All" },
              { value: "12-h", label: "Last 12 h" },
              { value: "day", label: "Last day" },
              { value: "2-d", label: "Last 2 days" },
              { value: "week", label: "Last week" },
              { value: "2-w", label: "Last 2 week" },
              { value: "month", label: "Last month" },
            ]}
          />
        </OrderFilterItemStyled>
      </OrderWrapperStyled>
      <ListStyled>
        {cars.map((car) => (
          <SearchCart
            {...car}
            key={car._id}
            _id={car._id}
            link={`${CarRoutesPath.car}/${car._id}`}
          />
        ))}
      </ListStyled>
      <Pagination
        onChange={handleNext}
        total={totalCars}
        current={page}
        defaultPageSize={limit}
        onShowSizeChange={handleShowSize}
      />
    </div>
  );
};
