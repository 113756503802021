import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../../../../constants/AdditionalInfo";
import { useAppSelector } from "../../../../../helpers/store.hook";
import camera360 from "../../../asset/360-camera.jpg";
import alarms from "../../../asset/alarms.jpg";
import antiLockBrakingSystem from "../../../asset/anti-lock-braking-system.jpg";
import automaticParkingSystem from "../../../asset/automatic-parking-system.jpg";
import brakeForceDistribution from "../../../asset/brake-force-distribution-BAS-EBD.jpg";
import centralLock from "../../../asset/central-lock.jpg";
import collisionAvoidance from "../../../asset/collisiona-voidance.jpg";
import controlBlindStops from "../../../asset/control-blind-spots.jpg";
import driverFatigueSensor from "../../../asset/driver-fatigue-sensor.jpg";
import frontCamera from "../../../asset/front-camera.jpeg";
import immobilizer from "../../../asset/immobilizer.jpg";
import interiorPenetrationSensor from "../../../asset/interior-intrusion-sensor.jpg";
import laneControl from "../../../asset/lane-control.webp";
import parktronic from "../../../asset/parktronic.jpeg";
import stabilizationSystem from "../../../asset/rastability-control- sensor.jpg";
import rearCamera from "../../../asset/rear-camera.jpg";
import blockingTheRearDoorLocks from "../../../asset/rear-door-locks.jpg";
import recognitionRoadSigns from "../../../asset/road-sign-recognition.jpg";
import steeringStabilization from "../../../asset/steering-stabilization.jpg";
import tirePressureSensor from "../../../asset/tire-pressure-sensor.jpg";
import antiSkidSystemAsr from "../../../asset/traction-control.jpg";
import { Icons } from "../../../search.types";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickAdditionalInfoCard } from "../../../shared/advancedSearch/SinglePickAdditionalInfo";
import { MenuID } from "../../../shared/constant/menuID";

export const SecurityIcons: Icons = {
  alarms: alarms,
  "anti-lock-braking-system-ABS": antiLockBrakingSystem,
  "anti-skid-system-ASR": antiSkidSystemAsr,
  "automatic-parking-system": automaticParkingSystem,
  "blocking-the-rear-door-locks": blockingTheRearDoorLocks,
  "brake-force-distribution-BAS-EBD": brakeForceDistribution,
  "camera-360": camera360,
  "central-lock": centralLock,
  "collision-avoidance": collisionAvoidance,
  "control-blind-spots": controlBlindStops,
  "driver-fatigue-sensor": driverFatigueSensor,
  "front-camera": frontCamera,
  immobilizer: immobilizer,
  "interior-penetration-sensor-volume-sensor": interiorPenetrationSensor,
  "lane-control": laneControl,
  parktronic: parktronic,
  "rear-camera": rearCamera,
  "recognition-road-signs": recognitionRoadSigns,
  "stabilization-system-ESP": stabilizationSystem,
  "steering-stabilization-VSM": steeringStabilization,
  "tire-pressure-sensor": tirePressureSensor,
};

export const SecurityPick = () => {
  const selectedSecurity = useAppSelector((state) => state.filters.selectedData.security);

  return (
    <SearchComponentWrapper title="security" count={selectedSecurity?.length} id={MenuID.SECURITY}>
      <SinglePickAdditionalInfoCard
        type={AdditionalInfoDTOKeys.SECURITY}
        selectedType={AdditionalInfoSearchSelectedDataKeys.SECURITY}
        images={SecurityIcons}
      />
    </SearchComponentWrapper>
  );
};
