import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../../../../constants/AdditionalInfo";
import { useAppSelector } from "../../../../../helpers/store.hook";
import outlet12V from "../../../asset/12v-socket.jpg";
import adaptiveCruise from "../../../asset/adaptive-cruise.jpg";
import automaticTrunkOpening from "../../../asset/automatic-trunk-opening.webp";
import ceilingBlack from "../../../asset/ceiling-black.jpg";
import choiceDrivingMode from "../../../asset/choice-driving-mode.jpg";
import cruiseControl from "../../../asset/cruise-control.jpg";
import doorCloser from "../../../asset/door-closer.jpg";
import electricMirrorDrive from "../../../asset/electric-mirror-drive.jpg";
import electricSeats from "../../../asset/electric-seats.jpg";
import electricSteeringWheelControl from "../../../asset/electric-steering-wheel-control.jpg";
import electricDashboard from "../../../asset/electronic-dashboard.jpg";
import hatch from "../../../asset/hatch.png";
import heatedMirrors from "../../../asset/heated-mirrors.jpg";
import heatedSeats from "../../../asset/heated-seats.jpg";
import steeringWheelHeating from "../../../asset/heated-steering-wheel.jpg";
import interiorLight from "../../../asset/interior-lighting.jpg";
import keyssAccessSystem from "../../../asset/keyless-access-system.jpg";
import lighter from "../../../asset/lighter.jpg";
import seatWithMassage from "../../../asset/massage-seats.jpg";
import multifunctionalSteeringWheel from "../../../asset/multifunctional-steering-wheel.jpg";
import outlet220V from "../../../asset/outlet-220V.avif";
import panoramicRoof from "../../../asset/panoramic-roof.webp";
import powerSteering from "../../../asset/power-steering.jpg";
import electricWindows from "../../../asset/power-windows.jpg";
import projectionDisplay from "../../../asset/projection-display.webp";
import rainSensor from "../../../asset/rain-sensor.jpg";
import remoteEngineStart from "../../../asset/remote-engine-start.jpg";
import seatMemory from "../../../asset/seat-memory.jpg";
import seatVentilation from "../../../asset/seat-ventilation.jpg";
import startStopSystem from "../../../asset/start-stop-system.png";
import sunBlindsBackDoor from "../../../asset/sun-blinds-back-door.jpg";
import thirdRowSeats from "../../../asset/third-row-seats.jpg";
import tintedWindows from "../../../asset/tinted-windows.jpg";
import trunkLidElectricDrive from "../../../asset/trunk-lid-electric-drive.jpg";
import unsersteeringGearShiftPaddles from "../../../asset/understeering-gear-shift-paddles.jpg";
import windshieldHeating from "../../../asset/windshield-heating.jpg";
import wirelessCharging from "../../../asset/wireless-charging.jpg";
import { Icons } from "../../../search.types";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickAdditionalInfoCard } from "../../../shared/advancedSearch/SinglePickAdditionalInfo";
import { MenuID } from "../../../shared/constant/menuID";

export const SalonOptionsIcons: Icons = {
  "adaptive-cruise": adaptiveCruise,
  "automatic-trunk-opening": automaticTrunkOpening,
  "ceiling-black": ceilingBlack,
  "choice-driving-mode": choiceDrivingMode,
  "cruise-control": cruiseControl,
  "door-closer": doorCloser,
  "electric-mirror-drive": electricMirrorDrive,
  "electric-seats": electricSeats,
  "electric-steering-wheel-control": electricSteeringWheelControl,
  "electric-windows": electricWindows,
  "electronic-dashboard": electricDashboard,
  hatch: hatch,
  "heated-mirrors": heatedMirrors,
  "heated-seats": heatedSeats,
  "interior-lighting": interiorLight,
  "keyless-access-system": keyssAccessSystem,
  lighter: lighter,
  "multifunctional-steering-wheel": multifunctionalSteeringWheel,
  "outlet-12V": outlet12V,
  "outlet-220V": outlet220V,
  "panoramic-roof": panoramicRoof,
  "power-steering": powerSteering,
  "projection-display": projectionDisplay,
  "rain-sensor": rainSensor,
  "remote-engine-start": remoteEngineStart,
  "seat-memory": seatMemory,
  "seat-ventilation": seatVentilation,
  "seat-with-massage": seatWithMassage,
  "start-stop-system": startStopSystem,
  "steering-wheel-heating": steeringWheelHeating,
  "sun-blinds-back-door": sunBlindsBackDoor,
  "third-row-seats": thirdRowSeats,
  "tinted-windows": tintedWindows,
  "trunk-lid-electric-drive": trunkLidElectricDrive,
  "understeering-gear-shift-paddles": unsersteeringGearShiftPaddles,
  "windshield-heating": windshieldHeating,
  "wireless-charging": wirelessCharging,
};

export const SalonOptionsPick = () => {
  const selectedSalonOptions = useAppSelector((state) => state.filters.selectedData.salon);

  return (
    <SearchComponentWrapper
      title="salon-options"
      count={selectedSalonOptions?.length}
      id={MenuID.SALON_OPTION}
    >
      <SinglePickAdditionalInfoCard
        type={AdditionalInfoDTOKeys.SALON}
        selectedType={AdditionalInfoSearchSelectedDataKeys.SALON}
        images={SalonOptionsIcons}
      />
    </SearchComponentWrapper>
  );
};
