import {
  AdditionalInfoDTOKeys,
  AdditionalInfoSearchSelectedDataKeys,
} from "../../../../../constants/AdditionalInfo";
import { useAppSelector } from "../../../../../helpers/store.hook";
import driveFull from "../../../asset/all-wheel-drive.svg";
import driveFront from "../../../asset/front-wheel-drive.svg";
import driveRear from "../../../asset/rear-wheel-drive.svg";
import { Icons } from "../../../search.types";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { SinglePickAdditionalInfoCard } from "../../../shared/advancedSearch/SinglePickAdditionalInfo";
import { MenuID } from "../../../shared/constant/menuID";

export const DriveTypesIcons: Icons = {
  "drive-front": driveFront,
  "drive-rear": driveRear,
  "drive-full": driveFull,
};

export const DriveTypesPick = () => {
  const selectedDriveType = useAppSelector((state) => state.filters.selectedData.driveType);

  return (
    <>
      <SearchComponentWrapper
        title="driveType"
        count={selectedDriveType?.length}
        id={MenuID.DRIVE_TYPE}
      >
        <SinglePickAdditionalInfoCard
          type={AdditionalInfoDTOKeys.DRIVE_TYPES}
          selectedType={AdditionalInfoSearchSelectedDataKeys.DRIVE_TYPE}
          images={DriveTypesIcons}
          svg
        />
      </SearchComponentWrapper>
    </>
  );
};
