import { Button, Form, Input } from "antd";
import { Navigate } from "react-router-dom";

import { VerifyPhoneThunk } from "../../components/auth/redux/auth.thunks";
import { useAppDispatch, useAppSelector } from "../../helpers/store.hook";
import { UserRoutesPath } from "../../routes";

export const AuthPhoneVerifyPage = () => {
  const dispatch = useAppDispatch();
  const isPhoneVerified = useAppSelector((state) => state.auth.isPhoneVerified);
  console.log("isPhoneVerified", isPhoneVerified);
  const isPending = useAppSelector((state) => state.auth.isPending);
  if (isPhoneVerified) {
    return <Navigate to={UserRoutesPath.user + UserRoutesPath.cars} />;
  }

  const onFinish = (values: { code: string }) => {
    console.log("Success:", values);
    dispatch(VerifyPhoneThunk({ code: values.code }));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Form
        name="phoneVerify"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        disabled={isPending}
      >
        <Form.Item
          label="code"
          name="code"
          rules={[
            { required: true, message: "Please input your name!" },
            () => ({
              validator(_, value) {
                if (value.length === 4 && !isNaN(Number(value))) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Code is not valid!"));
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
