import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  ContainerStyled,
  ImageContainerStyled,
  TitleContainerStyled,
  TitleStyled,
} from "./SinglePickCard";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import {
  AdditionalInfoOptionsKeys,
  AdditionalInfoSelectedKeys,
} from "../../../filters/filters.types";
import { FiltersActions } from "../../../filters/redux/filters.slice";

const SinglePickAdditionalInfoCardWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
const ImageStyled = styled.img<{ $svg?: boolean }>`
  width: ${(props) => (props.$svg ? "auto" : "100%")};
  height: auto;
`;

export const SinglePickAdditionalInfoCard: React.FC<{
  type: AdditionalInfoOptionsKeys;
  images: { [key: string]: string };
  onClick?: (value: string) => void;
  selectedType: AdditionalInfoSelectedKeys;
  svg?: boolean;
}> = ({ type, images, onClick, selectedType, svg }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const options = useAppSelector((state) => state.car[type]);
  const selected = useAppSelector((state) => state.filters.selectedData[selectedType]);

  const handleValue = (value: string) => () => {
    if (onClick) {
      onClick(value);
      return;
    }
    dispatch(FiltersActions.setAdditionalInfo({ type: selectedType, value }));
  };

  return (
    <SinglePickAdditionalInfoCardWrapperStyled>
      {options?.map((option) => (
        <ContainerStyled
          $border
          key={option._id}
          $isSelected={selected?.includes(option.value)}
          onClick={handleValue(option.value)}
        >
          <ImageContainerStyled $border>
            <ImageStyled alt={option.label} src={images[option.value]} $svg={svg} />
          </ImageContainerStyled>
          <TitleContainerStyled>
            <TitleStyled>
              <Tooltip title={t(option.searchName)}>{t(option.searchName)}</Tooltip>
            </TitleStyled>
          </TitleContainerStyled>
        </ContainerStyled>
      ))}
    </SinglePickAdditionalInfoCardWrapperStyled>
  );
};
