import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { UseInfo } from "../../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../../helpers/store.hook";
import { FiltersActions } from "../../../../filters/redux/filters.slice";
import { SearchComponentWrapper } from "../../../shared/advancedSearch/SearchComponentWrapper";
import { MenuID } from "../../../shared/constant/menuID";

const CheckboxWrapperStyled = styled.div`
  padding: 16px;
  font-weight: 500;
  color: rgb(34, 34, 34);
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
  text-transform: capitalize;
  display: flex;
  flex-gap: 32px;
`;

const CheckboxTextStyled = styled.div`
  font-family: "Roboto", sans-serif;
`;

export const UseInfoPick = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const selectedUseInfoBag = useAppSelector((state) => state.filters.selectedData.useInfo);
  const selectedAfterAccident = useAppSelector((state) => state.filters.selectedData.afterAccident);

  const onChange = (field: string) => (e: CheckboxChangeEvent) => {
    dispatch(FiltersActions.setUseInfo(field));
  };

  const onChangeAfterAccident = (e: CheckboxChangeEvent) => {
    dispatch(FiltersActions.setAfterAccident(e.target.checked));
  };

  return (
    <>
      <SearchComponentWrapper
        title={"useInfo"}
        count={selectedUseInfoBag?.length}
        id={MenuID.USE_INFO}
      >
        <CheckboxWrapperStyled>
          <Checkbox
            onChange={onChange(UseInfo.NEW)}
            checked={selectedUseInfoBag?.includes(UseInfo.NEW)}
          >
            <CheckboxTextStyled>{t("isNewCar")}</CheckboxTextStyled>
          </Checkbox>
          <Checkbox
            onChange={onChange(UseInfo.USED)}
            checked={selectedUseInfoBag?.includes(UseInfo.USED)}
          >
            <CheckboxTextStyled>{t("used")}</CheckboxTextStyled>
          </Checkbox>
          <Checkbox onChange={onChangeAfterAccident} checked={selectedAfterAccident}>
            <CheckboxTextStyled>{t("afterAccident")}</CheckboxTextStyled>
          </Checkbox>
        </CheckboxWrapperStyled>
      </SearchComponentWrapper>
    </>
  );
};
