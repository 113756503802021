import TextArea from "antd/es/input/TextArea";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FormItemStyled, LabelStyled } from "./form";
import { DESCRIPTION } from "../../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";

const TextAreaStyled = styled(TextArea)`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;

  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 16px;
`;

export const DescriptionAddCar: React.FC = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const value = useAppSelector((state) => state.car.selectedData.description);

  const handleDescription = (value: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(CarActions.setDescription(value.target.value));
  };

  return (
    <FormItemStyled
      label={<LabelStyled>{t("addCar.description.label")}</LabelStyled>}
      name={DESCRIPTION}
      rules={[{ required: true, message: t("addCar.description.error") }]}
    >
      <TextAreaStyled value={value} onChange={handleDescription} />
    </FormItemStyled>
  );
});
