import axios, { AxiosRequestConfig } from "axios";

import { LocalStorageKeys } from "../components/auth/types";
import { getToken, setTokenLocal } from "../components/auth/utils/tokenLocal";

export const baseURL = process.env.REACT_APP_BASE_URL;
export const baseURLApi = `${baseURL}/api/v1`;
axios.defaults.baseURL = baseURLApi;

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
let refreshAndRetryQueue: RetryQueueItem[] = [];

let isRefreshing = false;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest: AxiosRequestConfig = error.config;
    if (axios.isAxiosError(error)) {
      if (
        error.response?.data?.message === "Epired" &&
        error.response?.status === 401 &&
        error.response?.data?.error === "Unauthorized"
      ) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            // Refresh the access token
            const token = getToken(LocalStorageKeys.JWT_REFRESH);
            const response = await fetch(`${baseURLApi}/auth/refresh`, {
              headers: { Authorization: `Bearer ${token}` },
              method: "POST",
            });
            const tokens = await response.json();
            setTokenLocal(tokens);
            refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
              axios
                .request(config)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
            });
            refreshAndRetryQueue = [];
            return await axios.request(originalRequest);
          } catch (refreshError) {
            //TODO should logout user and delete tokens
            console.log(refreshError);
            throw refreshError;
          } finally {
            isRefreshing = false;
          }
        } else {
          return new Promise((resolve, reject) => {
            refreshAndRetryQueue.push({
              config: originalRequest,
              resolve,
              reject,
            });
          });
        }
      }
    }

    return Promise.reject(error);
  },
);

axios.interceptors.request.use((config) => {
  const headers = config.headers;
  const token = getToken(LocalStorageKeys.JWT_ACCESS);
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default axios;
