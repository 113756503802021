import { Checkbox, Form } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { USE_INFO_ACCIDENT } from "../../../../constants/AdditionalInfo";
import { useAppDispatch } from "../../../../helpers/store.hook";
import { CarActions } from "../../redux/car.slice";

const CheckboxStyled = styled(Checkbox)`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
`;

export const AfterAccidentAddCar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleChange = (e: CheckboxChangeEvent) => {
    dispatch(CarActions.setBoolean({ key: USE_INFO_ACCIDENT, value: e.target.checked }));
  };
  return (
    <Form.Item name={USE_INFO_ACCIDENT} rules={[{ required: false }]} valuePropName="checked">
      <CheckboxStyled onChange={handleChange}>{t("afterAccident")}</CheckboxStyled>
    </Form.Item>
  );
};
